<template>
<div
    v-if="showCreateAnecdotalPanel"
    ref="draggableContainer"
    class="modal modal-sticky-lg show d-block"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
>
    <div>
        <EditCreateAnecdotalForm
            :create-anecdotal="true"
            :create-anecdotal-properties="{students}"
            :drag-mouse-down="dragMouseDown"
            :toggle-size="toggleSize"
            :minimized="minimized"
        />
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import Types from '../store/Types';
import EditCreateAnecdotalForm from './EditCreateAnecdotalForm.vue';

export default {
    name: 'CreateAnecdotal',
    components: {
        EditCreateAnecdotalForm,
    },
    data() {
        return {
            minimized: false,
            positions: {
                clientX: 0,
                clientY: 0,
                movementX: 0,
                movementY: 0,
            },
        };
    },
    computed: {
        ...mapState({
            showCreateAnecdotalPanel: (state) => state.createAnecdotalPanel.open,
            students: (state) => state.createAnecdotalPanel.students,
        }),
    },
    methods: {
        dragMouseDown(event) {
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            // set the element's new position:
            this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.positions.movementY}px`;
            this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.positions.movementX}px`;
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
        toggleSize() {
            this.minimized = !this.minimized;

            if (this.minimized) {
                this.positions.movementX = undefined;
                this.positions.movementY = undefined;
                this.positions.clientX = 0;
                this.positions.clientY = 0;
                this.$refs.draggableContainer.style.top = 'auto';
                this.$refs.draggableContainer.style.right = 'auto';
            }
        },
        closeModal() {
            this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_CLOSE);
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-sticky-lg {
    padding: 0 !important;
    height: fit-content !important;
    resize: horizontal;
    right: auto;
    top: auto;
    margin: 0;
    left: 25px;
    bottom: 25px;
    width: 600px;
    max-width: 75vw;
    max-height: 100vh;
    min-width: 600px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px  !important;
}
</style>
