var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop",
    },
    [
      _c(
        "div",
        { staticClass: "kt-container kt-container--fluid" },
        [
          _vm.loadState == "BOOTING"
            ? [
                _c(
                  "div",
                  { staticClass: "kt-footer__copyright" },
                  [
                    _c("b-spinner", {
                      staticClass: "mt-1",
                      attrs: { small: "", variant: "primary" },
                    }),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(" Updated rosters are being downloaded... "),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.loadState == "READY"
            ? [
                _c("div", { staticClass: "kt-footer__copyright" }, [
                  _c("span", [
                    _vm._v(
                      " Your database has been updated " +
                        _vm._s(_vm.age) +
                        ". "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "kt-footer__menu" }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }