var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { key: _vm.key, on: { click: _vm.closeAllNavigationPopovers } },
    [
      _vm.cipherSessionLocked
        ? _c("OneTimePassword")
        : _vm.require2fa
        ? _c("RequireTwoFactorAuth")
        : [
            _vm.loggedIn
              ? _c(
                  "div",
                  {
                    staticClass: "kt-header-mobile kt-header-mobile--fixed",
                    attrs: { id: "kt_header_mobile" },
                  },
                  [
                    _c("div", { staticClass: "kt-header-mobile__toolbar" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "kt-header-mobile__toggler kt-header-mobile__toggler--left",
                          attrs: { id: "kt_aside_mobile_toggler" },
                          on: {
                            click: function ($event) {
                              return _vm.setMobileMenu("toggle")
                            },
                          },
                        },
                        [_c("span")]
                      ),
                    ]),
                    _c("div", { staticClass: "kt-header-mobile__logo" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.goHome()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "128px" },
                            attrs: {
                              alt: "SyncGrades",
                              src: "/logos/logo-text-dark.svg",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "kt-header-mobile__toolbar" }, [
                      _c(
                        "button",
                        {
                          staticClass: "kt-header-mobile__topbar-toggler",
                          attrs: { id: "kt_header_mobile_topbar_toggler" },
                          on: {
                            click: function ($event) {
                              return _vm.setMobileTopBar("toggle")
                            },
                          },
                        },
                        [_c("i", { staticClass: "flaticon-more" })]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "kt-grid kt-grid--hor kt-grid--root" }, [
              _c(
                "div",
                {
                  staticClass:
                    "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page",
                },
                [
                  _vm.loggedIn
                    ? _c("TheAsideMenu", { key: `app_${_vm.key}` })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper",
                      staticStyle: { "overflow-x": "hidden" },
                    },
                    [
                      _vm.loggedIn
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "kt-header kt-grid__item kt-header--fixed",
                              attrs: { id: "kt_header" },
                            },
                            [
                              _c(
                                "button",
                                { staticClass: "kt-header-menu-wrapper-close" },
                                [_c("i", { staticClass: "la la-close" })]
                              ),
                              _vm.$_userMixins_isHomeUser
                                ? _c("div", {
                                    staticClass: "kt-header-menu-wrapper w-100",
                                  })
                                : _vm.isCourseView && _vm.databaseIsReady
                                ? _c("TheTopLeftCourseMenu")
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-header-menu-wrapper w-100",
                                      attrs: { id: "kt_header_menu_wrapper" },
                                    },
                                    [
                                      !_vm.$_userMixins_isDisabled &&
                                      ((!_vm.hideAllNavigation &&
                                        _vm.$_userMixins_isSchoolUser) ||
                                        _vm.$_userMixins_isSuperAdmin)
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default",
                                              attrs: { id: "kt_header_menu" },
                                            },
                                            [
                                              _c(
                                                "ul",
                                                { staticClass: "kt-menu__nav" },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "kt-menu__item",
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "kt-menu__link",
                                                          attrs: {
                                                            to: {
                                                              name: "Teachers",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-menu__link-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Teachers "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "ul",
                                                { staticClass: "kt-menu__nav" },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "kt-menu__item",
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "kt-menu__link",
                                                          attrs: {
                                                            to: {
                                                              name: "CourseList",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-menu__link-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Courses "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "ul",
                                                { staticClass: "kt-menu__nav" },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "kt-menu__item",
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "kt-menu__link",
                                                          attrs: {
                                                            to: {
                                                              name: "Students",
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-menu__link-text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Students "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "ul",
                                                { staticClass: "kt-menu__nav" },
                                                [
                                                  _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "kt-menu__item",
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "kt-menu__link",
                                                          attrs: {
                                                            to: {
                                                              name: "AnecdotalLogs",
                                                            },
                                                            href: "/anecdotal-logs",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-menu__link-text",
                                                            },
                                                            [_vm._v(" Logs ")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                              _c("TheTopRightButtons"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$route.query.error
                        ? _c("PageError")
                        : _vm.loggedIn && !_vm.databaseIsReady
                        ? [
                            _c("TheLoadingIndicator", {
                              staticClass:
                                "kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor",
                            }),
                          ]
                        : _vm.databaseIsReady && !_vm.cipherSessionLocked
                        ? [
                            _c(
                              "div",
                              {
                                class: `${_vm.deviceType}-device kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor pb-0`,
                                attrs: { id: "kt_content" },
                              },
                              [
                                _vm.showLightbox ? _c("LightBox") : _vm._e(),
                                _vm.isLocalDev &&
                                _vm.$_userMixins_isSchoolUser &&
                                !_vm.hasLocalPassword &&
                                _vm.$route.name !== "Password"
                                  ? _c("PasswordAlert")
                                  : _vm._e(),
                                _c("router-view", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.showLightbox,
                                      expression: "!showLightbox",
                                    },
                                  ],
                                }),
                                !_vm.$_userMixins_isDisabled &&
                                _vm.$_userMixins_isSchoolUser
                                  ? [
                                      _c("CreateChatGroup"),
                                      _vm.$_userMixins_isSchoolUser
                                        ? _c("PopupChat")
                                        : _vm._e(),
                                      _c("CreateAnecdotal"),
                                      _c("AddStudentBadge"),
                                    ]
                                  : _vm._e(),
                                !_vm.$_userMixins_isDisabled &&
                                _vm.showCreateMessage
                                  ? _c("CreateMessage")
                                  : _vm._e(),
                                !_vm.$_userMixins_isDisabled &&
                                _vm.$_userMixins_isSchoolUser &&
                                _vm.showCreateAnnouncement
                                  ? _c("CreateAnnouncement")
                                  : _vm._e(),
                                !_vm.$_userMixins_isDisabled &&
                                _vm.$_userMixins_isSchoolAdmin &&
                                _vm.showCreateSchoolAnnouncement
                                  ? _c("CreateSchoolAnnouncement")
                                  : _vm._e(),
                                _c("MessageNotification", {
                                  attrs: {
                                    "notification-messages":
                                      _vm.notificationMessages,
                                    "clear-notification":
                                      _vm.clearMessageNotification,
                                  },
                                }),
                                _c("GlobalModals"),
                                _c("TheFooterNotification"),
                              ],
                              2
                            ),
                          ]
                        : [_c("router-view")],
                      _c("TheFooter"),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "kt-quick-panel",
                attrs: { id: "kt_quick_panel" },
              },
              [
                _vm.databaseIsReady
                  ? _c("TheQuickPanel", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "close",
                            fn: function () {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "kt-quick-panel__close",
                                    attrs: {
                                      id: "kt_quick_panel_close_btn",
                                      href: "#",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.closeQuickPanel.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "flaticon2-delete" })]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2659153264
                      ),
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.showQuickPanel
              ? _c("div", {
                  staticClass: "kt-quick-panel-overlay",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.closeQuickPanel()
                    },
                  },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }