<template>
<ul class="kt-menu__nav_header">
    <li class="kt-menu__section_top">
        <div class="kt-menu__section-text ml-4 pl-2">
            {{ externalSchoolId }} {{ schoolName }}
        </div>

        <div
            :title="current ? 'This is the current school term' : 'This is not the current school term'"
            :class="{
                'kt-font-danger': !current
            }"
            class="kt-menu__section-text ml-4 pl-2"
        >
            {{ schoolTermName }}
        </div>

        <div
            class="kt-menu__section-text ml-4 pl-2 mt-1"
            :class="{
                'kt-font-primary': socketEstablished,
                'kt-font-danger': !socketEstablished
            }"
        >
            <template v-if="socketEstablished">
                <span class="kt-badge kt-badge--brand kt-badge--dot mr-2" />
                WebSocket
            </template>
            <template v-else>
                <span class="kt-badge kt-badge--danger kt-badge--dot mr-2" />
                WebSocket
            </template>
        </div>
    </li>
</ul>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import userMixins from '../../../../store/mixins/userMixins';

export default {
    name: 'TheAsideMenuHeader',
    mixins: [userMixins],
    computed: {
        ...mapState({
            user: (state) => state.user,
            isSuperAdmin: (state) => state.user.isSuperAdmin,
            userName: (state) => state.user.userName,
            role: (state) => state.user.school.role,
            externalSchoolId: (state) => state.user.school.externalSchoolId,
            current: (state) => state.user.school.current,
            schoolName: (state) => state.user.school.schoolName,
            schoolTermName: (state) => state.user.school.name,
            googleCourseRefresh: (state) => state.googleCourseRefresh,
            // running: (state) => state.googleCourseRefresh.running,
            // title: (state) => state.googleCourseRefresh.title,
            // subTitle: (state) => state.googleCourseRefresh.subTitle,
        }),
        socketEstablished() {
            return this.$store.state.socketEstablished;
        },
        userName() {
            if (this.user.isSuperAdmin) {
                return this.user.mappedUserName.split('@')[0];
            }
            return this.user.userName.split('@')[0];
        },
        percentComplete() {
            if (!this.googleCourseRefresh) return '0%';
            const num = this.googleCourseRefresh.index;
            const den = this.googleCourseRefresh.total;
            if (num <= 0 || den <= 0) return '0%';
            const percentage = ((num / den) * 100);
            const roundToDecimals = 1;
            const perc = Math.floor(Number(`${Math.round(parseInt(`${percentage}e${roundToDecimals}`, 10))}e-${roundToDecimals}`));
            return `${perc}%`;
        },
    },
    methods: {
        reloadPage() {
            window.location.reload();
        },
    },
};
</script>

<style scoped>

ul.kt-menu__nav_header {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 11px;
}

div.kt-menu__section-text {
    line-height: 1.2em;
    height: 1.2em;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: #a1a8c3;
    white-space: nowrap;
}

li {
    list-style-type: none;
}

body.kt-aside--minimize div.kt-menu__section-text {
    display: none;
}
span.kt-widget24__desc, div.kt-widget24__title {
  white-space: nowrap;
  overflow: clip;
  text-overflow: ellipsis;
}

.spinner {
  transition-property: transform;
  transition-duration: 1s;
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
