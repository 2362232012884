<template>
<li
    :class="['kt-menu__item menu-user-header', headerOpen ? 'kt-menu__item--open' : '' ]"
>
    <a
        :id="`aside_teacher_${schoolEmail}`"
        href="#header-toggle-menu"
        class="kt-menu__link pt-2 pb-2"
        @click.stop.prevent="headerOpen = !headerOpen"
    >
        <span
            v-if="$_userMixins_schoolEmail != schoolEmail"
            class="kt-menu__link-icon kt-menu__link-icon-close"
            @click.stop.prevent="removePortfolio"
        >
            <i class="la la-close" />
        </span>
        <span
            class="kt-menu__link-icon"
            :class="$_userMixins_schoolEmail != schoolEmail ? 'kt-menu__link-icon-avatar' : ''"
        >
            <UserAvatar
                :rounded="true"
                :avatar-user="teacher"
                class="kt-media kt-media--sm--sidebar"
            >
                <div class="kt-media kt-media--sm">
                    <a
                        style="width: 1.75rem;"
                        class="kt-media kt-media--lg kt-media--primary"
                    >
                        <span style="height: 1.75rem; font-size: .75rem;">
                            {{ initials }}
                        </span>
                    </a>
                </div>
            </UserAvatar>
        </span>

        <span class="kt-menu__link-text">
            {{ title }}
        </span>

        <i class="kt-menu__ver-arrow la la-angle-right" />
    </a>
    <b-collapse
        v-model="headerOpen"
        class="kt-menu__submenu"
    >
        <span class="kt-menu__arrow" />
        <ul class="kt-menu__subnav">
            <AsideMenuItem
                title="Teacher Profile"
                route-name="TeacherInfo"
                icon="info"
                :route-params="{schoolEmail}"
            />

            <TeacherPortfolioClassMenu ref="course-menu">
                <TeacherPortfolioMenuCourse
                    v-for="(course, idx) in courses"
                    :key="`teacher_port_route_${idx}_${key}`"
                    class="kt-menu__item"
                    :course="course"
                    :teacher="teacher"
                />
            </TeacherPortfolioClassMenu>
        </ul>
    </b-collapse>
</li>
</template>

<script lang="ts">

import UserAvatar from '../../../../components/UserAvatar.vue';
import globalMixins from '../../../../store/mixins/globalMixins';
import userMixins from '../../../../store/mixins/userMixins';
import TeacherPortfolioMenuCourse from './TeacherPortfolioMenuCourse.vue';
import TeacherPortfolioClassMenu from './TeacherPortfolioClassMenu.vue';
import AsideMenuItem from './AsideMenuItem.vue';
import courseMixins from '../../../../store/mixins/courseMixins';
import Types from '../../../../store/Types';

export default {
    name: 'TeacherPortfolioMenu',
    components: {
        AsideMenuItem,
        TeacherPortfolioClassMenu,
        TeacherPortfolioMenuCourse,
        UserAvatar,
    },
    mixins: [globalMixins, userMixins, courseMixins],
    props: {
        teacher: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            headerOpen: false,
            key: 0,
        };
    },
    computed: {
        isActiveRoute() {
            return this.$route.params.schoolEmail == this.teacher.schoolEmail && this.$route.fullPath.indexOf('/teachers/') > -1;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        schoolEmail() {
            return this.teacher.schoolEmail;
        },
        initials() {
            return this.teacher.lastName.substring(0, 2);
        },
        courses() {
            if (!this.teacher) return [];
            const courses = this.$_courseMixins_getCoursesForStaffId(this.teacher.schoolStaffId);
            return courses
                .filter((x) => !x.hideFromNav)
                .sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));
        },
        title() {
            const { teacher } = this;
            return `${teacher.lastName}, ${teacher.firstName}`;
        },
    },
    watch: {
        extCourseSectionId() {
            this.init();
        },
        isActiveRoute() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        closeCourseMenu() {
            this.$refs['course-menu'].setOpen(false);
        },
        init() {
            const { isActiveRoute, extCourseSectionId } = this;
            this.headerOpen = isActiveRoute;
            if (isActiveRoute || extCourseSectionId) {
                this.$refs['course-menu'].setOpen(true);
            } else {
                this.$refs['course-menu'].setOpen(false);
            }
        },
        removePortfolio() {
            this.$store.commit(Types.mutations.REMOVE_PORTFOLIO_HISTORY, {
                type: 'Teacher',
                schoolEmail: this.schoolEmail,
            });
        },
    },
};
</script>

<style scoped>
img.kt-media--sm--sidebar {
    width: 1.75rem !important;
    max-height: 2rem;
}

span.kt-menu__link-icon-close {
    display: none !important;
}
span.kt-menu__link-icon-close:hover {
    color: rgba(253, 57, 122, 0.8) !important
}

li.menu-user-header a.kt-menu__link:hover span.kt-menu__link-icon-close {
    display: flex !important;
    width: 35px !important;
}
span.kt-menu__link-icon-avatar {
    display: flex !important;
}
li.menu-user-header a.kt-menu__link:hover span.kt-menu__link-icon-avatar {
    display: none !important;
}

</style>
