<template>
<li
    class="kt-menu__item"
    :class="{
        'kt-menu__item--here kt-menu__item--active': open,
        'kt-menu__item--open': open,
    }"
>
    <a
        href="#"
        class="kt-menu__link"
        @click.stop.prevent="open = !open"
    >
        <CourseIcon
            :course="course"
            size="xs"
        />

        <span class="kt-menu__link-text ml-2">
            <div class="menu-subtitle">
                {{ course.courseSectionTitle || course.name }}
            </div>
        </span>
        <span
            v-if="course.nextMeeting && course.nextMeeting.day && course.nextMeeting.day !== today"
            class="kt-menu__link-badge"
        >
            <span
                class="kt-shape-bg-color-1 kt-label-font-color-2 kt-font-xs px-2 py-1"
                :class="color"
                style="color: #fff; border-radius: 0.4rem;"
            >
                {{ course.nextMeeting.day.substring(0, 3) }}
            </span>
        </span>
        <i
            v-else
            class="kt-menu__ver-arrow la la-angle-right"
        />
    </a>
    <b-collapse
        v-model="open"
        class="kt-menu__submenu"
    >
        <span class="kt-menu__arrow" />
        <ul
            v-if="course.extCourseSectionId"
            class="kt-menu__subnav"
        >
            <li class="kt-menu__item  kt-menu__item--parent">
                <span class="kt-menu__link">
                    <span class="kt-menu__link-text">
                        {{ course.courseSectionTitle || course.name }}
                    </span>
                </span>
            </li>
            <li
                v-for="item in menuItems"
                :key="`${item.name}_${course.extCourseSectionId}`"
                class="kt-menu__item kt-menu__course-item"
            >
                <router-link
                    :to="{
                        name: item.name,
                        params: routeParameters,
                    }"
                    class="kt-menu__link"
                    :class="{
                        'router-link-exact-active': item.name == 'TeacherCourseReportCards' && $route.name == 'TeacherCourseReportCardGrades'
                    }"
                >
                    <span
                        v-if="item.icon"
                        class="kt-menu__link-icon"
                    >
                        <SVGIcon

                            :name="item.icon"
                        />
                    </span>
                    <i
                        v-else
                        class="kt-menu__link-bullet kt-menu__link-bullet--dot"
                    >
                        <span />
                    </i>

                    <span class="kt-menu__link-text">
                        {{ item.title }}
                    </span>
                </router-link>
            </li>
        </ul>
    </b-collapse>
</li>
</template>

<script>

import moment from 'moment';
import CourseIcon from '../../../../components/CourseIcon.vue';
import courseMixins from '../../../../store/mixins/courseMixins';
import teacherMixins from '../../../../store/mixins/teacherMixins';
import { getCourseMenuItems } from '../../../../router/routes/classRoutes';

export default {
    name: 'TeacherPortfolioMenuCourse',
    components: { CourseIcon },
    mixins: [courseMixins, teacherMixins],
    props: {
        course: {
            type: Object,
            required: true,
        },
        teacher: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            today: null,
            open: false,
            menuItems: [],
        };
    },
    computed: {
        color() {
            return this.$_courseMixins_getColorForCourse(this.course);
        },
        shortTitle() {
            const { today } = this;
            const { nextMeeting } = this.course;
            if (nextMeeting.day == today) {
                return `P${nextMeeting.period}`;
            }
            return '-';
        },
        routeParameters() {
            const { extCourseSectionId } = this.course;
            const { schoolEmail } = this.teacher;
            return { extCourseSectionId, schoolEmail };
        },
        canViewRosters() {
            return this.course.canViewRosters;
        },

    },
    watch: {
        $route() {
            const { extCourseSectionId } = this.$route.params;
            if (!extCourseSectionId) {
                this.open = false;
            }
            this.open = Boolean(extCourseSectionId == this.course.extCourseSectionId);
        },
    },
    mounted() {
        this.populate();
        const { course } = this;
        const { extCourseSectionId, schoolEmail } = this.$route.params;
        if (!schoolEmail || !extCourseSectionId || !course) return false;
        const open = Boolean(extCourseSectionId == course.extCourseSectionId);
        this.open = open;
    },
    methods: {
        populate() {
            this.today = moment().format('dddd');
            let menuItems = getCourseMenuItems();
            const { pathname } = window.location;

            // Remove all links in TeacherPortfolioClassMenu except for Course Information
            if (!this.canViewRosters) {
                const teacherRouteName = 'TeacherCourseInfo';
                menuItems = menuItems.filter((menu) => menu.teacherRouteName === teacherRouteName);
            }
            this.menuItems = menuItems.map((i) => {
                const item = i;
                item.name = i.teacherRouteName;
                item.active = Boolean(pathname.endsWith(i.path));
                return item;
            });
        },
    },
};
</script>

<style scoped>
div.menu-subtitle {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 120px;
}
</style>

<style scoped src='../../../../css/colors.scss' lang="scss" />

<style>
a.kt-menu__link.router-link-exact-active .kt-menu__link-icon svg g [fill] {
    fill: #0f88ef !important;
}
</style>
