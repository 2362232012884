<template>
<div class="w-100">
    <a class="kt-header__brand-logo w-100 text-center">
        <SVGIcon
            name="syncGradesLoader"
            class="logo loading"
        />

        <h1 v-if="loadState !== 'DECRYPTING'" class="kt-font-bolder">
            {{ ellipsis }}
            <br>
            loading data
            <br>
            please wait
        </h1>
        <h1 v-else class="kt-font-bolder">
            {{ ellipsis }}
            <br>
            Applying ciphers
            <br>
            please wait
        </h1>
    </a>
</div>
</template>
<script>

import { mapState } from 'vuex';

export default {
    name: 'TheLoadingIndicator',
    components: {},
    data() {
        return {
            seconds: 0,
        };
    },
    computed: {
        ...mapState({
            loadState: (state) => state.database.loadState,
            isLoading: (state) => state.navigation.loading,
        }),
        ellipsis(state) {
            if (state.seconds === 4) return '....';
            if (state.seconds === 3) return '...';
            if (state.seconds === 2) return '..';
            if (state.seconds === 1) return '.';
            return '';
        },
    },
    mounted() {
        const v = this;
        setInterval(() => {
            if (v.seconds > 4) {
                v.seconds = 1;
                return;
            }
            v.seconds += 1;
        }, 1000);
    },
    methods: {
    },
};

</script>

<style scoped>

a.kt-header__brand-logo {
  height: 100vh;
  display: block;
  color: white;
}
.kt-header__brand-logo svg {
    opacity: 0.4;
    height: 50vh;
    max-width: 50vw;
}
.kt-header__brand-logo h1 {
    font-family: 'Courier New', Courier, monospace;
    color: #ccc;
    font-size: 3.2rem;
    opacity: 0.4;
}

.logo {
  transition-property: transform;
  transition-duration: 1s;
}
.logo.loading {
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
