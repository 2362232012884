var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showPopupChat,
          expression: "showPopupChat",
        },
      ],
      staticClass: "modal modal-sticky-bottom-left modal-sticky-lg show",
      staticStyle: { display: "block" },
      attrs: { role: "dialog", "data-backdrop": "false", "aria-modal": "true" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c(
          "div",
          { staticClass: "modal-content" },
          [_c("Chat", { attrs: { "is-pop-up": true } })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }