var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showCreateAnecdotalPanel
    ? _c(
        "div",
        {
          ref: "draggableContainer",
          staticClass: "modal modal-sticky-lg show d-block",
          attrs: {
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c(
            "div",
            [
              _c("EditCreateAnecdotalForm", {
                attrs: {
                  "create-anecdotal": true,
                  "create-anecdotal-properties": { students: _vm.students },
                  "drag-mouse-down": _vm.dragMouseDown,
                  "toggle-size": _vm.toggleSize,
                  minimized: _vm.minimized,
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }