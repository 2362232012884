<template>
<div class="kt-container kt-container-fullscreen overflow-hidden py-0 my-0">
    <div
        v-if="report"
        class="kt-portlet kt-fullscreen-portlet"
    >
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    {{ title }}
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-group">
                    <router-link
                        :to="{
                            name: $route.name,
                            params: $route.params,
                            query: closeQuery,
                        }"
                        class="btn btn-icon"
                    >
                        <i class="la la-close" />
                    </router-link>
                </div>
            </div>
        </div>
        <div class="kt-portlet__body lightbox_body">
            <span v-for="(pages) in report" :key="`StudentOfficialPDF_${pages.studentOfficialPdfId}_page_${pages.pageNumber}`">
                <b-img
                    v-if="pages.jpgBucketPath"
                    :src="pages.jpgBucketPath"
                    fluid-grow
                    class="py-5"
                />
            </span>
        </div>
        <div class="kt-portlet__foot">
            <div>
                <a
                    v-if="report && report[0] && report[0].pdfBucketPath"
                    :href="downloadReport(report[0])"
                    target="_blank"
                    class="btn btn-primary pull-right"
                >
                    <i class="la la-download" />
                    Download PDF
                </a>

                <!-- MultiPage PDF navigation controls -->
                <div class="kt-portlet__head-toolbar d-none">
                    <div class="kt-portlet__head-group">
                        <span class="text-muted px-3">Page 1 of 2</span>
                        <a
                            href="#"
                            data-ktportlet-tool="toggle"
                            class="btn btn-sm btn-icon btn-clean btn-icon-md"
                        >
                            <i class="la la-angle-left" />
                        </a>
                        <a
                            href="#"
                            data-ktportlet-tool="toggle"
                            class="btn btn-sm btn-icon btn-clean btn-icon-md"
                        >
                            <i class="la la-angle-right" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
    name: 'LightBox',
    components: {},
    mixins: [],
    data() { return {}; },
    computed: {
        report() {
            const { officialPdfUploadId, studentEnrollmentId } = this.$route.query;
            const report = this.$store.state.database.studentPdfReports
                .filter((r) => r.officialPdfUploadId == officialPdfUploadId && r.studentEnrollmentId == studentEnrollmentId) || null;
            if (!report) return null;
            return report.sort((a, b) => a.pageNumber - b.pageNumber);
        },
        title() {
            const { report } = this;
            if (!report) return 'LightBox';
            return `${report[0].pdfType} ${report[0].extMarkingPeriod ? `MP ${report[0].extMarkingPeriod}` : ''}`;
        },
        closeQuery() {
            const { query } = this.$route;
            const q = { ...query };
            delete q.lightbox;
            delete q.officialPdfUploadId;
            delete q.studentEnrollmentId;
            return q;
        },
    },
    mounted() { },
    methods: {
        downloadReport(report) {
            const { user } = this.$store.state;
            const { school } = user;
            const { schoolId, schoolTermId } = school;
            const { studentOfficialPdfId } = report;
            return `/api/schools/${schoolId}/terms/${schoolTermId}/pdf/${studentOfficialPdfId}/download`;
        },
    },
});

</script>

<style scoped>
.lightbox_body {
    max-height: 70vh;
    overflow-y: scroll;
}
</style>
