var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "modal modal-sticky-top-right show d-block",
          staticStyle: {
            overflow: "hidden",
            "max-width": "450px",
            height: "auto",
          },
          attrs: {
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "kt-portlet kt-portlet--mobile kt-portlet--solid-brand",
            },
            [
              _c("div", { staticClass: "kt-portlet__head" }, [
                _c("div", { staticClass: "kt-portlet__head-label" }, [
                  _vm._m(0),
                  _c("h3", { staticClass: "kt-portlet__head-title" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          `New Message${
                            _vm.notificationMessages[0].messageAuthor
                              ? ` from ${_vm.notificationMessages[0].messageAuthor}`
                              : ""
                          }`
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                  _c("div", { staticClass: "kt-portlet__head-actions" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "kt-link kt-link--state kt-link--light kt-font-lg",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.closeNotification.apply(null, arguments)
                          },
                        },
                      },
                      [_c("i", { staticClass: "flaticon2-cross" })]
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "max-height": "200px",
                      "text-overflow": "ellipsis",
                      overflow: "hidden",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.notificationMessages[0].messageBody) +
                        " "
                    ),
                  ]
                ),
                !_vm.isReplying
                  ? _c("div", { staticClass: "px-4 mt-2" }, [
                      _c("div", { staticClass: "row pull-right" }, [
                        _c(
                          "span",
                          { staticClass: "d-flex align-items-center mr-3" },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "kt-link kt-link--state kt-link--light",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.goToThread.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" View Thread ")]
                            ),
                          ]
                        ),
                        _vm.notificationMessages[0].allowReplies
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-light",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.isReplying = !_vm.isReplying
                                  },
                                },
                              },
                              [_vm._v(" Reply ")]
                            )
                          : _vm._e(),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm.notificationMessages[0].allowReplies && _vm.isReplying
                ? _c(
                    "div",
                    {
                      staticClass:
                        "kt-portlet__foot kt-portlet__foot--sm kt-align-right",
                      staticStyle: { "background-color": "white" },
                    },
                    [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newComment,
                            expression: "newComment",
                          },
                        ],
                        staticClass: "form-control w-100",
                        attrs: { type: "text" },
                        domProps: { value: _vm.newComment },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.newComment = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "row pull-right mt-2 px-3" }, [
                        _c(
                          "span",
                          { staticClass: "d-flex align-items-center mr-3" },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "kt-link kt-link--state kt-link--dark",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.goToThread.apply(null, arguments)
                                  },
                                },
                              },
                              [_vm._v(" View Thread ")]
                            ),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-brand btn-md btn-font-sm btn-upper btn-bold",
                            class: {
                              "kt-spinner kt-spinner--sm kt-spinner--light":
                                _vm.saving,
                            },
                            attrs: {
                              type: "button",
                              disabled: !_vm.newComment || _vm.saving,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.sendReply.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" Send ")]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "kt-portlet__head-icon" }, [
      _c("i", { staticClass: "flaticon-chat" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }