<template>
<li
    v-if="step && step.visible"
    :key="`key_${key}`"
    class="kt-menu__item"
    :class="{
        'link-exact-active router-link-active': active && !enabled,
    }"
>
    <!-- Setup Link -->
    <a
        v-if="enabled"
        href="#"
        class="kt-menu__link"
        :class="{
            'router-link-exact-active router-link-active': active,
        }"
        @click.stop.prevent="navigate()"
    >
        <i v-if="finalized" class="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
        </i>
        <template v-else>
            <span class="kt-menu__link-icon">
                <i :class="`la ${icon} ${status == '' ? 'kt-menu__link-icon' : 'kt-font-' + status } kt-menu__link-icon`" />
            </span>
        </template>

        <span class="kt-menu__link-text">
            <div class="menu-subtitle text-nowrap text-truncate">
                {{ title }}
            </div>
        </span>
    </a>
    <!-- disabled link -->
    <span
        v-else
        class="kt-menu__link"
    >
        <span class="kt-menu__link-icon">
            <i :class="`la la-minus kt-menu__link-icon`" />
        </span>
        <span class="kt-menu__link-text">
            <div class="menu-subtitle text-nowrap text-truncate text-muted">
                {{ title }}
            </div>
        </span>
    </span>
</li>
</template>

<script>

import Types from '../../../../store/Types';

export default {
    name: 'AsideMenuItemSetupWizard',
    props: {
        routeName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        steps() {
            return this.$store.state.wizard.steps;
        },
        step() {
            const {routeName} = this;
            return this.steps.find(step => step.routeName == routeName);
        },
        title() {
            return this.step.title;
        },
        isToggle() {
            return this.step.isToggle;
        },
        status() {
            return this.step.status;
        },
        value() {
            return this.step.value;
        },
        enabled() {
            return this.step.enabled;
        },
        icon() {
            const { status, isToggle } = this;
            if (status == 'success') {
                return isToggle ? 'la-toggle-on' : 'la-check';
            }
            if (status == 'warning') {
                return isToggle ? 'la-toggle-on' : 'la-warning';
            }
            return 'la-times';
        },
        active() {
            if (!this.step) return false;
            if (this.$route.params && this.$route.params.routeName) {
                return Boolean(this.$route.params.routeName == this.step.routeName);
            }
            return false;
        },
        finalized() {
            return this.$store.state.user.school.finalized;
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            this.key++;
        },
        navigate() {
            const { routeName } = this.step;
            this.$router.push(({ path: `/configuration/wizard/${routeName}` }));
            // this.populate();
        },
    }

};
</script>

<style scoped>
    .menu-subtitle {
        width: 90%;
    }
</style>
