var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "kt-container kt-container-fullscreen overflow-hidden py-0 my-0",
    },
    [
      _vm.report
        ? _c("div", { staticClass: "kt-portlet kt-fullscreen-portlet" }, [
            _c("div", { staticClass: "kt-portlet__head" }, [
              _c("div", { staticClass: "kt-portlet__head-label" }, [
                _c("h3", { staticClass: "kt-portlet__head-title" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                ]),
              ]),
              _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                _c(
                  "div",
                  { staticClass: "kt-portlet__head-group" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-icon",
                        attrs: {
                          to: {
                            name: _vm.$route.name,
                            params: _vm.$route.params,
                            query: _vm.closeQuery,
                          },
                        },
                      },
                      [_c("i", { staticClass: "la la-close" })]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "kt-portlet__body lightbox_body" },
              _vm._l(_vm.report, function (pages) {
                return _c(
                  "span",
                  {
                    key: `StudentOfficialPDF_${pages.studentOfficialPdfId}_page_${pages.pageNumber}`,
                  },
                  [
                    pages.jpgBucketPath
                      ? _c("b-img", {
                          staticClass: "py-5",
                          attrs: { src: pages.jpgBucketPath, "fluid-grow": "" },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
            _c("div", { staticClass: "kt-portlet__foot" }, [
              _c("div", [
                _vm.report && _vm.report[0] && _vm.report[0].pdfBucketPath
                  ? _c(
                      "a",
                      {
                        staticClass: "btn btn-primary pull-right",
                        attrs: {
                          href: _vm.downloadReport(_vm.report[0]),
                          target: "_blank",
                        },
                      },
                      [
                        _c("i", { staticClass: "la la-download" }),
                        _vm._v(" Download PDF "),
                      ]
                    )
                  : _vm._e(),
                _vm._m(0),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head-toolbar d-none" }, [
      _c("div", { staticClass: "kt-portlet__head-group" }, [
        _c("span", { staticClass: "text-muted px-3" }, [_vm._v("Page 1 of 2")]),
        _c(
          "a",
          {
            staticClass: "btn btn-sm btn-icon btn-clean btn-icon-md",
            attrs: { href: "#", "data-ktportlet-tool": "toggle" },
          },
          [_c("i", { staticClass: "la la-angle-left" })]
        ),
        _c(
          "a",
          {
            staticClass: "btn btn-sm btn-icon btn-clean btn-icon-md",
            attrs: { href: "#", "data-ktportlet-tool": "toggle" },
          },
          [_c("i", { staticClass: "la la-angle-right" })]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }