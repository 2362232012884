var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-header__topbar-item kt-header__topbar-item--search dropdown",
      class: { show: _vm.showLocales },
    },
    [
      _c(
        "div",
        {
          staticClass: "kt-header__topbar-wrapper",
          attrs: { "data-toggle": "dropdown", "data-offset": "10px,0px" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.opened = true
              _vm.setLocales("toggle")
            },
          },
        },
        [
          _c("span", { staticClass: "kt-header__topbar-icon" }, [
            _c("img", {
              staticClass: "kt-menu__link-icon flag-image",
              attrs: { src: _vm.selectedFlag },
            }),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "py-2 dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim",
          class: { show: _vm.showLocales },
          attrs: { id: "locales" },
        },
        [
          _vm._l(_vm.locales, function (locale) {
            return [
              _c("li", { key: locale.locale, staticClass: "kt-menu__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "kt-menu__link",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.switchLocale(locale.locale)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "kt-menu__link-icon flag-image",
                      attrs: { src: locale.flag },
                    }),
                    _c(
                      "span",
                      { staticClass: "kt-menu__link-text locale-label" },
                      [_vm._v(" " + _vm._s(locale.label) + " ")]
                    ),
                  ]
                ),
              ]),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }