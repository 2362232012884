<template>
<ul class="kt-menu__nav pt-0">
    <AsideMenuItemHeader title="Data Analytics" />
    <DataAnalyticsMenu />
    <!-- <DataAnalyticsUsageMenu /> -->
</ul>
</template>

<script>
import DataAnalyticsMenu from './DataAnalyticsMenu.vue';
import AsideMenuItemHeader from './AsideMenuItemHeader.vue';

export default {
    name: 'TheDataAnalyticsMenu',
    components: {
        DataAnalyticsMenu,
        // DataAnalyticsUsageMenu,
        AsideMenuItemHeader,
    },
    computed: {
    },
};
</script>
