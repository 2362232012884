var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "kt-menu__item menu-user-header",
      class: {
        "kt-menu__item--open": _vm.open,
        "kt-menu__item--dull-open": _vm.openAndDull,
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "kt-menu__link pt-2 pb-2",
          attrs: { href: "#()-menu" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggle()
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "kt-menu__link-icon" },
            [_c("SVGIcon", { attrs: { name: "wizard" } })],
            1
          ),
          _c("span", { staticClass: "kt-menu__link-text" }, [
            _vm._v(" Term Setup Wizard "),
          ]),
          _c("i", { staticClass: "kt-menu__ver-arrow la la-angle-right" }),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "kt-menu__submenu",
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c("span", { staticClass: "kt-menu__arrow" }),
          _c(
            "ul",
            { staticClass: "kt-menu__subnav" },
            [
              _vm._l(_vm.routes, function (r) {
                return _c("AsideMenuItemSetupWizard", {
                  key: `vue_${r.routeName}`,
                  attrs: { "route-name": r.routeName },
                })
              }),
              !_vm.finalized
                ? _c("li", { staticClass: "kt-menu__item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "kt-menu__link",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.hideWizard === "true"
                              ? (_vm.hideWizard = "")
                              : (_vm.hideWizard = "true")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "kt-menu__link-icon" }, [
                          _c(
                            "i",
                            { staticClass: "la la-navicon kt-menu__link-icon" },
                            [_c("span")]
                          ),
                        ]),
                        _c("span", { staticClass: "kt-menu__link-text" }, [
                          _vm.hideWizard
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "menu-subtitle text-nowrap text-truncate",
                                },
                                [_vm._v(" Hide Full Menu ")]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "menu-subtitle text-nowrap text-truncate",
                                },
                                [_vm._v(" Show Full Menu ")]
                              ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }