<template>
<!--begin: User bar -->
<div
    :class="{'show': showUserBar}"
    class="kt-header__topbar-item kt-header__topbar-item--user"
>
    <div
        class="kt-header__topbar-wrapper"
        @click.stop="setUserBar('toggle')"
    >
        <div class="kt-header__topbar-user">
            <UserAvatar :avatar-user="user">
                <span
                    v-if="!encryptionEnabled || $_userMixins_isSchoolUser"
                    style="height: 32px; font-size: 1rem;"
                    class="kt-badge kt-badge--username kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold"
                >
                    {{ initials }}
                </span>
                <span
                    v-else
                    style="height: 32px; font-size: 1rem;"
                    class="kt-badge kt-badge--username kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold"
                >
                    <i class="fa fa-user" />
                </span>
            </UserAvatar>
        </div>

        <div
            :key="`userBar_${data.idx}`"
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
            :class="{'show': showUserBar}"
            :style="styleObject"
        >
            <!--begin: Head -->
            <div
                class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                style="background-image: url(/images/bg-1.jpg)"
            >
                <div class="kt-user-card__avatar">
                    <user-avatar :avatar-user="user">
                        <span v-if="!encryptionEnabled || $_userMixins_isSchoolUser" class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-brand kt-font-light">
                            {{ initials }}
                        </span>
                        <span v-else class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-brand kt-font-light">
                            <i class="fa fa-user" />
                        </span>
                    </user-avatar>
                </div>
                <div class="kt-user-card__name">
                    {{ !encryptionEnabled || $_userMixins_isSchoolUser ? `${firstName} ${lastName}` : `${mappedUserName || userName}` }}
                </div>
                <div
                    v-if="role"
                    class="kt-user-card__badge"
                >
                    <span
                        class="btn btn-label-brand btn-font-md"
                        style="color: white"
                    >{{ role }}</span>
                </div>
            </div>
            <!--end: Head -->

            <!--begin: Navigation -->
            <div class="kt-notification">
                <router-link
                    :to="{name: 'AccountSessions'}"
                    class="kt-notification__item"
                >
                    <div class="kt-notification__item-icon">
                        <SVGIcon
                            name="account"
                            style="opacity: 0.8"
                        />
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            Account Settings
                        </div>
                        <div class="kt-notification__item-time">
                            {{ userName }}
                        </div>
                    </div>
                </router-link>

                <a
                    v-if="false"
                    href="#"
                    class="kt-notification__item"
                    @click.stop.prevent="clearCache"
                >
                    <div class="kt-notification__item-icon">
                        <SVGIcon
                            name="database"
                            style="opacity: 0.8"
                        />
                    </div>
                    <div
                        class="kt-notification__item-details"
                        :title="dbProperties ? dbProperties.timestamp : ''"
                    >
                        <div class="kt-notification__item-title kt-font-bold">
                            Reset Data Cache
                        </div>
                        <div
                            v-if="dbProperties"
                            class="kt-notification__item-time"
                        >
                            Created {{ getRelativeDate(dbProperties ? dbProperties.timestamp : '') }}
                        </div>
                    </div>
                </a>

                <a
                    href="#"
                    class="kt-notification__item"
                    @click.stop.prevent="showTermPicker()"
                >
                    <div class="kt-notification__item-icon">
                        <i class="flaticon2-calendar-3 kt-font-success" />
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">{{ schoolName }}</div>
                        <div class="kt-notification__item-time">{{ schoolTermName }}</div>
                    </div>
                </a>
                <router-link
                    v-if="isBugReporter"
                    :to="{name: 'CreateBugReport'}"
                    class="kt-notification__item"
                >
                    <div class="kt-notification__item-icon">
                        <i class="la la-bug kt-font-danger" />
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            Submit an issue
                        </div>
                        <div class="kt-notification__item-time">
                            Request a feature or submit an issue.
                        </div>
                    </div>
                </router-link>

                <a
                    v-if="user.school.schoolTermId > 0 && $_userMixins_userRole !== 'Student'"
                    class="kt-notification__item"
                    target="_blank"
                    :href="`/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/feedback/redirect`"
                >
                    <div class="kt-notification__item-icon">
                        <i class="fa fa-vote-yea  kt-font-brand" />
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            Community Portal
                        </div>
                        <div class="kt-notification__item-time">
                            Request features
                        </div>
                    </div>
                </a>

                <a
                    v-if="!$_userMixins_isHomeUser || $_userMixins_isSuperAdmin"
                    :href="wikiUrl"
                    class="kt-notification__item"
                    target="_blank"
                >
                    <div class="kt-notification__item-icon">
                        <i class="flaticon2-document kt-font-primary" />
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">Wiki</div>
                        <div class="kt-notification__item-time">User Documentation</div>
                    </div>
                </a>
                <a
                    v-if="!$_userMixins_isHomeUser || $_userMixins_isSuperAdmin"
                    href="https://syncgrades.com/support"
                    class="kt-notification__item"
                    target="_blank"
                >
                    <div class="kt-notification__item-icon">
                        <i class="flaticon2-mail kt-font-primary" />
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">{{ $t('components.theUserBar.help') }}</div>
                        <div class="kt-notification__item-time">Submit your inquiry here</div>
                    </div>
                </a>
                <div class="kt-notification__custom kt-space-between pull-right">
                    <a
                        href="#"
                        class="kt-badge kt-badge--danger kt-badge--inline kt-badge--md kt-badge--rounded sg-sign-out"
                        @click.stop.prevent="logOut()"
                    >
                        <i class="fa fa-sign-out-alt mr-2" />
                        Sign Out
                    </a>
                    <!-- <a href="#" target="_blank" class="btn btn-clean btn-sm btn-bold">Upgrade</a> -->
                </div>
            </div>
            <!--end: Navigation -->
        </div>
    </div>

    <b-modal
        ref="password-modal"
        size="lg"
        variant="primary"
        centered
        title="Change Your Password"
    >
        <template #modal-header>
            <h5 class="modal-title">
                {{ $t('components.theUserBar.changePassword') }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="resetPasswordModal()"
            />
        </template>
        <template #default>
            <div class="modal-body">
                <div class="form-group row">
                    {{ $t('components.theUserBar.oldPassword') }}: <input
                        id="currentPassword"
                        v-model="data.password.old"
                        type="password"
                        class="form-control"
                    >
                </div>
                <div class="form-group row">
                    {{ $t('components.theUserBar.newPassword') }}: <input
                        id="oldPassword"
                        v-model="data.password.new"
                        type="password"
                        class="form-control"
                    >
                </div>
                <div class="form-group row">
                    {{ $t('components.theUserBar.retypePassword') }}: <input
                        id="againPassword"
                        v-model="data.password.again"
                        type="password"
                        class="form-control"
                    >
                </div>
                <div>
                    <p>{{ $t('components.theUserBar.passwordRequirements.title') }}:</p>
                    <ul>
                        <li>{{ $t('components.theUserBar.passwordRequirements.length') }}</li>
                        <li>{{ $t('components.theUserBar.passwordRequirements.uppercase') }}</li>
                        <li>{{ $t('components.theUserBar.passwordRequirements.digits') }}</li>
                        <li>{{ $t('components.theUserBar.passwordRequirements.special') }}</li>
                        <li>{{ $t('components.theUserBar.passwordRequirements.dictionary') }}</li>
                    </ul>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <div
                class="row"
                style="width: 100%"
            >
                <div class="col-10">
                    <span class="kt-font-danger">{{ data.password.message }}</span>
                </div>
                <div class="col-2">
                    <button
                        type="button"
                        class="btn btn-secondary pull-right"
                        data-dismiss="modal"
                        @click="confirmPassword()"
                    >
                        {{ $t('generic.labels.confirm') }}
                    </button>
                </div>
            </div>
        </template>
    </b-modal>

    <b-modal
        ref="term-picker-modal"
        variant="primary"
    >
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                {{ $t('components.theUserBar.selectTerm') }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
            />
        </template>
        <template #default>
            <div class="form-group row">
                <div
                    v-for="(term, i) in sortedSchoolTerms"
                    :key="'term'+i"
                    class="kt-notification-v2"
                    style="width: 100%"
                >
                    <a
                        href="#"
                        class="kt-notification-v2__item"
                        @click.stop.prevent="selectTerm(term.schoolTermId)"
                    >
                        <div class="kt-notification-v2__item-icon">
                            <i
                                :class="{
                                    'kt-font-success': i == user.termIndex,
                                    'kt-font-dark': !i == user.termIndex,
                                    'flaticon2-calendar-3': !term.hasCourses,
                                    'flaticon-presentation': term.hasCourses,
                                }"
                            />
                        </div>
                        <div class="kt-notification-v2__itek-wrapper">
                            <div
                                class="kt-notification-v2__item-title"
                                :class="{'kt-font-primary': i == user.termIndex}"
                            >
                                {{ term.schoolName }}
                            </div>
                            <div class="kt-notification-v2__item-desc kt-font-bolder">
                                {{ term.name }}
                                <span v-if="term.current"> - {{ $t('components.theUserBar.current').toUpperCase() }}</span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </template>
        <template #modal-footer />
    </b-modal>
</div>

<!--end: User bar -->
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import UserAvatar from '../../../../components/UserAvatar.vue';
import network from '../../../../lib/network';
import globalMixins from '../../../../store/mixins/globalMixins';
import Types from '../../../../store/Types';
import userMixins from '../../../../store/mixins/userMixins';

export default {
    name: 'TheUserBar',
    components: {
        UserAvatar,
    },
    mixins: [globalMixins, userMixins],
    data() {
        return {
            data: {
                idx: 0,
                imageLoaded: false,
                key: 'select',
                demoDistrict: false,
                password: {
                    old: null,
                    new: null,
                    again: null,
                    message: null,
                },
                selectedRole: '',
                selectedSchool: '',
            },
        };
    },
    computed: {
        ...mapState({
            showUserBar: (state) => state.navigation.showUserBar,
            user: (state) => state.user,
            mappedUserName: (state) => state.user.mappedUserName,
            userName: (state) => state.user.userName,
            firstName: (state) => state.user.firstName,
            lastName: (state) => state.user.lastName,
            schoolName: (state) => state.user.school.schoolName,
            schoolTermName: (state) => state.user.school.name,
            userRoles: (state) => state.user.school.role.replace('School ', ''),
            role: (state) => state.user.school.role.replace('School ', ''),
            initials(state) {
                if (state.user.lastName == '') return '';
                return (
                    state.user.lastName.substr(0, 2).toUpperCase()
                );
            },
            sortedSchoolTerms(state) {
                const terms = state.user.schoolTerms.slice();
                terms.sort((a, b) => ((a.name > b.name) ? 1 : -1));
                return terms;
            },
            styleObject() {
                return {
                    position: 'absolute',
                    top: '60px',
                    right: '14px',
                    left: 'auto',
                };
            },
        }),
        rootWikiPath() {
            return 'https://wiki.syncgrades.com/en/home';
        },
        hasWikiUrl() {
            return this.wikiUrl !== this.rootWikiPath;
        },
        wikiUrl() {
            const { helpRedirects } = this.$store.state.database;
            const routeName = this.$route.name;
            if ( helpRedirects && helpRedirects.hasOwnProperty(routeName) ) {
                return helpRedirects[routeName];
            }
            return this.rootWikiPath;
        },
        isSuperAdmin() {
            return this.$store.state.user.isSuperAdmin;
        },
        showDangerColor() {
            if (!this.isSuperAdmin) return false;
            return !this.hasWikiUrl;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        cache() {
            return this.$store.state.database.cache;
        },
        dbProperties() {
            const { cache } = this;
            return cache.find((i) => i.key == 'baseTables') || null;
        },
        isBugReporter() {
            const v = this;
            if (v.user.isSuperAdmin || ['School Admin'].includes(v.user.school.role)) return true;
            return v.$_userMixins_isSchoolUser && v.user.userPermissions.canSubmitBugReports;
        },
    },
    watch: {
        showUserBar() {
            this.data.idx += 1;
        },
        user: {
            deep: true,
            handler() {
                const v = this;
                if (v.user && v.user.schoolTerms) {
                    const currentTerm = v.user.schoolTerms.find((term) => term.current);
                    if (currentTerm) v.data.demoDistrict = currentTerm.demoDistrict;
                } else v.data.demoDistrict = false;
            },
        },
    },
    methods: {
        getRelativeDate(dt) {
            if (!dt) return '';
            return moment(dt, 'YYYY-MM-DD HH:mm:ss').fromNow();
        },
        showTermPicker() {
            this.$refs['term-picker-modal'].show();
            this.$store.commit('setUserBar', false);
        },
        showPasswordModal() {
            this.$refs['password-modal'].show();
            this.$store.commit('setUserBar', false);
        },
        selectTerm(schoolTermId) {
            this.$store.commit(Types.mutations.SET_SCHOOL_TERM, schoolTermId);
            this.$router.push({ name: 'Home' });
        },
        clearCache() {
            this.storageClear(() => {
                window.location.reload();
            });
        },
        logOut() {
            const { user } = this;
            const isNYC = Boolean(!user.isSuperAdmin && user.userName.indexOf('@schools.nyc.gov') > -1);
            if (isNYC) {
                const w = window.open('https://idpcloud.nycenet.edu/usc/signout', '_blank');
                if (w) {
                    setTimeout(() => {
                        // window.console.log(w);
                        w.close();
                        window.location.href = '/logout';
                    }, 2000);
                }
            } else {
                window.location.href = '/logout';
            }
        },
        confirmPassword() {
            const v = this;

            if (v.data.password.new != v.data.password.again) {
                const passwordError = v.getReadablePasswordError(['mismatch']);
                v.data.password.message = passwordError;
                return;
            }
            network.auth.changePassword(v.data.password.old, v.data.password.new, (err, result) => {
                if (err) {
                    v.$refs['password-modal'].hide();
                    return v.prettyAlert(
                        v.$bvModal,
                        'Error',
                        'An unknown error occurred, please try again.',
                        null,
                    );
                }
                if (result.success) {
                    v.resetPasswordModal();
                    return v.prettyAlert(
                        v.$bvModal,
                        'Success',
                        'Your password has been changed.',
                        null,
                    );
                }
                const passwordError = v.getReadablePasswordError(result.errors);
                v.data.password.message = passwordError;
            });
        },
        getReadablePasswordError(errors) {
            if (errors.includes('current')) return 'The old password you entered does not match our records, please try again';
            const readableMap = {
                mismatch: 'passwords don\'t match',
                min: 'minimum length',
                uppercase: 'uppercase letters',
                lowercase: 'lowercase letters',
                digits: 'digits',
                symbols: 'symbols',
                dictionary: 'dictionary words',
                pwned: 'known compromised',
            };
            const readableErrors = errors.map((error) => readableMap[error]);
            return `Your new password does not meet the listed password strength rules: ${readableErrors.join(', ')}.`;
        },
        resetPasswordModal() {
            const v = this;
            v.data.password.old = null;
            v.data.password.new = null;
            v.data.password.again = null;
            v.data.password.message = null;
            v.$refs['password-modal'].hide();
        },
        ...mapMutations(['setUserBar']),
    },
};

</script>
<style scoped>

a.kt-notification__item {
    text-decoration: none;
}
div.kt-user-card__name {
    overflow: hidden;
}

div.kt-user-card__name span.text-muted {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: block;
}

button.sg-button {
    border-style: none;
}

.sg-sign-out {
    min-width: 80px;
}

.btn.sg-role-badge {
    line-height: 0.6em;
}

</style>
