var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop",
    },
    [
      _c("div", { staticClass: "kt-container kt-container--fluid" }, [
        _c(
          "div",
          { staticClass: "kt-footer__copyright" },
          [
            _c("b-spinner", {
              staticClass: "mt-1",
              attrs: { small: "", variant: "primary" },
            }),
            _c("span", { staticClass: "ml-3" }, [
              _vm._v(" " + _vm._s(_vm.caption) + " "),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "kt-footer__menu" }, [
          _c(
            "a",
            {
              staticClass: "kt-footer__menu-link kt-link",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.abortRequests()
                },
              },
            },
            [_vm._v(" Cancel "), _c("i", { staticClass: "la la-times" })]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }