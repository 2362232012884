var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-content-between py-1 px-3 my-2",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.itemSelect()
        },
      },
    },
    [
      _c("div", { staticClass: "kt-user-card-v2" }, [
        _c(
          "div",
          { staticClass: "kt-user-card-v2__pic" },
          [
            _c("CourseIcon", {
              staticClass: "course-icon",
              attrs: { course: _vm.course, size: "md" },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "p-2" }, [
          _c(
            "div",
            {
              staticClass:
                "d-block course-title w-100 kt-font-bold kt-font-lg kt-font-dark",
            },
            [_vm._v(" " + _vm._s(_vm.course.name) + " ")]
          ),
          _c("div", { staticClass: "d-block w-100" }, [
            _vm._v(" " + _vm._s(_vm.teachers) + " "),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "p-2 text-right" }, [
        _c("span", { staticClass: "d-block" }, [
          _vm._v(" " + _vm._s(_vm.course.meetingName) + " "),
        ]),
        _c("span", { staticClass: "d-block" }, [
          _vm._v(" " + _vm._s(_vm.course.extCourseSectionId) + " "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }