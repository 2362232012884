import { render, staticRenderFns } from "./TheDatabaseUpdateNotification.vue?vue&type=template&id=8dafd29e"
import script from "./TheDatabaseUpdateNotification.vue?vue&type=script&lang=ts"
export * from "./TheDatabaseUpdateNotification.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8dafd29e')) {
      api.createRecord('8dafd29e', component.options)
    } else {
      api.reload('8dafd29e', component.options)
    }
    module.hot.accept("./TheDatabaseUpdateNotification.vue?vue&type=template&id=8dafd29e", function () {
      api.rerender('8dafd29e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/TheRouterLayout/components/TheDatabaseUpdateNotification.vue"
export default component.exports