var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "google-course-modal",
    attrs: { size: "lg", "no-close-on-backdrop": "", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title kt-font-danger" }, [
              _vm._v(" Combo Courses Using Different Grade Templates "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c(
                  "div",
                  { staticClass: "form-group form-group-last pt-3 pb-5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-secondary",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("div", { staticClass: "alert-icon" }, [
                          _c("i", {
                            staticClass: "flaticon-danger kt-font-brand",
                          }),
                        ]),
                        _c("div", { staticClass: "alert-text" }, [
                          _vm._v(" Each combo courses associated with "),
                          _c("code", [_vm._v(_vm._s(_vm.extCourseSectionId))]),
                          _vm._v(
                            " MUST share the same Grade Template. To fix this, choose the same Grade Template for all courses listed below and press save. "
                          ),
                          _c("br"),
                          _c("br"),
                          _c("code", [
                            _vm._v(
                              "Your combo courses may not work correctly until they share the same Grade Template."
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._l(_vm.changes, function (item) {
                  return _c(
                    "div",
                    {
                      key: `courseSectionId_${item.courseSectionId}`,
                      staticClass: "form-group row px-5",
                    },
                    [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("label", [_vm._v("Course")]),
                        _c("input", {
                          staticClass: "form-control col-md-10",
                          attrs: { type: "text", disabled: "" },
                          domProps: {
                            value: `${item.extCourseSectionId}: ${item.name}`,
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("label", [_vm._v("Grade Template")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.gradeTemplateId,
                                expression: "item.gradeTemplateId",
                              },
                            ],
                            staticClass: "custom-select form-control col-md-10",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  item,
                                  "gradeTemplateId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.gradeTemplates, function (t) {
                            return _c(
                              "option",
                              {
                                key: `option_t_${t.gradeTemplateId}_${item.gradeTemplateId}`,
                                domProps: {
                                  value: t.gradeTemplateId,
                                  selected:
                                    t.gradeTemplateId == item.gradeTemplateId,
                                },
                              },
                              [_vm._v(" " + _vm._s(t.gradeTemplateName) + " ")]
                            )
                          }),
                          0
                        ),
                        !item.gradeTemplateId
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "form-text text-muted kt-font-danger",
                              },
                              [_vm._v("Select a new Grade Template")]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", {
                        staticClass:
                          "kt-separator kt-separator--space-md kt-separator--border-dashed",
                      }),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.ignore.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Ignore For Now ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary alignment pull-right",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  disabled: _vm.saving || !_vm.isValid,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.save()
                  },
                },
              },
              [_vm._v(" Save Changes ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }