var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loadState == "BOOTING"
    ? _c("TheDatabaseUpdateNotification")
    : _vm.pendingDownloads.length > 0
    ? _c("TheNetworkRequestNotification")
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }