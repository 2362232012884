<template>
<li
    class="kt-menu__item menu-user-header"
    :class="{
        'kt-menu__item--open': open,
        'kt-menu__item--dull-open': openAndDull
    }"
>
    <a
        href="#toggle-menu"
        class="kt-menu__link pt-2 pb-2"
        @click.stop.prevent="toggle"
    >
        <span class="kt-menu__link-icon">
            <SVGIcon name="reportCards" />
        </span>

        <span class="kt-menu__link-text">
            Report Cards
        </span>

        <i class="kt-menu__ver-arrow la la-angle-right" />
    </a>
    <b-collapse
        v-model="open"
        class="kt-menu__submenu"
    >
        <span class="kt-menu__arrow" />
        <ul class="kt-menu__subnav">
            <AsideMenuItem
                title="Upload Excel Files"
                route-name="UploadReportCards"
            />
            <AsideMenuItem
                title="Set Marking Periods"
                route-name="ReportCardMarkingPeriods"
            />
            <AsideMenuItem
                title="Define Valid Marks"
                route-name="ValidMarks"
            />
            <AsideMenuItem
                title="Download Data"
                route-name="DownloadReportCards"
            />
        </ul>
    </b-collapse>
</li>
</template>

<script lang="ts">
import AsideMenuItem from './AsideMenuItem.vue';

export default {
    name: 'TheReportCardMenu',
    components: {
        AsideMenuItem,
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        openAndDull() {
            return Boolean(this.isActive && this.open);
        },
        isActive() {
            return this.$route.fullPath.indexOf('/configuration/report-cards/') > -1;
        },
    },
    watch: {
        $route() {
            this.setOpenState();
        },
    },
    mounted() {
        this.setOpenState();
    },
    methods: {
        toggle() {
            this.open = !this.open;
            setTimeout(() => {
                if (this.open) {
                    // scroll to bottom of menu
                    // document.getElementById('pscroll').parentNode.scrollTop = 999999;
                }
            }, 300);
        },
        setOpenState() {
            const { isActive } = this;
            this.open = Boolean(isActive);
        },
    },
};
</script>

<style lang="scss">
.kt-menu__item--dull-open {
    .kt-menu__link-text {
        color: #3d4456 !important;
    }
    .kt-menu__link-icon svg g [fill] {
        fill: #c4cff9 !important;;
    }
}
</style>
