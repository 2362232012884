<template>
<div
    v-if="showFooter"
    id="kt_footer"
    class="kt-footer  kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
>
    <div class="kt-container kt-container--fluid">
        <div class="kt-footer__copyright">
            {{ year }}&nbsp;&copy;&nbsp;
            <a
                href="https://www.syncgrades.com/"
                target="_blank"
                class="kt-link"
            >Lilo Consulting LLC</a>
        </div>
        <div class="kt-footer__menu">
            <a
                href="https://www.syncgrades.com/"
                target="_blank"
                class="kt-footer__menu-link kt-link"
            >{{ $t('components.theFooter.contactUs') }}</a>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment';

export default {
    computed: {
        year() {
            return moment().format('YYYY');
        },
        showFooter() {
            return Boolean(!this.$route);
        },
    },
};

</script>
