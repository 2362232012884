<template>
<li
    class="kt-menu__item menu-user-header"
    :class="{
        'kt-menu__item--open': classMenuOpen,
    }"
>
    <a
        href="#toggle-menu"
        class="kt-menu__link pt-2 pb-2"
        @click.stop.prevent="classMenuOpen = !classMenuOpen"
    >
        <span
            class="kt-menu__link-icon"
            :class="{
                'kt-menu__link-icon--dull': openAndDull
            }"
        >
            <SVGIcon name="courses" />
        </span>

        <span class="kt-menu__link-text">
            Classes
        </span>

        <i class="kt-menu__ver-arrow la la-angle-right" />
    </a>
    <b-collapse
        v-model="classMenuOpen"
        class="kt-menu__submenu"
    >
        <span class="kt-menu__arrow" />
        <ul class="kt-menu__subnav">
            <slot />
        </ul>
    </b-collapse>
</li>
</template>

<script>

export default {
    name: 'StudentPortfolioClassMenu',
    props: {
    },
    data() {
        return {
            classMenuOpen: false,
        };
    },
    computed: {
        openAndDull() {
            return Boolean(!this.extCourseSectionId && this.classMenuOpen);
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId || null;
        },
    },
    methods: {
        setOpen(bool) {
            this.classMenuOpen = bool;
        },
    },
};

</script>

<style lang="scss">
.kt-menu__link-icon--dull svg g [fill] {
    fill: #c4cff9 !important;
}
</style>
<style scoped src='../../../../css/colors.scss' lang="scss" />
