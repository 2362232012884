<template>
<TheDatabaseUpdateNotification v-if="loadState == 'BOOTING'" />
<TheNetworkRequestNotification v-else-if="pendingDownloads.length > 0" />
</template>

<script lang="ts">

import TheNetworkRequestNotification from './TheNetworkRequestNotification.vue';
import TheDatabaseUpdateNotification from './TheDatabaseUpdateNotification.vue';

export default {
    name: 'TheFooterNotification',
    components: {
        TheDatabaseUpdateNotification,
        TheNetworkRequestNotification,
    },
    data() {
        return {
            showFooter: false,
        };
    },
    computed: {
        loadState() {
            return this.$store.state.database.loadState;
        },
        pendingDownloads() {
            const { cache } = this.$store.state.database;
            return cache.filter((k) => k.status == 'loading' && k.cacheType != 'portfolioCalc');
        },
    },
    watch: {
        pendingDownloads() {
            this.init();
        },
        loadState() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            const show = this.shouldShowFooter();
            if (show) {
                this.showFooter = true;
                document.body.classList.add('kt-footer--fixed');
            } else {
                this.hideWithDelay();
            }
        },
        shouldShowFooter() {
            return Boolean(this.pendingDownloads.length > 0 || this.loadState == 'BOOTING');
        },
        hideWithDelay() {
            const v = this;
            document.body.classList.remove('kt-footer--fixed');
            setTimeout(() => {
                if (v.shouldShowFooter() == false) {
                    v.showFooter = false;
                }
            }, 1200);
        },
    },
};

</script>

<style scoped>
.kt-footer {
    z-index: 1;
}
</style>
