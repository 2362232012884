<template>
<div
    v-if="isPortfolioReady && selectedCourse && $_userMixins_isSchoolUser"
    class="kt-header-menu-wrapper w-100"
>
    <div
        id="course-popover"
        :key="`course_popover_${key}`"
        class="kt-header__topbar-wrapper course-container w-100 pl-4 pr-4"
        @click.stop.prevent="toggleShow()"
    >
        <div
            :class="show ? 'course-popover-shown' : ''"
            class="d-flex justify-content-between mb-2 mt-2"
        >
            <div class="kt-user-card-v2">
                <div class="kt-user-card-v2__pic">
                    <UserAvatar
                        v-if="teacher"
                        class="user-avatar"
                        :avatar-user="teacher"
                    >
                        <span
                            style="height: 32px; font-size: 1rem;"
                            class="kt-badge kt-badge--username kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold"
                        >
                            {{ initials }}
                        </span>
                    </UserAvatar>

                    <CourseIcon
                        v-else
                        class="course-icon"
                        :course="selectedCourse"
                        size="md"
                    />

                    <span class="hidden-down-arrow">
                        <SVGIcon
                            hex-color="#c0c4d1"
                            :name="!show ? 'leftNavigationDoubleArrowRight' : 'leftNavigationDoubleArrowDown'"
                        />
                    </span>
                </div>
                <div class="p-2">
                    <div class="d-block w-100 kt-font-bold kt-font-lg kt-font-dark">
                        {{ selectedCourse.name }}
                    </div>
                    <div class="d-block w-100">
                        {{ teachers }}
                    </div>
                </div>
            </div>
            <div class="p-2 text-right">
                <span class="d-block">
                    {{ selectedCourse.meetingName }}
                </span>
                <span class="d-block">
                    {{ selectedCourse.extCourseSectionId }}
                </span>
            </div>
        </div>
    </div>
    <b-popover
        target="course-popover"
        triggers="focus"
        placement="bottom"
        custom-class="popover-class"
        :show.sync="show"
    >
        <div class="kt-quick-search">
            <form
                class="kt-quick-search__form"
                @submit.stop.prevent="selectCourse()"
            >
                <div
                    class="input-group"
                    style="background: transparent !important;"
                >
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="flaticon2-search-1" />
                        </span>
                    </div>
                    <input
                        id="course-search"
                        v-model="searchTerm"
                        type="text"
                        name="term"
                        style="background: transparent !important;"
                        class="form-control kt-quick-search__input"
                        autocomplete="off"
                        placeholder="Search courses"
                        @keyup.13="selectCourse()"
                        @keyup.40="moveDown()"
                        @keyup.38="moveUp()"
                    >
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="la la-close kt-quick-search__close" />
                        </span>
                    </div>
                </div>
            </form>
        </div>

        <div class="kt-widget4">
            <CourseBadge
                v-for="(course, idx) in searchResults"
                :key="`${course.extCourseSectionId}_course`"
                :class="selectedIndex == idx ? 'active' : ''"
                :color="$_courseMixins_getColorForCourse(course)"
                :course="course"
                :index="idx"
                :on-selected="onSelected"
                :short-title="course.extCourseSectionId.substring(0, 1) + course.extCourseSectionId.slice(-1)"
            />
        </div>
    </b-popover>
</div>
</template>

<script>
import CourseBadge from '../../../../components/CourseBadge.vue';
import CourseIcon from '../../../../components/CourseIcon.vue';
import courseMixins from '../../../../store/mixins/courseMixins';
import portfolioMixins from '../../../../store/mixins/portfolioMixins';
import teacherMixins from '../../../../store/mixins/teacherMixins';
import userMixins from '../../../../store/mixins/userMixins';
import UserAvatar from '../../../../components/UserAvatar.vue';

export default {
    name: 'TheTopLeftCourseMenu',
    components: {
        UserAvatar,
        CourseBadge,
        CourseIcon,
    },
    mixins: [
        courseMixins,
        teacherMixins,
        userMixins,
        portfolioMixins,
    ],
    data() {
        return {
            key: 0,
            courses: [],
            selectedIndex: 0,
            searchTerm: '',
            show: false,
        };
    },
    computed: {
        isPortfolioReady() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        courseSections() {
            return this.$store.state.database.courseSections;
        },
        initials() {
            const { teacher } = this;
            if (!teacher) return '';
            return teacher.lastName.substr(0, 2).toUpperCase();
        },
        selectedCourse() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        selectedTeacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        selectedTeacherClasses() {
            if (!this.selectedTeacher) return [];
            const { schoolStaffId } = this.selectedTeacher;
            return this.$_courseMixins_getCoursesForStaffId(schoolStaffId);
        },
        myClasses() {
            const { schoolStaffId } = this.$store.state.user.school;
            return this.$_courseMixins_getCoursesForStaffId(schoolStaffId);
        },
        teacher() {
            const { selectedCourse } = this;
            if (!selectedCourse || !selectedCourse.teachers) return null;
            const [teacher] = selectedCourse.teachers;
            if (!teacher) return null;
            return teacher;
        },
        teachers() {
            const { selectedCourse } = this;
            if (!selectedCourse || !selectedCourse.teachers) return null;
            return selectedCourse.teachers
                .map((t) => `${t.lastName} ${t.firstName.substring(0, 1)}`)
                .join(', ');
        },
        searchResults() {
            const { myClasses, selectedTeacher, selectedTeacherClasses } = this;
            const searchTerm = ('' || this.searchTerm).toLowerCase();
            if (!searchTerm) {
                // default list on open
                if (selectedTeacher) {
                    return selectedTeacherClasses;
                }
                return myClasses;
            }
            const output = this.courses.filter((c) => (c.search.indexOf(searchTerm) > -1));
            return output.slice(0, 5);
        },
    },
    watch: {
        searchTerm() {
            this.selectedIndex = 0;
        },
        courseSections() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
        const v = this;
        this.$root.$on('bv::popover::shown', () => {
            v.selectedIndex = 0;
            v.focus();
        });
    },
    methods: {
        populate() {
            if (this.isPortfolioReady) {
                this.courses = this.$_courseMixins_getReducedCourses();
            }
        },
        onSelected(index) {
            this.selectedIndex = index;
            this.selectCourse();
        },
        toggleShow() {
            this.show = !this.show;
        },
        focus() {
            setTimeout(() => {
                const input = document.getElementById('course-search');
                if (!input) return;
                input.focus();
                // @ts-ignore
                input.select();
            }, 100);
        },
        moveDown() {
            const { selectedIndex, searchResults } = this;
            if (selectedIndex >= searchResults.length - 1) return;
            this.selectedIndex += 1;
        },
        moveUp() {
            const { selectedIndex } = this;
            if (selectedIndex == 0) return;
            this.selectedIndex -= 1;
        },
        selectCourse() {
            const { selectedIndex } = this;
            if (this.searchResults.length >= selectedIndex) {
                const course = this.searchResults[selectedIndex];
                const { extCourseSectionId, teachers } = course;
                const [teacher] = teachers;
                if (!teacher) {
                    this.$router.push({
                        name: 'CourseInfo',
                        params: {
                            extCourseSectionId,
                        },
                    });
                } else {
                    const { teacherRouteName } = this.$route.meta;
                    this.$router.push({
                        name: teacherRouteName || 'TeacherCourseInfo',
                        params: {
                            schoolEmail: teacher.schoolEmail,
                            extCourseSectionId,
                        },
                    });
                }
                this.show = false;
            }
        },
    },
};
</script>

<style>
.popover-class .arrow {
    visibility: hidden;
}
</style>
<style scoped src='../../../../css/colors.scss' lang="scss" />

<style scoped>
.course-icon {
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
    color: #c0c4d1;
}

div.course-container {
    cursor: pointer;
    max-width: 600px;
}

.popover-class {
    min-width: 400px;
    max-width: 600px;
    width: 100%;
    margin-left: 1rem;
}

.course-container:hover .course-icon,
.course-container:hover .user-avatar {
    display: none;
}

.hidden-down-arrow {
    width: 27px;
    margin-left: 6px;
    margin-right: 6px;
    display: none;
}

.course-container:hover .hidden-down-arrow {
    display: block;
}

.course-container .kt-font-bold {
    color: #c0c4d1 !important;
}

.course-container *.d-block {
    color: #c0c4d1;
}

.course-container:hover .kt-font-bold {
    color: #0f88ef !important;
}

.course-popover-shown *.d-block {
    color: #fff !important;
}
</style>
