<template>
<div
    :key="key"
    @click="closeAllNavigationPopovers"
>
    <OneTimePassword v-if="cipherSessionLocked" />
    <RequireTwoFactorAuth v-else-if="require2fa" />
    <template v-else>
        <!-- Mobile Header -->

        <div
            v-if="loggedIn"
            id="kt_header_mobile"
            class="kt-header-mobile kt-header-mobile--fixed"
        >
            <div class="kt-header-mobile__toolbar">
                <button
                    id="kt_aside_mobile_toggler"
                    class="kt-header-mobile__toggler kt-header-mobile__toggler--left"
                    @click="setMobileMenu('toggle')"
                >
                    <span />
                </button>
            </div>

            <div class="kt-header-mobile__logo">
                <a
                    href="#"
                    @click.stop.prevent="goHome()"
                >
                    <img
                        alt="SyncGrades"
                        src="/logos/logo-text-dark.svg"
                        style="width: 128px;"
                    >
                </a>
            </div>
            <div class="kt-header-mobile__toolbar">
                <button
                    id="kt_header_mobile_topbar_toggler"
                    class="kt-header-mobile__topbar-toggler"
                    @click="setMobileTopBar('toggle')"
                >
                    <i class="flaticon-more" />
                </button>
            </div>
        </div>

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <TheAsideMenu v-if="loggedIn" :key="`app_${key}`" />

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" style="overflow-x: hidden;">
                    <!-- Top Navigation -->
                    <div
                        v-if="loggedIn"
                        id="kt_header"
                        class="kt-header kt-grid__item kt-header--fixed"
                    >
                        <button class="kt-header-menu-wrapper-close">
                            <i class="la la-close" />
                        </button>

                        <div v-if="$_userMixins_isHomeUser" class="kt-header-menu-wrapper w-100" />
                        <TheTopLeftCourseMenu v-else-if="isCourseView && databaseIsReady" />
                        <div
                            v-else
                            id="kt_header_menu_wrapper"
                            class="kt-header-menu-wrapper w-100"
                        >
                            <div
                                v-if="!$_userMixins_isDisabled && ((!hideAllNavigation && $_userMixins_isSchoolUser) || $_userMixins_isSuperAdmin)"
                                id="kt_header_menu"
                                class="kt-header-menu kt-header-menu-mobile kt-header-menu--layout-default"
                            >
                                <ul class="kt-menu__nav">
                                    <li class="kt-menu__item">
                                        <router-link
                                            :to="{name: 'Teachers'}"
                                            class="kt-menu__link"
                                        >
                                            <span class="kt-menu__link-text"> Teachers </span>
                                        </router-link>
                                    </li>
                                </ul>
                                <ul class="kt-menu__nav">
                                    <li class="kt-menu__item">
                                        <router-link
                                            :to="{name: 'CourseList'}"
                                            class="kt-menu__link"
                                        >
                                            <span class="kt-menu__link-text"> Courses </span>
                                        </router-link>
                                    </li>
                                </ul>
                                <ul class="kt-menu__nav">
                                    <li class="kt-menu__item">
                                        <router-link
                                            :to="{name: 'Students'}"
                                            class="kt-menu__link"
                                        >
                                            <span class="kt-menu__link-text"> Students </span>
                                        </router-link>
                                    </li>
                                </ul>
                                <ul class="kt-menu__nav">
                                    <li class="kt-menu__item">
                                        <router-link
                                            :to="{name: 'AnecdotalLogs'}"
                                            href="/anecdotal-logs"
                                            class="kt-menu__link"
                                        >
                                            <span class="kt-menu__link-text"> Logs </span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <TheTopRightButtons />
                    </div>

                    <!-- Page error in QS -->
                    <PageError v-if="$route.query.error" />

                    <!-- Big Cog Background Spinner -->
                    <template v-else-if="loggedIn && !databaseIsReady">
                        <TheLoadingIndicator class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" />
                    </template>

                    <template v-else-if="databaseIsReady && !cipherSessionLocked">
                        <!-- Actual Router View Content -->
                        <div id="kt_content" :class="`${deviceType}-device kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor pb-0`">
                            <LightBox v-if="showLightbox" />

                            <PasswordAlert v-if="isLocalDev && $_userMixins_isSchoolUser && !hasLocalPassword && $route.name !== 'Password'" />

                            <!-- <keep-alive> -->
                            <router-view v-show="!showLightbox" />
                            <!-- </keep-alive> -->

                            <template v-if="!$_userMixins_isDisabled && $_userMixins_isSchoolUser">
                                <!-- Communication Popovers -->
                                <CreateChatGroup />
                                <PopupChat v-if="$_userMixins_isSchoolUser" />

                                <!-- Anecdotal Popover -->
                                <CreateAnecdotal />

                                <!-- Add Student Badge -->
                                <AddStudentBadge />
                            </template>

                            <CreateMessage v-if="!$_userMixins_isDisabled && showCreateMessage" />
                            <CreateAnnouncement v-if="!$_userMixins_isDisabled && $_userMixins_isSchoolUser && showCreateAnnouncement" />
                            <CreateSchoolAnnouncement v-if="!$_userMixins_isDisabled && $_userMixins_isSchoolAdmin && showCreateSchoolAnnouncement" />

                            <MessageNotification
                                :notification-messages="notificationMessages"
                                :clear-notification="clearMessageNotification"
                            />

                            <GlobalModals />

                            <!-- Database Update Alerts -->
                            <TheFooterNotification />
                        </div>
                    </template>

                    <template v-else>
                        <!-- 404 -->
                        <router-view />
                    </template>

                    <TheFooter />
                </div>
            </div>
        </div>
        <div
            id="kt_quick_panel"
            class="kt-quick-panel"
        >
            <TheQuickPanel v-if="databaseIsReady">
                <template #close>
                    <a
                        id="kt_quick_panel_close_btn"
                        href="#"
                        class="kt-quick-panel__close"
                        @click.stop.prevent="closeQuickPanel"
                    >
                        <i class="flaticon2-delete" />
                    </a>
                </template>
            </TheQuickPanel>
        </div>
        <div
            v-if="showQuickPanel"
            class="kt-quick-panel-overlay"
            @click.stop.prevent="closeQuickPanel()"
        />
    </template>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState, mapGetters, mapMutations } from 'vuex';
import async from 'async';
import moment from 'moment';
import * as localForage from 'localforage';

import * as network from '../../network';
import models from '../../models';
import CreateAnecdotal from '../../components/CreateAnecdotal.vue';
import CreateMessage from '../../components/Communication/CreateMessage.vue';
import CreateAnnouncement from '../../components/Communication/CreateAnnouncement.vue';
import CreateSchoolAnnouncement from '../../components/Communication/CreateSchoolAnnouncement.vue';
import CreateChatGroup from '../../components/Communication/CreateChatGroup.vue';
import MessageNotification from '../../components/Communication/MessageNotification.vue';
import PopupChat from '../../components/Communication/PopupChat.vue';
import AddStudentBadge from '../../components/AddStudentBadge.vue';
import GlobalModals from '../../components/GlobalModals.vue';
import LightBox from '../../rootComponents/LightBox.vue';
import OneTimePassword from '../../components/OneTimePassword/OneTimePassword.vue';
import PasswordAlert from '../../components/PasswordAlert.vue';
import PageError from '../../components/PageError.vue';
import RequireTwoFactorAuth from '../../rootComponents/RequireTwoFactorAuth.vue';
import router from '../../router/index';
import TheAsideMenu from './components/TheAsideMenu/TheAsideMenu.vue';
import TheFooter from './components/TheFooter/TheFooter.vue';
import TheFooterNotification from './components/TheFooterNotification.vue';
import TheLoadingIndicator from './components/TheLoadingIndicator/TheLoadingIndicator.vue';
import TheQuickPanel from './components/TheQuickPanel.vue';
import TheTopLeftCourseMenu from './components/TheTopLeftNavigation/TheTopLeftCourseMenu.vue';
import TheTopRightButtons from './components/TheTopContainer/TheTopRightButtons.vue';
import Types from '../../store/Types';
import globalMixins from '../../store/mixins/globalMixins';
import userMixins from '../../store/mixins/userMixins';
import { getTeacherFromRoute } from '../../store/mixins/teacherMixins';
import { getStudentFromRoute } from '../../store/mixins/studentMixins';

export default Vue.extend({
    name: 'TheRouterLayout',
    components: {
        CreateAnecdotal,
        AddStudentBadge,
        CreateMessage,
        CreateAnnouncement,
        CreateSchoolAnnouncement,
        CreateChatGroup,
        MessageNotification,
        PopupChat,
        GlobalModals,
        LightBox,
        OneTimePassword,
        PasswordAlert,
        PageError,
        RequireTwoFactorAuth,
        TheAsideMenu,
        TheFooter,
        TheFooterNotification,
        TheLoadingIndicator,
        TheQuickPanel,
        TheTopLeftCourseMenu,
        TheTopRightButtons,
    },
    mixins: [globalMixins, userMixins],
    data() {
        return {
            debounceResize: null,
            notificationMessages: [],
            webSocketTimeOut: null,
        };
    },
    computed: {
        ...mapState({
            databaseIsReady: (state) => state.database.loadState === 'READY',
            error: (state) => state.error || null,
            hasError: (state) => (!!state.error),
            hideAllNavigation: (state) => state.navigation.hideAllNavigation || state.user.school.schoolId == 0,
            isAdmin: (state) => state.user.school.role == 'School Admin',
            loading: (state) => state.navigation.loading,
            loadState: (state) => state.database.loadState,
            loggedIn: (state) => Boolean(state.user.school.schoolId > 0),
            schoolTermId: (state) => state.user.school.schoolTermId,
            setStickyAsideMenu: (state) => state.user.school.role != 'Student' && state.navigation.showAside,
            showMobileMenu: (state) => state.navigation.showMobileMenu,
            showMobileTopBar: (state) => state.navigation.showMobileTopBar,
            showQuickPanel: (state) => state.quickPanel.open,
            twoFactorAuth: (state) => state.user.twoFactorAuth,
            user: (state) => state.user,
            userName: (state) => state.user.mappedUserName || state.user.userName,
            hasLocalPassword: (state) => state.user.hasLocalPassword,
            windowWidth: (state) => state.windowWidth,
            showCreateMessage: (state) => state.communication.createMessage,
            showCreateAnnouncement: (state) => state.communication.createAnnouncement,
            showCreateSchoolAnnouncement: (state) => state.communication.createSchoolAnnouncement,
            messageNotificationsEnabled: (state) => state.settings.communication.messageNotificationsEnabled,
            showMessageNotification: (state) => state.communication.messageNotification,
        }),
        ...mapGetters({
            showSubHeader: Types.getters.IS_SUBHEADER_ENABLED,
            twoFactorEnabled: Types.getters.IS_TWO_FACTOR_AUTH_ENABLED,
            twoFactorRequired: Types.getters.IS_TWO_FACTOR_AUTH_REQUIRED,
            cipherSessionLocked: Types.getters.IS_CIPHER_SESSION_LOCKED,
        }),
        key: {
            get() {
                return this.$store.state.appKey;
            },
            set() {
                this.$store.commit(Types.mutations.CYCLE_APP_KEY);
            },
        },
        deviceType: {
            get() {
                return this.$store.state.deviceType;
            },
            set(deviceType) {
                this.$store.commit(Types.mutations.SET_RESPONSIVE_DEVICE_TYPE, deviceType);
            },
        },
        require2fa() {
            if (this.$_userMixins_isHomeUser) return false;
            if (this.user.twoFactorAuth.twoFactorAuthEnabled) return false;
            return this.twoFactorRequired;
        },
        hideRootComponent() {
            return this.showLightbox;
        },
        showPasswordAlert() {
            // return true && this.$route.name !== 'Password';
            return this.$_userMixins_isSchoolUser && !this.hasLocalPassword && this.$route.name !== 'Password';
        },
        showLightbox() {
            return Boolean(this.$route.query && this.$route.query.lightbox);
        },
        userTwoFactorAuthMethodId() {
            if (!this.$store.state.user.twoFactorAuth) return null;
            return this.$store.state.user.twoFactorAuth.userTwoFactorAuthMethodId;
        },
        forceStudentView() {
            return (this.$store.state.forceStudentView && this.$route.params.studentEnrollmentId);
        },
        isCourseView() {
            return Boolean(this.$route.params.extCourseSectionId );
        },
        shouldShowFinalizeBanner() {
            const v = this;
            const isSchoolFinalized = v.user.school.schoolId > 0 && v.user.school.finalized;
            const isHome = window.location.href.includes('/home');
            return !isSchoolFinalized && !isHome;
        },
        rostersLastUpdated() {
            return this.$store.state.user.school.rostersLastUpdated;
        },
        settings() {
            return this.$store.state.settings;
        },
        dirty() {
            return this.$store.state.database.assignmentIsDirty;
        },
    },
    watch: {
        error() {
            const { error } = this;
            if (error === 'Session expired' && !window.location.search.includes('sessionExpired')) {
                return this.showSessionExpiredModal();
            }
            if (error) {
                this.showError(error, true);
            }
        },
        $route: {
            handler() {
                this.validateBetaRoute();
                this.validateDisabledState();
            },
            deep: true,
        },
        '$route.query.panel': {
            handler() {
                if (!this.databaseIsReady) return;
                if (this.$route.query && this.$route.query.panel) {
                    this.$store.commit(Types.mutations.OPEN_QUICK_PANEL);
                } else {
                    this.$store.commit(Types.mutations.CLOSE_QUICK_PANEL);
                }
            },
            deep: true,
        },
        setStickyAsideMenu(val) {
            const v = this;
            if (val == 'toggle') {
                v.enableAsideMenu(!v.setStickyAsideMenu);
            } else {
                v.enableAsideMenu(val);
            }
        },
        showMobileMenu(val) {
            if (val) {
                document.body.classList.add('kt-aside--on');
                document.getElementById('kt_aside').classList.add('kt-aside--on');
            } else {
                document.body.classList.remove('kt-aside--on');
                document.getElementById('kt_aside').classList.remove('kt-aside--on');
            }
        },
        showMobileTopBar(val) {
            if (val) {
                document.body.classList.add('kt-header__topbar--mobile-on');
            } else {
                document.body.classList.remove('kt-header__topbar--mobile-on');
            }
        },
        showQuickPanel(val) {
            if (val) {
                document.body.classList.add('kt-quick-panel--on');
                document.getElementById('kt_quick_panel').classList.add('kt-quick-panel--on');
            } else {
                document.body.classList.remove('kt-quick-panel--on');
                document.getElementById('kt_quick_panel').classList.remove('kt-quick-panel--on');
            }
        },
    },
    mounted() {
        window.addEventListener('beforeunload', this.beforeWindowUnload);
        window.addEventListener('resize', this.storeWindowSize);

        this.storeWindowSize();
        this.enableAsideMenu(false);

        this.configureSubHeader();
        this.setWatchers();

        const { deviceType, setMobileMenu, $store } = this;
        const vueInstance = this;
        const { confirmStayInDirtyForm } = this;
        router.beforeEach((to, from, next) => {
            // remeber where the user has been
            $store.commit(Types.mutations.ADD_SCREEN_RECORD_HISTORY, window.location.href);
            if (deviceType == 'mobile' || deviceType == 'tablet') setMobileMenu(false);

            // warn if navigating away from dirty form
            const cancelled = confirmStayInDirtyForm();
            if (cancelled) return next(false);
            next();
        });

        router.afterEach(() => {
            // connect with the socket server
            const webSocket = vueInstance.sgWebSocket;
            if (!webSocket) {
                return openWebSocket(vueInstance);
            }
            postClientViewStateMessage(vueInstance);

        });

        this.startSession();
    },
    beforeUnmount() {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
        window.removeEventListener('resize', this.storeWindowSize);
    },
    methods: {
        ...mapMutations([
            'closeAllNavigationPopovers',
            'setMobileMenu',
            'setMobileTopBar',
            'setPageParameters',
            'getCoursesForActivePortfolio',
        ]),
        confirmStayInDirtyForm() {
            if (this.$route.name === 'TeacherLocalCourseAssignmentEdit') {
                const { assignmentIsDirty } = this.$store.state.database;
                if (!assignmentIsDirty) return false;
                const confirmed = window.confirm('You have unsaved edits to this assignment, are you sure you want to disregard these changes?');
                return !confirmed;
            }
            return false;
        },
        beforeWindowUnload(e) {
            const { schoolId, schoolTermId } = this.user.school;
            network.webSockets.removeSocketSession({ url: { schoolId, schoolTermId }}, () => {});

            if (this.confirmStayInDirtyForm()) {
                // Cancel the event
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
        },
        closeQuickPanel() {
            this.$store.commit(Types.mutations.CLOSE_QUICK_PANEL);
            this.$router.push({
                name: this.$route.name,
                params: this.$route.params,
                query: null,
            });
        },
        setWatchers() {
            // watch for initial session login
            const v = this;

            this.$watch('user.userId', () => {
                const { userName } = v;
                const { schoolTermId } = v.user.school;
                window.sessionStorage.setItem('userName', userName);
                window.sessionStorage.setItem('schoolTermId', schoolTermId);
                window.sessionStorage.setItem(`${userName}-${schoolTermId}_expiry`, moment().add(3, 'days').toISOString());

                v.configureSubHeader();
            });

            // watch for page meta change
            this.$watch('showSubHeader', () => {
                v.configureSubHeader();
            });

            this.$watch('loadState', () => {
                v.incrementKey();
                if (v.loadState == 'READY') {
                    setTimeout(() => {
                        document.body.classList.add('kt-aside--enabled', 'kt-aside-light', 'kt-aside--fixed');
                    }, 250);
                }
            });

            // watch for settings change and save to local storage
            this.$watch('settings', () => {
                const { settings } = v.$store.state;
                if (settings.dirty) {
                    saveSettingsToLocalStorage(v.user, settings);
                }
            }, { deep: true });
        },
        goHome() {
            this.$router.push({ name: 'Home' });
        },
        incrementKey() {
            this.key += 1;
        },
        responsiveDeviceCategory(windowWidth) {
            if (windowWidth < 576) return 'mobile';
            if (windowWidth < 1026) return 'tablet';
            if (windowWidth < 1280) return 'laptop';
            return 'desktop';
        },
        storeWindowSize() {
            const v = this;
            const { $store, responsiveDeviceCategory, configureSubHeader } = this;
            clearTimeout(this.debounceResize);
            this.debounceResize = setTimeout(() => {
                const windowWidth = document.documentElement.clientWidth;
                const windowHeight = document.documentElement.clientHeight;
                $store.commit(Types.mutations.SET_WINDOW_SIZE, { windowWidth, windowHeight });
                v.deviceType = responsiveDeviceCategory(windowWidth);
                configureSubHeader();
            }, 100);
        },
        configureSubHeader() {
            if (this.showSubHeader) {
                document.body.classList.add('kt-subheader--enabled', 'kt-subheader--fixed', 'kt-subheader--solid');
            } else {
                document.body.classList.remove('kt-subheader--enabled', 'kt-subheader--fixed', 'kt-subheader--solid');
            }
        },
        enableAsideMenu(val) {
            if (val) {
                document.body.classList.add('kt-aside--minimize');
            } else {
                document.body.classList.remove('kt-aside--minimize');
                document.body.classList.remove('kt-aside--minimize-hover');
            }
        },
        startSession() {
            const v = this;
            const { commit } = this.$store;

            network.session.refreshSession({}, (err, user) => {
                if (err) return commit(Types.mutations.HANDLE_ERROR, 'Session expired');
                // if (err) window.
                commit(Types.mutations.SET_USER_PROFILE, user);
                v.validateBetaRoute();
                v.validateDisabledState();
                setTimeout(() => {
                    initiateDatabase(user, v);
                }, 10);
            });
        },
        validateBetaRoute() {
            const { user } = this;
            const { school } = user;
            const { isBetaSchool } = school;
            if (!this.isLocalDev && this.$route.meta && this.$route.meta.isLocal) {
                this.goHome();
                return;
            }
            if (!isBetaSchool && this.$route.meta && this.$route.meta.isBeta) this.goHome();
        },
        validateDisabledState() {
            if (this.$_userMixins_isSuperAdmin && this.$route.path.includes('/configuration/super/')) return;
            if (this.$_userMixins_isDisabled) this.goHome();
            return;
        },
        clearMessageNotification() {
            const v = this;
            v.notificationMessages.shift();

            if (v.showMessageNotification) v.$store.commit(Types.mutations.TOGGLE_COMMUNICATION_MESSAGE_NOTIFICATION);
            setTimeout(() => {
                v.$store.commit(Types.mutations.TOGGLE_COMMUNICATION_MESSAGE_NOTIFICATION);
            }, 750);
        },
    },
});

/**
 * @description This routine will restore a db from cache if available, and conditionally initiate a db refresh from the server
 * @description Saving to cache is initiated in frontend/src/store/index.js
 * @param {Object} user
 * @param {Object} vueInstance
 */
function initiateDatabase(user, vueInstance) {
    const { storageClearExpired, $store, showError } = vueInstance;
    const { state, commit, dispatch, getters } = $store;
    const isLocked = getters[Types.getters.IS_CIPHER_SESSION_LOCKED];
    if (isLocked) {
        return storageClearExpired(() => {
            window.syncGrades.log('Aborted local state retrieval, cipher session required', 'info', 'TheRouterLayout');
        });
    }

    async.auto({
        // wipe expired storage
        clearExpired(next) {
            storageClearExpired(next);
        },
        loadUserSettings: ['clearExpired', function (results, next) {
            loadSettingsFromLocalStorage(user, (err2, output) => {
                if (err2) return next(err2);
                const settings = { ...output };
                if (settings) {
                    commit(Types.mutations.APPLY_USER_SETTINGS, settings);
                    window.syncGrades.log('User settings loaded from local storage', 'info', 'TheRouterLayout');
                }
                next();
            });
        }],
        download: ['loadUserSettings', function (results, next) {
            dispatch(Types.actions.START_DB_UPDATER, (err, data) => {
                if (err) return next(err);
                window.syncGrades.log('Background database population has completed', 'info', 'TheRouterLayout');
                next(err, data);
            });
        }],
        decrypt: ['download', function (results, next) {
            dispatch(Types.actions.DECRYPT_SCHOOL_TERM_ENCRYPTION_KEY, {
                callback(err, schoolTermEncryptionKey) {
                    if (err) {
                        window.syncGrades.log(`schoolPassword decryption failed ${err}`, 'error', 'cipher');
                    }
                    commit(Types.mutations.SET_SCHOOL_TERM_ENCRYPTION_KEY, schoolTermEncryptionKey || null);
                    next();
                },
            });
        }],
    }, 3, (err) => {
        if (err) return showError(err);
        commit(Types.mutations.SET_DB_LOAD_STATE, 'READY');
        commit(Types.mutations.CYCLE_APP_KEY);
        window.syncGrades.log('Session setup is completed', 'info', 'TheRouterLayout');
        openWebSocket(vueInstance);
    });
}

function saveSettingsToLocalStorage(user, settings) {
    const key = `userSettings_${user.mappedUserId || user.userId}`;

    localForage.setItem(key, settings).then(() => {
        window.syncGrades.log('Settings saved to local storage', 'info', 'TheRouterLayout');
    });
}

function loadSettingsFromLocalStorage(user, callback) {
    const key = `userSettings_${user.mappedUserId || user.userId}`;
    localForage.getItem(key, (err, settings) => {
        if (err) {
            localForage.removeItem(key);
        }
        callback(err, settings);
    });
}

// https://github.com/websockets/ws/blob/master/examples/express-session-parse/public/app.js
function openWebSocket(vueInstance) {
    if (vueInstance.sgWebSocket) return;
    // const { showError } = vueInstance;

    const port = window.location.href.indexOf('localhost') > -1 ? '3000' : `${window.location.port}`;
    const serverUri = `ws://${window.location.hostname}:${port}/api/websocket`;
    Vue.prototype.sgWebSocket = new WebSocket(serverUri);
    const webSocket = vueInstance.sgWebSocket;

    window.syncGrades.log(serverUri, 'info', 'WebSocket');

    webSocket.onerror = function (err) {
        window.syncGrades.log(`Websocket error ${err}`, 'error', 'WebSocket');
        vueInstance.$store.commit(Types.mutations.SET_SOCKET_ESTABLISHED, false);
        Vue.prototype.sgWebSocket = null;
    };
    webSocket.onmessage = function (event) {
        const socketMessage = JSON.parse(event.data);
        const { messageType, payload } = socketMessage;
        const v = vueInstance;
        const {
            $store, prettyAlert, $bvModal, showError, messageNotificationsEnabled, showMessageNotification,
        } = vueInstance;
        // const { showError, $route } = vueInstance;

        window.syncGrades.log(event.data, 'info', 'WebSocket');

        if (messageType == 'SchoolTermNotification') {
            // const { schoolTermId } = payload;
            // if (payload.body) {
            //     return prettyAlert(
            //         $bvModal, messageType,
            //         `schoolTermId ${schoolTermId} refresh`, () => {},
            //     );
            // }

            if (payload.action == 'SocketEstablished') {
                vueInstance.$store.commit(Types.mutations.SET_SOCKET_ESTABLISHED, true);
                const serverVersion = payload.version;
                const userVersion = vueInstance.$store.state.user.version;
                if (serverVersion != userVersion) {

                    return $bvModal
                        .msgBoxOk('A new version of SyncGrades has been released. Reload the page to continue', {
                            title: 'Software Update Available',
                            okVariant: 'primary',
                            headerClass: 'p-2 border-bottom-0',
                            footerClass: 'p-2 border-top-0',
                            centered: true,
                            noCloseOnEsc: true,
                            noCloseOnBackdrop: true,
                            okTitle: 'Reload Page',
                        })
                        .then(value => {
                            window.location.reload();
                        })
                        .catch(err => {
                            // An error occurred
                        });
                }
            }

            if (payload.action == 'AnecdotalUpdate') {
                // this is a hack to force a view update
                $store.commit(Types.mutations.BUMP_RELOAD_KEY);
                $store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, { tableName: 'studentBadges', callback: function() {} });
                $store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, { tableName: 'badges', callback: function() {} });
                console.log('AnecdotalUpdate: Student Badges have been recalculated');
            }

            if (payload.action == 'StudentGroupUpdate') {
                // this is a hack to force a view update
                $store.commit(Types.mutations.BUMP_RELOAD_KEY);
                $store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
                    tableName: 'studentGroups',
                    callback: (err) => {
                        if (err) return showError(err);
                        console.log('StudentGroupUpdate: Student Groups have been repopulated');
                    },
                });
            }

            if (payload.action == 'CommunicationUsageUpdate') {
                // this is a hack to force a view update
                if (payload.quotaExceeded) showError('A communication quota has been exceeded. Some recipients may not have been notified by SMS or Email');
                $store.commit(Types.mutations.BUMP_RELOAD_KEY);
                $store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
                    tableName: 'schools',
                    callback: (err) => {
                        if (err) return showError(err);
                        console.log('CommunicationUsageUpdate: Schools have been repopulated');
                    },
                });
            }
        }
        if (messageType == 'UserMessageNotifications') {
            if (payload.messageId) {
                $store.commit(Types.mutations.BUMP_RELOAD_KEY);
                $store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, {
                    tableName: 'messages',
                    callback: (err) => {
                        if (err) return showError(err);
                        window.console.log('messagesUpdate: Messages have been repopulated');
                    },
                });
                if (!messageNotificationsEnabled) return;

                v.notificationMessages.push({
                    messageId: payload.messageId,
                    messageBody: payload.messageBody,
                    messageAuthor: payload.author,
                    allowReplies: payload.allowReplies,
                });

                if (!showMessageNotification) $store.commit(Types.mutations.TOGGLE_COMMUNICATION_MESSAGE_NOTIFICATION);
                return;
            }
            return prettyAlert(
                $bvModal, messageType,
                payload.body, () => {},
            );
        }

        if (messageType == 'CipherSessionExpired') {
            $store.commit(Types.mutations.SET_CIPHER_SESSION_LOCKED);
        }
        // if (messageType == 'AiResponse') {
        //     $store.commit(Types.mutations.SET_AI_RESPONSE, payload);
        // }

        window.syncGrades.log(socketMessage, 'info', 'WebSocket');
    };
    webSocket.onopen = function () {
        postClientViewStateMessage(vueInstance);


        // if (vueInstance.webSocketTimeOut) {
        //     clearTimeout(vueInstance.webSocketTimeOut);
        //     vueInstance.webSocketTimeOut = null;
        // }
        // vueInstance.webSocketTimeOut = setTimeout(() => {
        //     openWebSocket(vueInstance);
        // }, 60000);
    };
    webSocket.onclose = function () {
        window.syncGrades.log('WebSocket connection closed', 'info', 'WebSocket');
        vueInstance.$store.commit(Types.mutations.SET_SOCKET_ESTABLISHED, false);
        Vue.prototype.sgWebSocket = null;

        openWebSocket(vueInstance);
    };
}

function postClientViewStateMessage(vueInstance) {

    const { $store, $route, sgWebSocket } = vueInstance;
    const { schoolId, schoolTermId } = $store.state.user.school;
    if (!sgWebSocket) return;

    if (sgWebSocket.readyState !== 1) {
        return setTimeout(() => {
            postClientViewStateMessage(vueInstance);
        }, 1000);
    }

    let schoolStaffIdChannel = null;
    if ($route.params.schoolEmail) {
        const teacher = getTeacherFromRoute($route, $store);
        if (teacher && teacher.schoolStaffId) {
            schoolStaffIdChannel = teacher.schoolStaffId;
        }
    }

    let studentEnrollmentIdChannel = null;
    if ($route.params.studentEnrollmentId) {
        const student = getStudentFromRoute($route, $store);
        if (student && student.studentEnrollmentId) {
            studentEnrollmentIdChannel = student.studentEnrollmentId;
        }
    }

    const payload = {
        schoolId,
        schoolTermId,
        schoolStaffIdChannel,
        studentEnrollmentIdChannel,
    };

    const message = new models.SocketMessage({
        messageType: 'ClientViewState',
        payload,
    });

    try {
        sgWebSocket.send(JSON.stringify(message));
    } catch (err) {
        window.syncGrades.log(err, 'error', 'WebSocket');
    }
}

</script>

<style lang="css" src="socicon/css/socicon.css"></style>
<style lang="css" src="@fortawesome/fontawesome-free/css/all.min.css"></style>
<style lang="css" src="../../assets/plugins/line-awesome/css/line-awesome.css"></style>
<style lang="css" src="../../assets/plugins/flaticon/flaticon.css"></style>
<style lang="css" src="../../assets/plugins/flaticon2/flaticon.css"></style>

<style lang="scss" src="../../assets/sass/style.vue.scss"></style>
<style lang="scss" src="../../assets/sass/global/integration/frameworks/vue/_skins.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/header/skins/menu/dark.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/aside/skins/dark.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/brand/skins/dark.scss"></style>
<style lang="scss" src="../../assets/sass/global/layout/header/skins/base/dark.scss"></style>

<style lang="scss" src="../../css/colors.scss"></style>

<style lang="scss">

html, body {
    scroll-behavior: smooth;
};

body.kt-header-menu-wrapper--on .kt-header-logo {
    display: none;
}
div.b-toaster-top-right {
    top: 80px !important;
}

.sg-alert {
    border-radius: 0;
}

div.mobile-device>div:nth-of-type(2),
div.tablet-device>div:nth-of-type(2) {
    margin-top: 70px;
}

div.mobile-device>div.kt-subheader,
div.tablet-device>div.kt-subheader {
    position: fixed;
    top: auto;
    z-index: 1;
    background-color: #fff;
    width: 100%;
}

div.paginator-container div.pagination__desc {
    display: inline-block;
    margin-right: 10px;
    max-width: 90px;
    max-height: 50px;
    line-height: 1.2rem;
}

.v-md-editor-preview, div.markdown-container {
    all: initial;
    padding: 10px;

    div.github-markdown-body, div.markdown {
        color: kt-base-color(label, 3);
        font-family: "Monaco", "Lexend Deca","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 1rem;
        font-weight: 400;

        h1 {
            font-size: 1.3rem;
            font-weight: 700;
        }
        h2 {
            font-size: 1.1rem;
            font-weight: 600;
            padding: 6px 0;
        }
        h3 {
            font-weight: 600;
            font-size: 1rem;
        }
        h4 {
            font-weight: 500;
            font-size: 1rem;
        }
        h5 {
            font-weight: 500;
            font-size: 1rem;
        }
    }
}

.kt-container-fullscreen {
    width: 100% !important;
}

.kt-fullscreen-portlet .kt-portlet__body {
    min-height: calc(100vh - 280px);
}

body.kt-subheader--enabled {
    .mobile-device, .tablet-device {
        .kt-container-fullscreen {
            margin-top: 20px !important;
        }
        .kt-fullscreen-portlet .kt-portlet__body {
            min-height: calc(100vh - 220px);
        }
    }
    .laptop-device, .desktop-device {
        .kt-container-fullscreen {
            margin-top: -64px !important;
        }
    }
}

body:not(.kt-subheader--enabled) {
    .mobile-device, .tablet-device {
        .kt-container-fullscreen {
            margin-top: 20px !important;
        }
        .kt-fullscreen-portlet .kt-portlet__body {
            min-height: calc(100vh - 220px);
        }
    }
    .laptop-device, .desktop-device {
        .kt-container-fullscreen {
            margin-top: 0px !important;
        }
    }
}

</style>

<style scoped>
div.sg-loader {
    height: 3px;
    width: 100%
}

</style>

<style>

/* https://github.com/handsontable/handsontable/issues/2494#issuecomment-1362392667 */
.ht_clone_top, .ht_clone_left, .ht_clone_top_inline_start_corner { z-index: 1 !important; }

.v-md-editor {
    border: 1px solid #e2e5ec;
    box-shadow: none;
}

</style>
