var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kt-header__topbar kt-grid__item" },
    [
      _vm.onBetaRoute
        ? _c("div", { staticClass: "kt-header__topbar-item mr-4" }, [
            _c("div", { staticClass: "kt-header__topbar-wrapper" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      value: `This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`,
                      expression:
                        "`This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`",
                      modifiers: { hover: true },
                    },
                  ],
                  staticClass:
                    "kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded py-2",
                  staticStyle: { "max-height": "30px", "align-self": "center" },
                },
                [
                  _c("i", { staticClass: "fas fa-fire mr-2" }),
                  _c("span", { staticClass: "kt-font-boldest" }, [
                    _vm._v("BETA"),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm.$_userMixins_isSuperAdmin ? _c("TheSuperMenuButton") : _vm._e(),
      !_vm.$_userMixins_isHomeUser || _vm.$_userMixins_isSuperAdmin
        ? _c("TheHelpRedirectButton")
        : _vm._e(),
      _c("TheBugRecordingButton"),
      !_vm.$_userMixins_isDisabled && _vm.canSearch
        ? _c("TheSearchBar")
        : _vm._e(),
      !_vm.$_userMixins_isDisabled && _vm.$_userMixins_isSchoolUser
        ? _c("AnecdotalButton")
        : _vm._e(),
      !_vm.$_userMixins_isDisabled ? _c("MessagingButton") : _vm._e(),
      !_vm.$_userMixins_isDisabled && !_vm.$_userMixins_isHomeUser
        ? _c("TheSettingsButton")
        : _vm._e(),
      _vm.twoFactorEnabled &&
      _vm.$_userMixins_isSchoolUser &&
      _vm.hasLocalPassword
        ? _c("TheCipherButton")
        : _vm._e(),
      false ? _c("TheLocaleSwitcher") : _vm._e(),
      _c("TheUserBar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }