<template>
<div
    v-if="user"
    :key="key"
    class="kt-container kt-grid__item kt-grid__item--fluid row pt-4 mt-5"
>
    <OneTimePassword v-if="userTwoFactorAuthMethodId" />
    <!-- choose a method to verify your account -->
    <div
        v-else
        :class="{
            'col-8 offset-2': deviceType == 'desktop' || deviceType == 'laptop',
            'col-8 offset-2 pt-5': deviceType == 'tablet',
            'col-12 pt-5 pl-2 pr-2': deviceType == 'mobile',
        }"
    >
        <!-- 2fa portlet -->
        <div
            v-if="twoFactorRequired"
            class="row"
        >
            <div class="col">
                <div
                    class="alert alert-light alert-elevate fade show"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="la la-info-circle kt-font-danger" />
                    </div>
                    <div class="alert-text">
                        <p>
                            Two factor authentication is required for your
                            account. Please setup two factor authentication
                            before continuing.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon">
                        <SVGIcon name="encrypted" />
                    </span>
                    <h3 class="kt-portlet__head-title">
                        Setup Two Factor Authentication
                    </h3>
                </div>
                <div class="kt-portlet__head-toolbar" />
            </div>

            <div
                v-if="twoFactorMixinsAuthMethods.length > 0"
                class="kt-portlet__body"
            >
                <SetupTwoFactorAuth
                    :increment-key="incrementKey"
                    :full-screen="true"
                />
            </div>
            <div class="kt-portlet__foot">
                <div
                    v-if="twoFactorMixinsAuthMethods.length > 0"
                    class="row w-100"
                >
                    <div class="col-6">
                        <button
                            v-if="twoFactorEnabled"
                            class="btn btn-secondary"
                        >
                            <i class="fa fa-power-off" />
                            Finish Setup
                        </button>
                    </div>
                    <div class="col-6" />
                </div>
                <div v-else>
                    <div class="text-center">
                        <button
                            class="btn btn-primary btn-upper btn-taller btn-wider"
                            @click="showAddNewMethodModal"
                        >
                            Begin Setup Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <TwoFactorAuthMethodModal ref="two-factor-auth-method-modal" />
</div>
</template>

<script lang="ts">
import { mapState, mapGetters } from 'vuex';
import Vue from 'vue';
import Types from '../store/Types';
import twoFactorMixins from '../store/mixins/twoFactorMixins';
import TwoFactorAuthMethodModal from '../components/TwoFactorAuthMethodModal.vue';
import OneTimePassword from '../components/OneTimePassword/OneTimePassword.vue';
import SetupTwoFactorAuth from '../components/SetupTwoFactorAuth.vue';

// import * as network from '../network';

export default Vue.extend({
    name: 'RequireTwoFactorAuth',
    components: {
        OneTimePassword,
        SetupTwoFactorAuth,
        TwoFactorAuthMethodModal,
    },
    mixins: [twoFactorMixins],
    data() {
        return {
            key: 0,
            saving: false,
        };
    },
    computed: {
        userTwoFactorAuthMethodId: {
            get() {
                return this.$store.state.user.twoFactorAuth.userTwoFactorAuthMethodId;
            },
            set(val) {
                this.$store.commit(Types.mutations.SET_USER_TWO_FACTOR_AUTH_METHOD_ID, val);
            },
        },
        ...mapState({
            user: (state) => state.user,
            deviceType: (state) => state.deviceType,
        }),
        ...mapGetters({
            twoFactorEnabled: Types.getters.IS_TWO_FACTOR_AUTH_ENABLED,
            twoFactorRequired: Types.getters.IS_TWO_FACTOR_AUTH_REQUIRED,
        }),
    },
    mounted() {
        const v = this;
        v.verify = false;
        v.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if (modalId == 'auth-method-modal') {
                v.key += 1;
            }
        });
    },
    methods: {
        incrementKey() {
            this.key += 1;
        },
        showAddNewMethodModal() {
            this.$refs['two-factor-auth-method-modal'].open();
        },
        startChallengeForMethod(authMethod) {
            this.userTwoFactorAuthMethodId = authMethod.userTwoFactorAuthMethodId;
        },
    },
});
</script>

<style scoped>
div.invalid-feedback {
    padding-top: 4px;
}
</style>
