<template>
<div
    ref="draggableContainer"
    class="modal-sticky-md modal show d-block"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
>
    <div class="kt-portlet mb-0">
        <div class="kt-portlet__head" @mousedown="dragMouseDown">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    <a
                        v-if="!encryptionEnabled"
                        href="#"
                        @click.stop.prevent="$_panelMixins_openPanelForStudent(student)"
                    >
                        <UserAvatar
                            :rounded="true"
                            :avatar-user="student"
                            class="kt-media mr-2"
                            style="width: 1.75rem;"
                        >
                            <div class="kt-media kt-media--sm">
                                <a
                                    style="width: 1.75rem;"
                                    class="kt-media kt-media--lg kt-media--success"
                                >
                                    <span style="height: 1.75rem; font-size: .75rem;">
                                        {{ initials }}
                                    </span>
                                </a>
                            </div>
                        </UserAvatar>
                    </a>
                    <a
                        v-else
                        href="#"
                        @click.stop.prevent="$_panelMixins_openPanelForStudent(student)"
                    >
                        <div class="kt-media kt-media--sm">
                            <a
                                style="width: 1.75rem;"
                                class="kt-media kt-media--lg kt-media--success"
                            >
                                <span style="height: 1.75rem; font-size: .75rem;">
                                    {{ initials }}
                                </span>
                            </a>
                        </div>
                    </a>
                    Add Student Badges
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-group">
                    <a
                        href="#"
                        class="btn btn-sm btn-icon btn-clean btn-icon-md"
                        @click.stop.prevent="toggleSize"
                    >
                        <i :class="['la', minimized ? 'la-angle-up' : 'la-angle-down' ]" />
                    </a>

                    <a
                        href="#"
                        class="btn btn-sm btn-icon btn-clean btn-icon-md"
                        @click.stop.prevent="closeModal"
                    >
                        <i class="la la-close" />
                    </a>
                </div>
            </div>
        </div>
        <div :class="[minimized ? 'd-none' : '']">
            <div class="kt-portlet__body pt-3">
                <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold" role="tablist">
                    <li
                        v-for="tab in tabs"
                        :key="tab.name"
                        class="nav-item"
                    >
                        <router-link
                            v-if="tab.name == 'Create New'"
                            :to="{
                                name: 'EditBadge',
                                query: {
                                    badgeType,
                                },
                                params: {
                                    badgeId: 'new'
                                }
                            }"
                            class="nav-link"
                        >
                            <i :class="tab.icon" />
                            {{ tab.name }}
                        </router-link>
                        <a
                            v-else
                            :class="tab.selected ? 'nav-link active' : 'nav-link'"
                            href="#"
                            role="tab"
                            @click.stop.prevent="selectTab(tab)"
                        >
                            <i :class="tab.icon" />
                            {{ tab.name }}s
                        </a>
                    </li>
                </ul>
                <div class="form-group mb-0">
                    <div class="badge-container">
                        <div v-if="selectedTab.name == 'Achievement Level'" class="kt-radio-list">
                            <label
                                v-for="badge in filteredBadges"
                                :key="`badge${badge.badgeId}`"
                                :class="['kt-radio font', badge.badgeColor]"
                            >
                                <input
                                    type="radio"
                                    :checked="badge.selected"
                                    @change="selectAchievementBadge(badge)"
                                >
                                {{ badge.badgeIcon }} {{ badge.achievementLevel }}. {{ badge.badgeTitle }}
                                <span />
                            </label>
                        </div>
                        <div v-else class="kt-checkbox-inline">
                            <label
                                v-for="badge in filteredBadges"
                                :key="`badge${badge.badgeId}`"
                                :class="['kt-checkbox font', badge.badgeColor]"
                            >
                                <input
                                    v-model="badge.selected"
                                    type="checkbox"
                                >
                                {{ badge.badgeIcon }} {{ badge.badgeTitle }}
                                <span />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kt-portlet__foot">
                <div class="kt-form__actions">
                    <div class="form-group">
                        <!-- <label>Manually Award Badge:</label> -->
                        <div class="input-group">
                            <input
                                v-model="rationale"
                                type="text"
                                class="form-control"
                                placeholder="Rationale (optional)"
                                :disabled="saving || badgesToSave.length == 0"
                            >
                            <div class="input-group-append">
                                <button
                                    class="btn btn-primary"
                                    type="button"
                                    :disabled="saving || badgesToSave.length == 0"
                                    :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                                    @click.stop.prevent="saveBadges"
                                >
                                    <template v-if="selectedTab.name == 'Achievement Level'">
                                        Change Achievement Level
                                    </template>
                                    <template v-else-if="selectedTab.name == 'Academic Award'">
                                        Award Academic Badge
                                    </template>
                                    <template v-else>
                                        Set Administrative Badge
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import async from 'async';
import moment from 'moment';
import Types from '../store/Types';
import * as network from '../network';
import UserAvatar from '../components/UserAvatar.vue';
import { getCoursesForStudentEnrollmentId } from '../store/mixins/courseMixins';
import studentMixins from '../store/mixins/studentMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import panelMixins from '../store/mixins/panelMixins';

export default Vue.extend({
    name: 'StudentBadgeForm',
    components: {
        UserAvatar,
    },
    mixins: [
        studentMixins,
        panelMixins,
        portfolioMixins
    ],
    data() {
        return {
            tabs: [
                {name: 'Administrative Badge', icon: 'flaticon2-layers-1', selected: true},
                {name: 'Academic Award', icon: 'flaticon2-writing', selected: false},
                {name: 'Achievement Level', icon: 'flaticon2-line-chart', selected: false},
            ],
            createNewBadge: true,
            mutableBadges: [],
            rationale: '',
            loading: false,
            saving: false,
            minimized: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0,
            },
        };
    },
    computed: {
        filteredBadges() {
            const { selectedTab, mutableBadges } = this;
            const badges = mutableBadges
                .filter((badge) => badge.badgeType == selectedTab.name);
            if (selectedTab.name !== 'Achievement Level') {
                badges.sort((a, b) => a.badgeTitle > b.badgeTitle ? 1 : -1); // lowest to highest
            }
            return badges;
        },
        selectedTab() {
            return this.tabs.find((tab) => tab.selected);
        },
        initials() {
            const { student, encryptionEnabled } = this;
            if (encryptionEnabled) {
                return student.maskedStudentName.substring(0, 2);
            }
            return `${student.lastName.substring(0, 2)}`;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        clientSideEncryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled;
        },
        badgeType() {
            const { badgeType } = this.$store.state.addStudentBadgePanel;
            return badgeType;
        },
        studentEnrollmentId() {
            const [ student ] = this.$store.state.addStudentBadgePanel.students;
            if (!student) return null;
            return student.studentEnrollmentId;
        },
        badges() {
            return this.$store.state.database.badges;
        },
        formattedBadges() {
            const { badges, studentBadges } = this;
            return badges.map((b) => {
                const badge = { ...b };
                badge.isAwarded = false;
                if (!studentBadges) return badge;

                const { earnedBadges } = studentBadges;
                badge.isAwarded = earnedBadges.some((eb) => eb.badgeId == badge.badgeId);
                return badge;
            });
        },
        badgesToSave() {
            const { filteredBadges } = this;
            return filteredBadges.filter((b) => b.selected);
        },
        student() {
            return this.$_studentMixins_getStudentFromEnrollmentId(this.studentEnrollmentId);
        },
        studentBadges() {
            return this.$store.state.database.studentBadges.find((b) => b.studentId == this.student.studentId);
        },
        studentCourses() {
            const { studentEnrollmentId, $store } = this;
            if (!studentEnrollmentId) return [];
            return getCoursesForStudentEnrollmentId($store, studentEnrollmentId);
        },
        courseSectionId() {
            const { studentCourses } = this;
            const { schoolStaffId } = this.$store.state.user.school;
            const course = studentCourses.find(function(course) {
                const teacher = course.teachers.find(function(teacher) {
                    return teacher.schoolStaffId == schoolStaffId;
                });
                return Boolean(teacher);
            });
            return course ? course.courseSectionId : null;
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        selectTab(t) {
            this.tabs = this.tabs.map((tab) => {
                const newTab = { ...tab };
                newTab.selected = Boolean(tab.name == t.name);
                return newTab;
            });
        },
        populate() {
            const { badgeType } = this;
            const userId = this.$store.state.user.mappedUserId || this.$store.state.user.userId;
            const badges = [...this.$store.state.database.badges]
                .map((badge) => {
                    const newBadge = { ...badge };
                    newBadge.selected = false;
                    return newBadge;
                })
                .filter(function(badge) {
                    const { badgeManagers } = badge;
                    if (badge.deleted) return false;
                    if (badgeManagers.length === 0) return true;
                    const isManager = badgeManagers.some(function(bm) {
                        return bm.userId == userId;
                    });
                    return isManager;
                });

            //select initial tab
            if (badgeType) {
                this.tabs = this.tabs
                    .filter((tab) => tab.name == badgeType)
                    .map((tab) => {
                        const newTab = { ...tab };
                        newTab.selected = Boolean(tab.name == badgeType);
                        return newTab;
                    });
            } else {
                this.tabs = this.tabs
                    .filter((tab) => tab.name !== 'Achievement Level')
            }

            // add new option
            if (badgeType == 'Academic Award') {
                this.tabs.push({name: 'Create New', icon: 'flaticon2-add', selected: false});
            }
            this.mutableBadges = [...badges];

        },
        dragMouseDown(event) {
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            // set the element's new position:
            this.$refs.draggableContainer.style.top = `${this.$refs.draggableContainer.offsetTop - this.positions.movementY}px`;
            this.$refs.draggableContainer.style.left = `${this.$refs.draggableContainer.offsetLeft - this.positions.movementX}px`;
        },
        closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        },
        toggleSize() {
            this.minimized = !this.minimized;

            if (this.minimized) {
                this.positions.movementX = undefined;
                this.positions.movementY = undefined;
                this.positions.clientX = 0;
                this.positions.clientY = 0;
                this.$refs.draggableContainer.style.top = 'auto';
                this.$refs.draggableContainer.style.left = 'auto';
            }
        },
        closeModal() {
            this.$store.commit(Types.mutations.SET_STUDENT_BADGE_PANEL_CLOSE);
        },
        selectAchievementBadge(badge) {
            const badges = this.mutableBadges.map((b) => {
                const newBadge = { ...b };
                newBadge.selected = Boolean(badge.badgeId == b.badgeId && badge.badgeType == b.badgeType);
                return newBadge;
            });
            this.mutableBadges = [...badges];
        },
        saveBadges() {
            const v = this;
            const {
                showError, rationale, badgesToSave,
                courseSectionId, $store,
            } = v;
            if (badgesToSave.length === 0 || !v.student) return;

            if (v.saving) return;
            v.saving = true;
            const { studentEnrollmentId, studentId } = this.student;

            async.eachOfLimit(badgesToSave, 1, (newBadge, index, next) => {
                const {badgeId, badgeType} = newBadge;
                const params = {
                    url: {
                        schoolId: $store.state.user.school.schoolId,
                        schoolTermId: $store.state.user.school.schoolTermId,
                        studentId: v.student.studentId,
                    },
                    body: {
                        studentBadge: {
                            studentEnrollmentId,
                            studentId,
                            badgeId,
                            isAchievementBadge: badgeType == 'Achievement Level',
                            awardedOn: moment().format('YYYY-MM-DD'),
                            rationale,
                        },
                        courseSectionId,
                    },
                };
                network.badges.addStudentBadge(params, next);
            }, (err) => {
                v.saving = false;
                if (err) return showError(err);
                $store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, { tableName: 'studentBadges', callback: function() {
                    $store.commit(Types.mutations.BUMP_RELOAD_KEY);
                }});

                v.closeModal();

            });
        },

    },
});
</script>

<style lang="scss" scoped>

div.modal-sticky-md {
    width: 540px;
}

.table tr td {
    vertical-align: middle;
}

.student-score {
    font-size: 1.4rem;
    font-weight: 600;
}

.badge-container {
    overflow-y: auto;
    max-height: 128px;
}

.kt-checkbox.font {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-sticky-md {
    padding: 0 !important;
    // position: absolute !important;
    height: fit-content !important;
    resize: horizontal;
    left: auto;
    top: auto;
    margin: 0;
    right: 25px;
    bottom: 25px;
    width: 500px;
    max-width: 75vw;
    max-height: 75vh;
    min-width: 410px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px  !important;
}
</style>
