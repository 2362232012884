<template>
<div
    title="'Open Documentation'"
    class="kt-header__topbar-item kt-header__topbar-item--search dropdown"
>
    <div
        class="kt-header__topbar-wrapper"
        @click.stop.prevent="open()"
    >
        <span
            :class="{
                'kt-header__topbar-icon': true,
                'undefined': showDangerColor,
            }"
        >
            <SVGIcon name="help" />
        </span>
    </div>

    <HelpRedirectModal
        v-if="isSuperAdmin"
        ref="helpRedirectModal"
    />
</div>
</template>

<script>
import SVGIcon from '../../../../components/SVGIcon/SVGIcon.vue';
import HelpRedirectModal from '../../../../components/HelpRedirectModal.vue';

export default {
    name: 'TheHelpRedirectButton',
    components: {
        SVGIcon,
        HelpRedirectModal,
    },
    computed: {
        rootWikiPath() {
            return 'https://wiki.syncgrades.com/en/home';
        },
        hasWikiUrl() {
            return this.wikiUrl !== this.rootWikiPath;
        },
        wikiUrl() {
            const { helpRedirects } = this.$store.state.database;
            const routeName = this.$route.name;
            if ( helpRedirects && helpRedirects.hasOwnProperty(routeName) ) {
                return helpRedirects[routeName];
            }
            return this.rootWikiPath;
        },
        isSuperAdmin() {
            return this.$store.state.user.isSuperAdmin;
        },
        showDangerColor() {
            if (!this.isSuperAdmin) return false;
            return !this.hasWikiUrl;
        },
    },
    methods: {
        open() {
            if (this.isSuperAdmin) {
                this.$refs.helpRedirectModal.open();
            } else {
                window.open(this.wikiUrl, '_blank');
            }
        },
    },
};
</script>

<style>

.kt-header__topbar .kt-header__topbar-item .kt-header__topbar-icon.undefined svg g path[fill] {
    fill: #fd397a !important;
}

</style>
