var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "topSchoolMenuNav" } },
    [
      _c("TheAsideMenuHeader"),
      !_vm.finalized
        ? _c(
            "ul",
            { staticClass: "kt-menu__nav pt-0" },
            [_c("TheSetupWizardMenu")],
            1
          )
        : _vm._e(),
      _vm.finalized || _vm.hideWizard
        ? [
            !_vm.$_userMixins_isDisabled && _vm.$_userMixins_isTeacher
              ? _c(
                  "ul",
                  { staticClass: "kt-menu__nav pt-0" },
                  [
                    _c("AsideMenuItemHeader", {
                      attrs: { title: "My Classroom" },
                    }),
                    _c("TeacherPortfolioMenu", {
                      attrs: { teacher: _vm.teacher },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.portfolios, function (portfolio, idx) {
              return _c(
                "ul",
                {
                  key: `portfolioViewers_${idx}_${_vm.key}`,
                  staticClass: "kt-menu__nav py-0",
                },
                [
                  idx == 0
                    ? _c("AsideMenuItemHeader", {
                        attrs: { title: "Portfolios" },
                      })
                    : _vm._e(),
                  portfolio["schoolStaffId"]
                    ? _c("TeacherPortfolioMenu", {
                        ref: `teacher_port_${portfolio.schoolStaffId}`,
                        refInFor: true,
                        attrs: { teacher: portfolio },
                      })
                    : _vm._e(),
                  portfolio["studentEnrollmentId"]
                    ? _c("StudentPortfolioMenu", {
                        attrs: { student: portfolio },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            !_vm.$_userMixins_isDisabled
              ? _c(
                  "ul",
                  { staticClass: "kt-menu__nav pt-0" },
                  [
                    _c("AsideMenuItemHeader", { attrs: { title: "School" } }),
                    _c("AsideMenuItem", {
                      attrs: {
                        title: "Teachers",
                        "route-name": "Teachers",
                        icon: "teachers",
                      },
                    }),
                    _c("AsideMenuItem", {
                      attrs: {
                        title: "Courses",
                        "route-name": "CourseList",
                        icon: "courses",
                      },
                    }),
                    _c("AsideMenuItem", {
                      attrs: {
                        title: "Students",
                        "route-name": "Students",
                        icon: "students",
                      },
                    }),
                    _c("AsideMenuItem", {
                      attrs: {
                        title: "Student Groups",
                        "route-name": "StudentGroupsList",
                        icon: "group",
                      },
                    }),
                    _c("AsideMenuItem", {
                      attrs: {
                        title: "Communication",
                        "route-name": "Communication",
                        icon: "communication",
                      },
                    }),
                    _c("AsideMenuItem", {
                      attrs: {
                        title: "Anecdotal Logs",
                        "route-name": "AnecdotalLogs",
                        icon: "anecdotal",
                      },
                    }),
                    _c("AsideMenuItem", {
                      attrs: {
                        title: "Reports",
                        "route-name": "Reports",
                        icon: "progressReports",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.$_userMixins_isDisabled && _vm.$_userMixins_isDemoSchool
              ? _c("TheDataAnalyticsMenu")
              : _vm._e(),
            !_vm.$_userMixins_isDisabled ? _c("TheSettingsMenu") : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }