<template>
<div
    v-show="showCreateCommunicationGroup"
    class="modal modal-sticky-bottom-left modal-sticky-lg show"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block"
>
    <div class="modal-dialog">
        <div class="modal-content kt-inbox">
            <CreateMessagingForm v-if="showCreateCommunicationGroup" :key="`communicationForm_${key}`" />
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Types from '../../store/Types';
import CreateChatGroupForm from './CreateChatGroupForm.vue';

export default {
    name: 'CreateChatGroup',
    components: {
        CreateChatGroupForm,
    },
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        ...mapState({
            showCreateCommunicationGroup: (state) => state.communication.createCommunicationGroup,
        }),
    },
    methods: {
        closeModal() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_COMMUNICATION_GROUP);
        },
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/pages/inbox/inbox.scss";
</style>
