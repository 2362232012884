var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "google-cat-change-modal",
    attrs: { size: "xl", "no-close-on-backdrop": "", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title kt-font-danger" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.extCourseSectionId) +
                  " - Google Category Assignments "
              ),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return _vm.closeModal()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c(
                  "div",
                  { staticClass: "form-group form-group-last pt-3 pb-5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-secondary",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("div", { staticClass: "alert-icon" }, [
                          _c("i", {
                            staticClass: "flaticon-danger kt-font-brand",
                          }),
                        ]),
                        _c("div", { staticClass: "alert-text" }, [
                          _vm._v(" The Grade Template Categories for "),
                          _c("code", [_vm._v(_vm._s(_vm.extCourseSectionId))]),
                          _vm._v(
                            " may have been modified by an administrator. To ensure accuracy, please map each Google Category to Template Category and save. "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._l(_vm.changes, function (item) {
                  return _c(
                    "div",
                    {
                      key: `category_${item.googleCourseGradeCategoryId}`,
                      staticClass: "form-group row px-5",
                    },
                    [
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("label", [_vm._v("Google Category")]),
                        _c("input", {
                          staticClass: "form-control col-md-10",
                          attrs: { type: "text", disabled: "" },
                          domProps: { value: `${item.gradeCategoryName}` },
                        }),
                      ]),
                      _c("div", { staticClass: "col-md-6" }, [
                        _c("label", [_vm._v("Grade Template Category")]),
                        _c("br"),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.newGradeTemplateCategoryId,
                                expression: "item.newGradeTemplateCategoryId",
                              },
                            ],
                            staticClass: "form-control col-md-10",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  item,
                                  "newGradeTemplateCategoryId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.gradeTemplateCategories, function (c) {
                            return _c(
                              "option",
                              {
                                key: `option_c_${c.gradeTemplateCategoryId}`,
                                domProps: { value: c.gradeTemplateCategoryId },
                              },
                              [_vm._v(" " + _vm._s(c.categoryName) + " ")]
                            )
                          }),
                          0
                        ),
                        !item.newGradeTemplateCategoryId
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "form-text text-muted kt-font-danger",
                              },
                              [_vm._v("Select a new Grading Category")]
                            )
                          : _vm._e(),
                      ]),
                      _c("div", {
                        staticClass:
                          "kt-separator kt-separator--space-md kt-separator--border-dashed",
                      }),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.ignore.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Ignore For Now ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary alignment pull-right",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  disabled: _vm.saving || !_vm.isValid,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Save Changes ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }