var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.user
    ? _c(
        "div",
        {
          key: _vm.key,
          staticClass:
            "kt-container kt-grid__item kt-grid__item--fluid row pt-4 mt-5",
        },
        [
          _vm.userTwoFactorAuthMethodId
            ? _c("OneTimePassword")
            : _c(
                "div",
                {
                  class: {
                    "col-8 offset-2":
                      _vm.deviceType == "desktop" || _vm.deviceType == "laptop",
                    "col-8 offset-2 pt-5": _vm.deviceType == "tablet",
                    "col-12 pt-5 pl-2 pr-2": _vm.deviceType == "mobile",
                  },
                },
                [
                  _vm.twoFactorRequired
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "alert alert-light alert-elevate fade show",
                              attrs: { role: "alert" },
                            },
                            [
                              _c("div", { staticClass: "alert-icon" }, [
                                _c("i", {
                                  staticClass:
                                    "la la-info-circle kt-font-danger",
                                }),
                              ]),
                              _c("div", { staticClass: "alert-text" }, [
                                _c("p", [
                                  _vm._v(
                                    " Two factor authentication is required for your account. Please setup two factor authentication before continuing. "
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "kt-portlet kt-portlet--mobile" }, [
                    _c("div", { staticClass: "kt-portlet__head" }, [
                      _c("div", { staticClass: "kt-portlet__head-label" }, [
                        _c(
                          "span",
                          { staticClass: "kt-portlet__head-icon" },
                          [_c("SVGIcon", { attrs: { name: "encrypted" } })],
                          1
                        ),
                        _c("h3", { staticClass: "kt-portlet__head-title" }, [
                          _vm._v(" Setup Two Factor Authentication "),
                        ]),
                      ]),
                      _c("div", { staticClass: "kt-portlet__head-toolbar" }),
                    ]),
                    _vm.twoFactorMixinsAuthMethods.length > 0
                      ? _c(
                          "div",
                          { staticClass: "kt-portlet__body" },
                          [
                            _c("SetupTwoFactorAuth", {
                              attrs: {
                                "increment-key": _vm.incrementKey,
                                "full-screen": true,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "kt-portlet__foot" }, [
                      _vm.twoFactorMixinsAuthMethods.length > 0
                        ? _c("div", { staticClass: "row w-100" }, [
                            _c("div", { staticClass: "col-6" }, [
                              _vm.twoFactorEnabled
                                ? _c(
                                    "button",
                                    { staticClass: "btn btn-secondary" },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-power-off",
                                      }),
                                      _vm._v(" Finish Setup "),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "col-6" }),
                          ])
                        : _c("div", [
                            _c("div", { staticClass: "text-center" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary btn-upper btn-taller btn-wider",
                                  on: { click: _vm.showAddNewMethodModal },
                                },
                                [_vm._v(" Begin Setup Now ")]
                              ),
                            ]),
                          ]),
                    ]),
                  ]),
                ]
              ),
          _c("TwoFactorAuthMethodModal", {
            ref: "two-factor-auth-method-modal",
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }