<template>
<b-modal
    ref="help-redirect-modal"
    size="lg"
    variant="primary"
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Wiki Redirect Setup
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>
    <template #default>
        <div
            class="modal-body"
            style="padding: 0px 10px; overflow-x: hidden"
        >
            <div class="form-group">
                <label>Wiki URL for: {{ routeName }}</label>
                <input
                    v-model="mutableRedirectUrl"
                    type="text"
                    class="form-control"
                    placeholder="Enter wiki url"
                >
                <span v-if="hasWikiUrl" class="form-text text-muted">
                    Enter a new URL to update the current URL.
                </span>
                <span v-else class="form-text text-muted">
                    Enter a URL to redirect to when the user clicks the help button.
                </span>
            </div>
        </div>
    </template>
    <template #modal-footer>
        <div class="row w-100">
            <div class="col-12 text-right">
                <template v-if="hasWikiUrl">
                    <a
                        :href="wikiUrl"
                        target="wikiUrl"
                        class="btn btn-secondary mr-2"
                    >
                        Open Current url for `{{ routeName }}`
                    </a>
                    <a
                        href="#"
                        :disabled="!mutableRedirectUrl"
                        class="btn btn-primary"
                        @click.stop.prevent="saveChanges"
                    >
                        Edit Wiki URL
                    </a>
                </template>
                <a
                    v-else
                    href="#"
                    :disabled="!mutableRedirectUrl"
                    class="btn btn-primary"
                    @click.stop.prevent="saveChanges"
                >
                    Set Wiki URL
                </a>
            </div>
        </div>
    </template>
</b-modal>
</template>

<script>

import * as network from '../network';
import Types from '../store/Types';

export default {
    name: 'HelpRedirectModal',
    data() {
        return {
            mutableRedirectUrl: null,
            routeName: null,
        };
    },
    computed: {
        rootWikiPath() {
            return 'https://wiki.syncgrades.com/en/home';
        },
        hasWikiUrl() {
            return this.wikiUrl !== this.rootWikiPath;
        },
        wikiUrl() {
            const { helpRedirects } = this.$store.state.database;
            const routeName = this.$route.name;
            if ( helpRedirects && helpRedirects.hasOwnProperty(routeName) ) {
                return helpRedirects[routeName];
            }
            return this.rootWikiPath;
        },
        isSuperAdmin() {
            return this.$store.state.user.isSuperAdmin;
        },
    },
    methods: {
        saveChanges() {
            const { mutableRedirectUrl, routeName } = this;
            const { showError, close, $store } = this;

            const redirect = {
                redirectUrl: mutableRedirectUrl,
                routeName: routeName,
            };
            network.help.createRedirect({ body: { redirect } }, function(err) {
                if (err) return showError(err);
                const callback = function(err) {
                    if (err) return showError(err);
                };
                $store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, { tableName: 'helpRedirects', callback });
                close();
            });
        },
        open() {
            this.load();
            this.$refs['help-redirect-modal'].show();
        },
        close() {
            this.mutableRedirectUrl = null;
            this.routeName = null;
            this.$refs['help-redirect-modal'].hide();
        },
        load() {
            this.routeName = this.$route.name;
            this.mutableRedirectUrl = this.wikiUrl;
            if (this.hasWikiUrl) {
                this.mutableRedirectUrl = this.wikiUrl;
            }
        },
    },
};
</script>

<style scoped>
.kt-section__desc {
    margin-bottom: 0.4rem;
}
.modal-dialog {
    overflow-y: initial !important;
}
.modal-body {
    max-height: 75vh;
    overflow-y: auto;
}
</style>
