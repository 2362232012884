<template>
<div class="kt-header__topbar-item dropdown">
    <div
        v-if="$_userMixins_isSchoolUser"
        class="kt-header__topbar-wrapper"
        data-toggle="dropdown"
        data-offset="30px,0px"
        aria-expanded="true"
    >
        <span class="kt-header__topbar-icon">
            <SVGIcon
                class="kt-svg-icon"
                :name="'email'"
            />
        </span>
    </div>
    <div
        v-else
        class="kt-header__topbar-wrapper"
        data-toggle="dropdown"
        data-offset="10px,0px"
        @click.stop.prevent="toggleCreateMessage"
    >
        <span class="kt-header__topbar-icon">
            <SVGIcon
                :name="'email'"
            />
        </span>
    </div>
    <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-306px, 65px, 0px);"
        :class="{'d-none': $_userMixins_isHomeUser}"
        x-placement="bottom-end"
    >
        <div class="kt-mycart">
            <div class="kt-mycart__head kt-head" style="background-image: url(/images/bg-1.jpg);">
                <div class="kt-mycart__info">
                    <!-- <span class="kt-mycart__icon">
                        <SVGIcon
                            class="kt-svg-icon"
                            name="email"
                            :hex-color="'#ffffff'"
                        />
                    </span> -->
                    <h3 class="kt-mycart__title">
                        Communication
                    </h3>
                </div>
                <div class="kt-mycart__button">
                    <router-link
                        :to="{name: 'Communication'}"
                        class="btn btn-success btn-sm"
                    >
                        {{ `${unReadMessageCount} Unread` }}
                    </router-link>
                </div>
            </div>
            <div
                class="kt-mycart__body kt-scroll ps"
                data-scroll="true"
                data-height="245"
                data-mobile-height="200"
                style=""
            >
                <div class="kt-grid-nav kt-grid-nav--skin-light">
                    <div class="kt-grid-nav__row">
                        <a
                            v-if="$_userMixins_isSchoolAdmin && (canMessageSchoolUsers || canMessageHomeUsers)"
                            href="#"
                            class="kt-grid-nav__item"
                            @click.stop.prevent="toggleCreateSchoolAnnouncement"
                        >
                            <span class="kt-grid-nav__icon">
                                <SVGIcon
                                    class="kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg"
                                    :name="'announcement'"
                                />
                            </span>

                            <span class="kt-grid-nav__desc">New School Announcement</span>
                        </a>
                        <a
                            v-if="($_userMixins_isSchoolAdmin || $_userMixins_isTeacher) && (canMessageSchoolUsers || canMessageHomeUsers)"
                            href="#"
                            class="kt-grid-nav__item"
                            @click.stop.prevent="toggleCreateAnnouncement"
                        >
                            <span class="kt-grid-nav__icon">
                                <SVGIcon
                                    class="kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg"
                                    :name="'email'"
                                />
                            </span>

                            <span class="kt-grid-nav__desc">New Course Announcement</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="kt-mycart__footer">
                <div class="kt-mycart__section" />
                <div class="kt-mycart__button ">
                    <button
                        v-if="canMessageSchoolUsers || canMessageHomeUsers"
                        type="button"
                        class="btn btn-primary btn-sm mr-4"
                        @click="toggleCreateMessage"
                    >
                        New Chat
                    </button>
                    <router-link
                        :to="{name: 'Communication'}"
                        class="btn btn-success btn-sm pull-right1"
                    >
                        Open Inbox
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import SVGIcon from '../../../../components/SVGIcon/SVGIcon.vue';
import panelMixins from '../../../../store/mixins/panelMixins';
import userMixins from '../../../../store/mixins/userMixins';
import Types from '../../../../store/Types';

export default {
    name: 'MessagingButton',
    components: { SVGIcon },
    mixins: [panelMixins, userMixins],
    computed: {
        ...mapState({
            showCreateMessage: (state) => state.communication.createMessage,
            showCreateAnnouncement: (state) => state.communication.createAnnouncement,
            showCreateSchoolAnnouncement: (state) => state.communication.createSchoolAnnouncement,
            userPermissions: (state) => state.user.userPermissions,
        }),
        unReadMessageCount() {
            return this.$store.state.database.messages.filter((message) => !message.messageRead).length;
        },
        canMessageSchoolUsers() {
            const { userPermissions } = this;
            return userPermissions.canMessageSchoolUsers;
        },
        canMessageHomeUsers() {
            const { userPermissions } = this;
            return userPermissions.canMessageHomeUsers;
        },
    },
    methods: {
        toggleCreateMessage() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_MESSAGE);
        },
        toggleCreateAnnouncement() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_ANNOUNCEMENT);
        },
        toggleCreateSchoolAnnouncement() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_SCHOOL_ANNOUNCEMENT);
        },
    },
};
</script>

<style>

span.kt-header__topbar-icon--active path {
    fill: #0f88ef !important;
}
.unread-count {
    position: absolute;
    bottom: 0px;
    right: 0px;
}
</style>
