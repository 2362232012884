<template>
<b-modal
    ref="google-course-modal"
    v-model="showModal"
    size="lg"
    no-close-on-backdrop
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title kt-font-danger">
            Invalid Google Linked Courses
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close"
        />
    </template>

    <template #default>
        <div class="modal-body">
            <div class="form-group form-group-last pt-3 pb-5">
                <div
                    class="alert alert-secondary"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-danger kt-font-brand" />
                    </div>
                    <div class="alert-text">
                        Each combo course associated with <code>{{ extCourseSectionId }}</code>
                        must share the same Google Course Link.

                        To fix this, please link each of the following to the same Google Course.
                        <br><br>
                        <code>Your combo courses may not work correctly until they share the same Google Course Link.</code>
                    </div>
                </div>
            </div>
            <div
                v-for="item in changes"
                :key="`courseSectionId_${item.courseSectionId}`"
                class="form-group row px-5"
            >
                <div class="col-md-4">
                    <label>Course</label>
                    <input
                        type="text"
                        class="form-control col-md-10"
                        disabled
                        :value="`${item.extCourseSectionId}: ${item.name}`"
                    >
                </div>
                <div class="col-md-4">
                    <label>Google Course</label>
                    <input
                        type="text"
                        class="form-control col-md-10"
                        disabled
                        :value="`${item.googleCourseName}`"
                    >
                </div>
                <div class="col-md-4">
                    <label>&nbsp;</label>
                    <br>
                    <a
                        href="#"
                        class="btn btn-primary"
                        data-dismiss="modal"
                        @click.stop.prevent="editLink(item)"
                    >
                        <i class="la la-link" />
                        Change Course Link
                    </a>
                </div>

                <div class="kt-separator kt-separator--space-md kt-separator--border-dashed" />
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.stop.prevent="ignore"
        >
            Ignore For Now
        </button>
    </template>
</b-modal>
</template>

<script>
import { getPortfolioCacheKey } from '../store/mixins/portfolioMixins';
import courseMixins from '../store/mixins/courseMixins';
import Types from '../store/Types';

export default {
    name: 'GlobalComboCourseGoogleCourseModal',
    mixins: [courseMixins],
    data() {
        return {
            saving: false,
            show: false,
            changes: [],
        };
    },
    computed: {
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        unmatched() {
            const { comboCourses } = this;
            if (comboCourses.length == 0) return [];
            const googleCourseId = this.course.googleCourseId || 0;
            const allMatching = comboCourses.every((c) => (c.googleCourseId || 0) === googleCourseId);
            if (allMatching) return [];
            return comboCourses.slice();
        },
        comboCourses() {
            const getCourseById = this.$_courseMixins_getCourseById;
            const { comboCourses } = this.course;
            if (!comboCourses || comboCourses.length == 0) return [];
            const courses = comboCourses.map((c) => {
                const cc = { ...c };
                const course = getCourseById(cc.courseSectionId);
                const { name, extCourseSectionId } = course;
                const { courseSectionId, googleCourseId } = course;
                const googleCourse = this.$store.state.database.googleCourses.find((g) => g.googleCourseId == googleCourseId);
                const googleCourseName = googleCourse ? googleCourse.googleCourseName : 'Unknown Name';
                return {
                    name,
                    extCourseSectionId,
                    courseSectionId,
                    googleCourseId,
                    googleCourseName,
                };
            });
            return courses;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        isPortfolioReady() {
            const cacheKey = getPortfolioCacheKey(this.$route, this.$store.state.database);
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        schoolEmail() {
            return this.$route.params.schoolEmail;
        },
        showModal: {
            get() {
                return this.show;
            },
            set(val) {
                this.show = val;
            },
        },
    },
    watch: {
        $route() {
            this.populate();
        },
        isPortfolioReady() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        ignore() {
            this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, true);
            setTimeout(function () {
                this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, false);
            }, 1000 * 60 * 15); // 15 mins
            this.showModal = false;
        },
        editLink(item) {
            const { schoolEmail } = this;
            this.ignore();
            this.$router.push({
                name: 'TeacherCourseInfo',
                params: {
                    extCourseSectionId: item.extCourseSectionId,
                    schoolEmail,
                },
            });
        },
        populate() {
            const { schoolEmail, extCourseSectionId } = this.$route.params;
            const isPortfolioView = Boolean(schoolEmail && extCourseSectionId);
            if (!isPortfolioView) return;

            const { isPortfolioReady, unmatched } = this;
            if (!isPortfolioReady) return;

            this.changes = unmatched.map((x) => ({ ...x }));
            this.showModal = this.changes.length > 0;
        },
    },
};
</script>
