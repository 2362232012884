import { render, staticRenderFns } from "./MessageNotification.vue?vue&type=template&id=5f01f239"
import script from "./MessageNotification.vue?vue&type=script&lang=ts"
export * from "./MessageNotification.vue?vue&type=script&lang=ts"
import style0 from "./MessageNotification.vue?vue&type=style&index=0&id=5f01f239&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f01f239')) {
      api.createRecord('5f01f239', component.options)
    } else {
      api.reload('5f01f239', component.options)
    }
    module.hot.accept("./MessageNotification.vue?vue&type=template&id=5f01f239", function () {
      api.rerender('5f01f239', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Communication/MessageNotification.vue"
export default component.exports