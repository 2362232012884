<template>
<b-modal
    ref="google-course-modal"
    v-model="showModal"
    size="lg"
    no-close-on-backdrop
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title kt-font-danger">
            Combo Courses Using Different Grade Templates
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>

    <template #default>
        <div class="modal-body">
            <div class="form-group form-group-last pt-3 pb-5">
                <div
                    class="alert alert-secondary"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-danger kt-font-brand" />
                    </div>
                    <div class="alert-text">
                        Each combo courses associated with <code>{{ extCourseSectionId }}</code>
                        MUST share the same Grade Template.

                        To fix this, choose the same Grade Template for all courses listed below
                        and press save.
                        <br><br>
                        <code>Your combo courses may not work correctly until they share the same Grade Template.</code>
                    </div>
                </div>
            </div>
            <div
                v-for="item in changes"
                :key="`courseSectionId_${item.courseSectionId}`"
                class="form-group row px-5"
            >
                <div class="col-md-6">
                    <label>Course</label>
                    <input
                        type="text"
                        class="form-control col-md-10"
                        disabled
                        :value="`${item.extCourseSectionId}: ${item.name}`"
                    >
                </div>

                <div class="col-md-6">
                    <label>Grade Template</label>
                    <select
                        v-model="item.gradeTemplateId"
                        class="custom-select form-control col-md-10"
                    >
                        <option
                            v-for="t in gradeTemplates"
                            :key="`option_t_${t.gradeTemplateId}_${item.gradeTemplateId}`"
                            :value="t.gradeTemplateId"
                            :selected="t.gradeTemplateId == item.gradeTemplateId"
                        >
                            {{ t.gradeTemplateName }}
                        </option>
                    </select>
                    <span
                        v-if="!item.gradeTemplateId"
                        class="form-text text-muted kt-font-danger"
                    >Select a new Grade Template</span>
                </div>

                <div class="kt-separator kt-separator--space-md kt-separator--border-dashed" />
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.stop.prevent="ignore"
        >
            Ignore For Now
        </button>
        <button
            type="button"
            class="btn btn-primary alignment pull-right"
            data-dismiss="modal"
            :disabled="saving || !isValid"
            @click.stop.prevent="save()"
        >
            Save Changes
        </button>
    </template>
</b-modal>
</template>

<script>
import { getPortfolioCacheKey } from '../store/mixins/portfolioMixins';
import courseMixins from '../store/mixins/courseMixins';
import * as network from '../network';
import Types from '../store/Types';

export default {
    name: 'GlobalComboCourseGradeTemplateModal',
    mixins: [courseMixins],
    data() {
        return {
            saving: false,
            show: false,
            changes: [],
        };
    },
    computed: {
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        unmatched() {
            const { comboCourses, gradeTemplate } = this;
            if (!gradeTemplate || !comboCourses || comboCourses.length == 0) return [];
            const gradeTemplateId = gradeTemplate.gradeTemplateId || 0;
            const allMatching = comboCourses.every((c) => c.gradeTemplateId == gradeTemplateId);
            if (allMatching) return [];
            return comboCourses.slice();
        },
        comboCourses() {
            return [];
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        gradeTemplate() {
            if (!this.course) return null;
            return this.course.gradeTemplate;
        },
        gradeTemplates() {
            return this.$store.state.database.gradeTemplates.filter((t) => !t.deleted);
        },
        isValid() {
            const { changes } = this;
            if (changes.length == 0) return false;
            const [first] = changes;
            if (!first) return false;
            const { gradeTemplateId } = first;
            const allSame = changes.every((c) => c.gradeTemplateId == gradeTemplateId);
            return allSame;
        },
        isPortfolioReady() {
            const cacheKey = getPortfolioCacheKey(this.$route, this.$store.state.database);
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        showModal: {
            get() {
                return this.show;
            },
            set(val) {
                this.show = val;
            },
        },
    },
    watch: {
        $route() {
            this.populate();
        },
        isPortfolioReady() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        ignore() {
            this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, true);
            setTimeout(function () {
                this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, false);
            }, 1000 * 60 * 15); // 15 mins
            this.showModal = false;
        },
        populate() {
            const { schoolEmail, extCourseSectionId } = this.$route.params;
            const isPortfolioView = Boolean(schoolEmail && extCourseSectionId);
            if (!isPortfolioView) return;

            const { isPortfolioReady, unmatched } = this;
            if (!isPortfolioReady) return;

            this.changes = unmatched.map((x) => ({ ...x }));
            this.showModal = this.changes.length > 0;
        },
        save() {
            const v = this;
            const { showError, changes } = this;
            const { schoolId, schoolTermId, schoolStaffId } = this.$store.state.user.school;

            if (this.saving) return;
            if (!this.isValid) return;

            this.saving = true;

            const courseSectionGradeTemplates = changes.map((x) => ({
                courseSectionId: x.courseSectionId,
                gradeTemplateId: x.gradeTemplateId,
            }));

            const params = {
                url: { schoolId, schoolTermId, schoolStaffId },
                body: { courseSectionGradeTemplates },
            };
            network.courseSections.assignTemplate(params, (err) => {
                v.saving = false;
                if (err) return showError(err);
                window.location.reload();
            });
        },
    },
};
</script>
