<template>
<li
    class="kt-menu__item menu-user-header"
    :class="{
        'kt-menu__item--open': open,
        'kt-menu__item--dull-open': openAndDull
    }"
>
    <a
        href="#()-menu"
        class="kt-menu__link pt-2 pb-2"
        @click.stop.prevent="toggle()"
    >
        <span class="kt-menu__link-icon">
            <SVGIcon name="wizard" />
        </span>

        <span class="kt-menu__link-text">
            Term Setup Wizard
        </span>

        <i class="kt-menu__ver-arrow la la-angle-right" />
    </a>
    <b-collapse
        v-model="open"
        class="kt-menu__submenu"
    >
        <span class="kt-menu__arrow" />
        <ul class="kt-menu__subnav">
            <AsideMenuItemSetupWizard
                v-for="r in routes"
                :key="`vue_${r.routeName}`"
                :route-name="r.routeName"
            />

            <!-- Add hide menu () -->
            <li v-if="!finalized" class="kt-menu__item">
                <a
                    href="#"
                    class="kt-menu__link"
                    @click.stop.prevent="hideWizard === 'true' ? hideWizard = '' : hideWizard = 'true'"
                >
                    <span class="kt-menu__link-icon">
                        <i class="la la-navicon kt-menu__link-icon"> <span /> </i>
                    </span>

                    <span class="kt-menu__link-text">
                        <div v-if="hideWizard" class="menu-subtitle text-nowrap text-truncate">
                            Hide Full Menu
                        </div>
                        <div v-else class="menu-subtitle text-nowrap text-truncate">
                            Show Full Menu
                        </div>
                    </span>
                </a>
            </li>
        </ul>
    </b-collapse>
</li>
</template>

<script lang="ts">
import Types from '../../../../store/Types';
import AsideMenuItemSetupWizard from './AsideMenuItemSetupWizard.vue';

export default {
    name: 'TheSetupWizardMenu',
    components: {
        AsideMenuItemSetupWizard,
    },
    data() {
        return {
            open: true,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        routes() {
            const { state } = this.$store;
            const { subscription } = state.user;

            if (!this.$store.state.user.school.finalized && !subscription.adminLocked) {
                return this.$store.state.wizard.steps;
            } else {
                return this.$store.state.wizard.steps
                    .filter(function(s) {
                        if (subscription.anecdotalsEnabled === false && s.routeName === 'setup-anecdotals') {
                            return false;
                        }
                        if (subscription.gradingEnabled === false && s.routeName === 'setup-grading') {
                            return false;
                        }
                        if (subscription.encryptionEnabled === false && s.routeName === 'setup-encryption') {
                            return false;
                        }
                        if (subscription.communicationEnabled === false && s.routeName === 'setup-communication') {
                            return false;
                        }
                        return true;
                    });
            }
        },
        externalDistrictId() {
            return this.user.school.externalDistrictId;
        },
        role() {
            return this.user.school.role;
        },
        finalized() {
            return this.$store.state.user.school.finalized;
        },
        hideWizard: {
            get: function() {
                return this.$store.state.wizard.hide;
            },
            set: function(val) {
                this.$store.commit(Types.mutations.SET_HIDE_WIZARD, val);
            }
        },
        googleSetting() {
            return this.$store.state.user.school.googleSetting;
        },
        openAndDull() {
            return Boolean(this.isActive && this.open);
        },
        isActive() {
            return this.$route.fullPath.indexOf('/configuration/wizard/') > -1;
        },
    },
    watch: {
        $route() {
            this.setOpenState();
        },
    },
    mounted() {
        this.setOpenState();
    },
    methods: {
        toggle() {
            this.open = !this.open;
        },
        setOpenState() {
            const v = this;
            // this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);

            if (!this.finalized) {
                this.open = true;
                return;
            }
            const { isActive } = this;
            this.open = Boolean(isActive);
        },
    },
};
</script>

<style lang="scss">
.kt-menu__item--dull-open {
    .kt-menu__link-text {
        color: #3d4456 !important;
    }
    .kt-menu__link-icon svg g [fill] {
        fill: #c4cff9 !important;;
    }
}
</style>
