var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.step && _vm.step.visible
    ? _c(
        "li",
        {
          key: `key_${_vm.key}`,
          staticClass: "kt-menu__item",
          class: {
            "link-exact-active router-link-active": _vm.active && !_vm.enabled,
          },
        },
        [
          _vm.enabled
            ? _c(
                "a",
                {
                  staticClass: "kt-menu__link",
                  class: {
                    "router-link-exact-active router-link-active": _vm.active,
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.navigate()
                    },
                  },
                },
                [
                  _vm.finalized
                    ? _c(
                        "i",
                        {
                          staticClass:
                            "kt-menu__link-bullet kt-menu__link-bullet--dot",
                        },
                        [_c("span")]
                      )
                    : [
                        _c("span", { staticClass: "kt-menu__link-icon" }, [
                          _c("i", {
                            class: `la ${_vm.icon} ${
                              _vm.status == ""
                                ? "kt-menu__link-icon"
                                : "kt-font-" + _vm.status
                            } kt-menu__link-icon`,
                          }),
                        ]),
                      ],
                  _c("span", { staticClass: "kt-menu__link-text" }, [
                    _c(
                      "div",
                      {
                        staticClass: "menu-subtitle text-nowrap text-truncate",
                      },
                      [_vm._v(" " + _vm._s(_vm.title) + " ")]
                    ),
                  ]),
                ],
                2
              )
            : _c("span", { staticClass: "kt-menu__link" }, [
                _c("span", { staticClass: "kt-menu__link-icon" }, [
                  _c("i", { class: `la la-minus kt-menu__link-icon` }),
                ]),
                _c("span", { staticClass: "kt-menu__link-text" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "menu-subtitle text-nowrap text-truncate text-muted",
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                ]),
              ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }