var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "kt-menu__nav pt-0" },
    [
      _c("AsideMenuItemHeader", { attrs: { title: "Data Analytics" } }),
      _c("DataAnalyticsMenu"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }