var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "kt-menu__item menu-user-header",
      class: {
        "kt-menu__item--open": _vm.open,
        "kt-menu__item--dull-open": _vm.openAndDull,
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "kt-menu__link pt-2 pb-2",
          attrs: { href: "#toggle-menu" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggle.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "kt-menu__link-icon" },
            [_c("SVGIcon", { attrs: { name: "googleClassroom" } })],
            1
          ),
          _c("span", { staticClass: "kt-menu__link-text" }, [
            _vm._v(" Google Classroom "),
          ]),
          _c("i", { staticClass: "kt-menu__ver-arrow la la-angle-right" }),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "kt-menu__submenu",
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c("span", { staticClass: "kt-menu__arrow" }),
          _c(
            "ul",
            { staticClass: "kt-menu__subnav" },
            [
              _c("AsideMenuItem", {
                attrs: { title: "Account", "route-name": "GoogleAccount" },
              }),
              _vm.$_userMixins_isSchoolAdmin
                ? _c("AsideMenuItem", {
                    attrs: {
                      title: "Admin Credentials",
                      "route-name": "AdminGoogleAccount",
                    },
                  })
                : _vm._e(),
              _vm.$_userMixins_isSchoolAdmin
                ? _c("AsideMenuItem", {
                    attrs: {
                      title: "Account Creator",
                      "route-name": "GoogleAccountCreator",
                    },
                  })
                : _vm._e(),
              _vm.$_userMixins_isSchoolAdmin
                ? _c("AsideMenuItem", {
                    attrs: {
                      title: "User Linker",
                      "route-name": "GoogleUserLink",
                    },
                  })
                : _vm._e(),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Course Manager",
                  "route-name": "GoogleCourseManager",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }