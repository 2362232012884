var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "kt-menu__item",
      class: {
        "kt-menu__item--here kt-menu__item--active": _vm.open,
        "kt-menu__item--open": _vm.open,
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "kt-menu__link",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.open = !_vm.open
            },
          },
        },
        [
          _c("CourseIcon", { attrs: { course: _vm.course, size: "xs" } }),
          _c("span", { staticClass: "kt-menu__link-text ml-2" }, [
            _c("div", { staticClass: "menu-subtitle" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.course.courseSectionTitle || _vm.course.name) +
                  " "
              ),
            ]),
          ]),
          _vm.course.nextMeeting &&
          _vm.course.nextMeeting.day &&
          _vm.course.nextMeeting.day !== _vm.today
            ? _c("span", { staticClass: "kt-menu__link-badge" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "kt-shape-bg-color-1 kt-label-font-color-2 kt-font-xs px-2 py-1",
                    class: _vm.color,
                    staticStyle: { color: "#fff", "border-radius": "0.4rem" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.course.nextMeeting.day.substring(0, 3)) +
                        " "
                    ),
                  ]
                ),
              ])
            : _c("i", { staticClass: "kt-menu__ver-arrow la la-angle-right" }),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          staticClass: "kt-menu__submenu",
          model: {
            value: _vm.open,
            callback: function ($$v) {
              _vm.open = $$v
            },
            expression: "open",
          },
        },
        [
          _c("span", { staticClass: "kt-menu__arrow" }),
          _vm.course.extCourseSectionId
            ? _c(
                "ul",
                { staticClass: "kt-menu__subnav" },
                [
                  _c(
                    "li",
                    { staticClass: "kt-menu__item kt-menu__item--parent" },
                    [
                      _c("span", { staticClass: "kt-menu__link" }, [
                        _c("span", { staticClass: "kt-menu__link-text" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.course.courseSectionTitle || _vm.course.name
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._l(_vm.menuItems, function (item) {
                    return _c(
                      "li",
                      {
                        key: `${item.name}_${_vm.course.extCourseSectionId}`,
                        staticClass: "kt-menu__item kt-menu__course-item",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "kt-menu__link",
                            class: {
                              "router-link-exact-active":
                                item.name == "TeacherCourseReportCards" &&
                                _vm.$route.name ==
                                  "TeacherCourseReportCardGrades",
                            },
                            attrs: {
                              to: {
                                name: item.name,
                                params: _vm.routeParameters,
                              },
                            },
                          },
                          [
                            item.icon
                              ? _c(
                                  "span",
                                  { staticClass: "kt-menu__link-icon" },
                                  [
                                    _c("SVGIcon", {
                                      attrs: { name: item.icon },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "i",
                                  {
                                    staticClass:
                                      "kt-menu__link-bullet kt-menu__link-bullet--dot",
                                  },
                                  [_c("span")]
                                ),
                            _c("span", { staticClass: "kt-menu__link-text" }, [
                              _vm._v(" " + _vm._s(item.title) + " "),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }