<template>
<div class="kt-header__topbar kt-grid__item">
    <div v-if="onBetaRoute" class="kt-header__topbar-item mr-4">
        <div class="kt-header__topbar-wrapper">
            <span
                v-b-tooltip.hover="`This page is a Beta Feature. Feedback and Bug reports are welcome as it is still receiving additional improvements.`"
                class="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded py-2"
                style="max-height: 30px; align-self: center;"
            >
                <i class="fas fa-fire mr-2" />
                <span class="kt-font-boldest">BETA</span>
            </span>
        </div>
    </div>

    <TheSuperMenuButton v-if="$_userMixins_isSuperAdmin" />
    <TheHelpRedirectButton v-if="!$_userMixins_isHomeUser || $_userMixins_isSuperAdmin" />

    <TheBugRecordingButton />

    <TheSearchBar v-if="!$_userMixins_isDisabled && canSearch" />
    <AnecdotalButton v-if="!$_userMixins_isDisabled && $_userMixins_isSchoolUser" />
    <MessagingButton v-if="!$_userMixins_isDisabled" />

    <TheSettingsButton v-if="!$_userMixins_isDisabled && !$_userMixins_isHomeUser" />
    <TheCipherButton v-if="twoFactorEnabled && $_userMixins_isSchoolUser && hasLocalPassword" />
    <TheLocaleSwitcher v-if="false" />

    <TheUserBar />
</div>
</template>

<script lang="ts">

import { mapState, mapGetters } from 'vuex';
import Types from '../../../../store/Types';
import TheSearchBar from './TheSearchBar.vue';
import TheUserBar from './TheUserBar.vue';
import TheSuperMenuButton from './TheSuperMenuButton.vue';
import TheHelpRedirectButton from './TheHelpRedirectButton.vue';
import AnecdotalButton from './AnecdotalButton.vue';
import TheLocaleSwitcher from './TheLocaleSwitcher.vue';
import TheCipherButton from './TheCipherButton.vue';
import TheSettingsButton from './TheSettingsButton.vue';
import MessagingButton from './MessagingButton.vue';
import TheBugRecordingButton from './TheBugRecordingButton.vue';
import globalMixins from '../../../../store/mixins/globalMixins';
import userMixins from '../../../../store/mixins/userMixins';

export default {
    name: 'TheTopRightButtons',
    components: {
        AnecdotalButton,
        MessagingButton,
        TheBugRecordingButton,
        TheCipherButton,
        TheHelpRedirectButton,
        TheLocaleSwitcher,
        TheSearchBar,
        TheSettingsButton,
        TheSuperMenuButton,
        TheUserBar,
    },
    mixins: [globalMixins, userMixins],
    computed: {
        ...mapState({
            user: (state) => state.user,
            hasLocalPassword: (state) => state.user.hasLocalPassword,
            hideAllNavigation: (state) => state.navigation.hideAllNavigation,
        }),
        ...mapGetters({
            isNycGoogleDomainUser: Types.getters.IS_NYC_GOOGLE_DOMAIN_USER,
            twoFactorEnabled: Types.getters.IS_TWO_FACTOR_AUTH_ENABLED,
        }),
        canSearch() {
            const v = this;
            return ['School Admin', 'Staff', 'Teacher'].includes(v.user.school.role);
        },
        canAutoLink() {
            const v = this;
            return ['Super Admin', 'School Admin'].includes(v.user.school.role);
        },
        hasSession() {
            const v = this;
            return v.user.school.schoolId > 0;
        },
        onBetaRoute() {
            const route = this.$route;
            return route.meta.isBeta || false;
        },
    },
};
</script>
