<template>
<div
    class="d-flex justify-content-between py-1 px-3 my-2"
    @click.stop.prevent="itemSelect()"
>
    <div class="kt-user-card-v2">
        <div class="kt-user-card-v2__pic">
            <CourseIcon
                class="course-icon"
                :course="course"
                size="md"
            />
        </div>
        <div class="p-2">
            <div class="d-block course-title w-100 kt-font-bold kt-font-lg kt-font-dark">
                {{ course.name }}
            </div>
            <div class="d-block w-100">
                {{ teachers }}
            </div>
        </div>
    </div>
    <div class="p-2 text-right">
        <span class="d-block">
            {{ course.meetingName }}
        </span>
        <span class="d-block">
            {{ course.extCourseSectionId }}
        </span>
    </div>
</div>
</template>

<script lang="ts">

import CourseIcon from './CourseIcon.vue';

export default {
    name: 'CourseBadge',
    components: { CourseIcon },
    props: {
        shortTitle: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        course: {
            type: Object,
            required: true,
        },
        onSelected: {
            type: Function,
            required: true,
        },
    },
    computed: {
        teachers() {
            const { course } = this;
            if (!course.teachers) return '';
            return course.teachers
                .map((t) => `${t.lastName} ${t.firstName.substring(0, 1)}`)
                .join(', ');
        },
    },
    methods: {
        itemSelect() {
            this.onSelected(this.index);
        },
    },
};
</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style scoped>

div.justify-content-between {
    border-radius: 5px;
    cursor: pointer;
}
div.justify-content-between:hover {
    background-color: #f9f9f9;
}

div.justify-content-between.active {
    background-color: #bddefa;
}

div.justify-content-between:hover div.course-title {
    color: #339bf3 !important;
}

.course-icon {
    display: block;
    font-size: 1.1rem;
    font-weight: 500
}

.d-block {
    line-height: 1.5rem;
    max-height: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

</style>
