var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { staticClass: "kt-menu__section mt-0" }, [
    _c("h4", { staticClass: "kt-menu__section-text" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c("i", { staticClass: "kt-menu__section-icon flaticon-more-v2" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }