<template>
<div
    :class="{'show': showLocales}"
    class="kt-header__topbar-item kt-header__topbar-item--search dropdown"
>
    <div
        class="kt-header__topbar-wrapper"
        data-toggle="dropdown"
        data-offset="10px,0px"
        @click.stop.prevent="opened = true; setLocales('toggle')"
    >
        <span class="kt-header__topbar-icon">
            <img
                class="kt-menu__link-icon flag-image"
                :src="selectedFlag"
            >
        </span>
    </div>
    <div
        id="locales"
        :class="{'show': showLocales}"
        class=" py-2 dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim"
    >
        <template v-for="locale in locales">
            <li
                :key="locale.locale"
                class="kt-menu__item"
            >
                <a
                    href="#"
                    class="kt-menu__link"
                    @click.stop.prevent="switchLocale(locale.locale)"
                >
                    <img
                        class="kt-menu__link-icon flag-image"
                        :src="locale.flag"
                    >
                    <span class="kt-menu__link-text locale-label">
                        {{ locale.label }}
                    </span>
                </a>
            </li>
        </template>
    </div>
</div>
</template>

<script>

import { mapState, mapMutations, mapGetters } from 'vuex';

export default {
    name: 'TheLocaleSwitcher',
    data() {
        return {
            locales: [
                {
                    locale: 'en',
                    flag: '/images/flags/226-united-states.svg',
                    label: 'English',
                },
                {
                    locale: 'es',
                    flag: '/images/flags/128-spain.svg',
                    label: 'Spanish',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            locale: (state) => state.locale,
            showLocales: (state) => state.navigation.showLocales,
        }),
        selectedFlag() {
            const selectedLocale = this.locales.find((locale) => locale.locale == this.getLocale());
            return selectedLocale?.flag || this.locales[0].flag;
        },
    },
    methods: {
        ...mapMutations([
            'setLocale',
            'setLocales',
        ]),
        ...mapGetters([
            'getLocale',
        ]),
        switchLocale(locale) {
            this.setLocale(locale);
            this.$root.$i18n.locale = this.getLocale();
            this.setLocales();
        },
    },
};

</script>

<style scoped>
#locales.show {
    position: absolute;
    transform: translate3d(-138px, 80px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
}
@media (max-width: 768px) {
  #locales.show {
    top: -12px;
    left: -12px;
  }
}
img.flag-image {
    width: 22px;
    height: 22px;
    border-radius: 5px;
}
span.locale-label {
    margin-left: 20px;
}
</style>
