<template>
<div class="kt-header__topbar-item">
    <div
        v-if="screenRecord.isRecording"
        class="kt-header__topbar-wrapper"
        @click.stop.prevent="stopScreenRecord"
    >
        <span
            id="record-icon"
            v-b-tooltip.hover="'Click here to stop recording'"
            class="kt-header__topbar-icon1 kt-pulse kt-pulse--danger"
        >
            <SVGIcon
                name="record"
                hex-color="#fd397a"
            />
            <span class="kt-pulse__ring" />
        </span>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import Types from '../../../../store/Types';

export default {
    name: 'TheBugRecordingButton',
    computed: {
        ...mapState({
            screenRecord: (state) => state.screenRecord,
        }),
    },
    methods: {
        stopScreenRecord() {
            const { $store, $router } = this;
            $store.dispatch(Types.actions.STOP_SCREEN_RECORD, () => {
                $router.push({ name: 'SubmitBugReport' });
            });
        },
    },
};
</script>

<style scoped>
#record-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
}
</style>
