var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "google-course-modal",
    attrs: { size: "lg", "no-close-on-backdrop": "", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title kt-font-danger" }, [
              _vm._v(" Invalid Google Linked Courses "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: { click: close },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c(
                  "div",
                  { staticClass: "form-group form-group-last pt-3 pb-5" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert alert-secondary",
                        attrs: { role: "alert" },
                      },
                      [
                        _c("div", { staticClass: "alert-icon" }, [
                          _c("i", {
                            staticClass: "flaticon-danger kt-font-brand",
                          }),
                        ]),
                        _c("div", { staticClass: "alert-text" }, [
                          _vm._v(" Each combo course associated with "),
                          _c("code", [_vm._v(_vm._s(_vm.extCourseSectionId))]),
                          _vm._v(
                            " must share the same Google Course Link. To fix this, please link each of the following to the same Google Course. "
                          ),
                          _c("br"),
                          _c("br"),
                          _c("code", [
                            _vm._v(
                              "Your combo courses may not work correctly until they share the same Google Course Link."
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._l(_vm.changes, function (item) {
                  return _c(
                    "div",
                    {
                      key: `courseSectionId_${item.courseSectionId}`,
                      staticClass: "form-group row px-5",
                    },
                    [
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", [_vm._v("Course")]),
                        _c("input", {
                          staticClass: "form-control col-md-10",
                          attrs: { type: "text", disabled: "" },
                          domProps: {
                            value: `${item.extCourseSectionId}: ${item.name}`,
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", [_vm._v("Google Course")]),
                        _c("input", {
                          staticClass: "form-control col-md-10",
                          attrs: { type: "text", disabled: "" },
                          domProps: { value: `${item.googleCourseName}` },
                        }),
                      ]),
                      _c("div", { staticClass: "col-md-4" }, [
                        _c("label", [_vm._v(" ")]),
                        _c("br"),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { href: "#", "data-dismiss": "modal" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.editLink(item)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-link" }),
                            _vm._v(" Change Course Link "),
                          ]
                        ),
                      ]),
                      _c("div", {
                        staticClass:
                          "kt-separator kt-separator--space-md kt-separator--border-dashed",
                      }),
                    ]
                  )
                }),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.ignore.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Ignore For Now ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.showModal,
      callback: function ($$v) {
        _vm.showModal = $$v
      },
      expression: "showModal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }