var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    _c(
      "a",
      { staticClass: "kt-header__brand-logo w-100 text-center" },
      [
        _c("SVGIcon", {
          staticClass: "logo loading",
          attrs: { name: "syncGradesLoader" },
        }),
        _vm.loadState !== "DECRYPTING"
          ? _c("h1", { staticClass: "kt-font-bolder" }, [
              _vm._v(" " + _vm._s(_vm.ellipsis) + " "),
              _c("br"),
              _vm._v(" loading data "),
              _c("br"),
              _vm._v(" please wait "),
            ])
          : _c("h1", { staticClass: "kt-font-bolder" }, [
              _vm._v(" " + _vm._s(_vm.ellipsis) + " "),
              _c("br"),
              _vm._v(" Applying ciphers "),
              _c("br"),
              _vm._v(" please wait "),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }