var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "kt-menu__item menu-user-header",
      class: {
        "kt-menu__item--open": _vm.classMenuOpen,
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "kt-menu__link pt-2 pb-2",
          attrs: { href: "#toggle-menu" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.classMenuOpen = !_vm.classMenuOpen
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "kt-menu__link-icon",
              class: {
                "kt-menu__link-icon--dull": _vm.openAndDull,
              },
            },
            [_c("SVGIcon", { attrs: { name: "courses" } })],
            1
          ),
          _c("span", { staticClass: "kt-menu__link-text" }, [
            _vm._v(" Classes "),
          ]),
          _c("i", { staticClass: "kt-menu__ver-arrow la la-angle-right" }),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "kt-menu__submenu",
          model: {
            value: _vm.classMenuOpen,
            callback: function ($$v) {
              _vm.classMenuOpen = $$v
            },
            expression: "classMenuOpen",
          },
        },
        [
          _c("span", { staticClass: "kt-menu__arrow" }),
          _c("ul", { staticClass: "kt-menu__subnav" }, [_vm._t("default")], 2),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }