var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showStudentBadgePanel
    ? _c(
        "div",
        {
          staticClass: "modal modal-sticky-bottom-right modal-sticky-lg show",
          staticStyle: { display: "block" },
          attrs: {
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c(
              "div",
              { staticClass: "modal-content kt-inbox" },
              [_c("StudentBadgeForm")],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }