var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showFooter
    ? _c(
        "div",
        {
          staticClass:
            "kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop",
          attrs: { id: "kt_footer" },
        },
        [
          _c("div", { staticClass: "kt-container kt-container--fluid" }, [
            _c("div", { staticClass: "kt-footer__copyright" }, [
              _vm._v(" " + _vm._s(_vm.year) + " ©  "),
              _c(
                "a",
                {
                  staticClass: "kt-link",
                  attrs: {
                    href: "https://www.syncgrades.com/",
                    target: "_blank",
                  },
                },
                [_vm._v("Lilo Consulting LLC")]
              ),
            ]),
            _c("div", { staticClass: "kt-footer__menu" }, [
              _c(
                "a",
                {
                  staticClass: "kt-footer__menu-link kt-link",
                  attrs: {
                    href: "https://www.syncgrades.com/",
                    target: "_blank",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("components.theFooter.contactUs")))]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }