var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-header__topbar-item kt-header__topbar-item--search dropdown",
      class: { show: _vm.showSearchBar },
      attrs: { title: "Search" },
    },
    [
      _c(
        "div",
        {
          staticClass: "kt-header__topbar-wrapper",
          attrs: { "data-toggle": "dropdown", "data-offset": "10px, 0px" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.setSearchBar("toggle")
              _vm.focus()
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "kt-header__topbar-icon" },
            [_c("SVGIcon", { attrs: { name: "search" } })],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-lg",
          class: { show: _vm.showSearchBar },
          attrs: { id: "topSearch" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "kt-quick-search kt-quick-search--dropdown kt-quick-search--result-compact",
            },
            [
              _c(
                "form",
                {
                  staticClass: "kt-quick-search__form",
                  attrs: { method: "get" },
                  on: {
                    submit: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.selectPortfolio(0)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input-group",
                      staticStyle: { background: "transparent !important" },
                    },
                    [
                      _vm._m(0),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchTerm,
                            expression: "searchTerm",
                          },
                        ],
                        staticClass: "form-control kt-quick-search__input",
                        staticStyle: { background: "transparent !important" },
                        attrs: {
                          id: "main-search",
                          type: "text",
                          name: "term",
                          autocomplete: "off",
                          placeholder: "Search teachers or students",
                        },
                        domProps: { value: _vm.searchTerm },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              $event.keyCode !== 13
                            )
                              return null
                            return _vm.selectPortfolio(0)
                          },
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.setSearchBar(true)
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchTerm = $event.target.value
                          },
                        },
                      }),
                      _vm._m(1),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm.portfolios && _vm.portfolios.length > 0
            ? _c(
                "div",
                { staticClass: "kt-widget4 p-3" },
                _vm._l(_vm.portfolios, function (portfolio, idx) {
                  return _c(
                    "div",
                    {
                      key: `${idx}_portfolio`,
                      staticClass: "kt-widget4__item",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.selectPortfolio(idx)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "kt-widget4__pic kt-widget4__pic--pic" },
                        [
                          portfolio.role == "Student" && _vm.encryptionEnabled
                            ? _c("StudentAvatar", {
                                staticStyle: { width: "35px", height: "35px" },
                                attrs: { student: portfolio },
                              })
                            : _c(
                                "UserAvatar",
                                { attrs: { "avatar-user": portfolio } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--lg kt-badge--success",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            portfolio.lastName.substring(0, 1)
                                          ) +
                                          _vm._s(
                                            portfolio.firstName.substring(0, 1)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                      portfolio.role == "Student"
                        ? [
                            !_vm.encryptionEnabled
                              ? _c(
                                  "div",
                                  { staticClass: "kt-widget4__info" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "kt-widget4__username",
                                        attrs: {
                                          to: {
                                            name: "StudentInfo",
                                            params: {
                                              studentEnrollmentId:
                                                portfolio.studentEnrollmentId,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(portfolio.lastName) +
                                            ", " +
                                            _vm._s(portfolio.firstName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-muted text-truncate",
                                        staticStyle: { "max-width": "150px" },
                                      },
                                      [
                                        portfolio.extStudentId.length == 9
                                          ? [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    portfolio.extStudentId.substring(
                                                      0,
                                                      3
                                                    )
                                                  ) +
                                                  "- " +
                                                  _vm._s(
                                                    portfolio.extStudentId.substring(
                                                      3,
                                                      6
                                                    )
                                                  ) +
                                                  "- " +
                                                  _vm._s(
                                                    portfolio.extStudentId.substring(
                                                      6,
                                                      9
                                                    )
                                                  ) +
                                                  " " +
                                                  _vm._s(portfolio.homeRoom) +
                                                  " "
                                              ),
                                            ]
                                          : [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    portfolio.extStudentId
                                                  ) +
                                                  " " +
                                                  _vm._s(portfolio.homeRoom) +
                                                  " "
                                              ),
                                            ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "kt-widget4__info" },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "kt-widget4__username",
                                        attrs: {
                                          to: {
                                            name: "StudentInfo",
                                            params: {
                                              studentEnrollmentId:
                                                portfolio.studentEnrollmentId,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              portfolio.maskedStudentName
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-muted text-truncate",
                                        staticStyle: { "max-width": "150px" },
                                      },
                                      [
                                        _vm._v(
                                          " GL: " +
                                            _vm._s(portfolio.gradeLevel) +
                                            " HR: " +
                                            _vm._s(portfolio.homeRoom) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-sm btn-bold btn-label-warning",
                                attrs: {
                                  to: {
                                    name: "StudentInfo",
                                    params: {
                                      studentEnrollmentId:
                                        portfolio.studentEnrollmentId,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "kt-widget4__text m-0" },
                                  [_vm._v(" Student ")]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      portfolio.role == "Teacher"
                        ? [
                            _c(
                              "div",
                              { staticClass: "kt-widget4__info" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "kt-widget4__username",
                                    attrs: {
                                      to: {
                                        name: "TeacherInfo",
                                        params: {
                                          schoolEmail: portfolio.schoolEmail,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(portfolio.lastName) +
                                        ", " +
                                        _vm._s(portfolio.firstName) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-muted text-truncate",
                                    staticStyle: { "max-width": "150px" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(portfolio.schoolEmail) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-sm btn-bold btn-label-brand",
                                attrs: {
                                  to: {
                                    name: "TeacherInfo",
                                    params: {
                                      schoolEmail: portfolio.schoolEmail,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "kt-widget4__text m-0" },
                                  [_vm._v(" Teacher ")]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      portfolio.role == "Guardian"
                        ? [
                            _c(
                              "div",
                              { staticClass: "kt-widget4__info" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "kt-widget4__username",
                                    attrs: {
                                      to: {
                                        name: "StudentGuardians",
                                        params: {
                                          studentEnrollmentId:
                                            portfolio.studentEnrollmentId,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(portfolio.lastName) +
                                        ", " +
                                        _vm._s(portfolio.firstName) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-muted text-truncate",
                                    staticStyle: { "max-width": "150px" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          `${portfolio.relationToStudent} of ${portfolio.lastName}`
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-sm btn-bold btn-label-success",
                                attrs: {
                                  to: {
                                    name: "StudentGuardians",
                                    params: {
                                      studentEnrollmentId:
                                        portfolio.studentEnrollmentId,
                                    },
                                  },
                                },
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "kt-widget4__text m-0" },
                                  [_vm._v(" Guardian ")]
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "flaticon2-search-1" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la la-close kt-quick-search__close" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }