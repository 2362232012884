<template>
<div>
    <div
        id="kt_aside"
        class="kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
        :class="{ 'kt-aside--on': show && showMobileMenu }"
        @mouseover="hovering = true"
        @mouseout="hovering = false"
    >
        <!-- Top Left Logo In Black -->
        <div class="kt-aside__brand kt-grid__item">
            <div class="kt-aside__brand-logo">
                <router-link :to="{ name: 'Home' }">
                    <SVGIcon
                        :name="'syncgrades'"
                        :class="showAside ? 'ml-3' : 'mr-3'"
                    />
                    <img
                        v-if="!showAside"
                        alt="SyncGrades"
                        src="/logos/logo-text-dark.svg"
                        style="width: 128px"
                    >
                </router-link>
            </div>
            <div class="kt-aside__brand-tools">
                <button
                    id="kt_aside_toggler"
                    class="kt-aside__brand-aside-toggler"
                    :class="{ 'kt-aside__brand-aside-toggler--active': show }"
                    @click.stop.prevent="setStickyAsideMenu('toggle')"
                >
                    <span>
                        <SVGIcon name="leftNavigationDoubleArrowLeft" />
                    </span>
                    <span>
                        <SVGIcon name="leftNavigationDoubleArrowRight" />
                    </span>
                </button>

                <button class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left">
                    <span />
                </button>
            </div>
        </div>

        <div
            id="kt_aside_menu_wrapper"
            class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        >
            <perfect-scrollbar
                ref="scroll"
                style="height: 100vh; position: relative;"
            >
                <div
                    id="kt_aside_menu"
                    :class="{ 'kt-aside--on': !show }"
                    class="kt-aside-menu"
                >
                    <template v-if="databaseIsReady">
                        <TheSchoolUserMenu v-if="$_userMixins_isSchoolUser" />
                        <TheHomeUserMenu v-if="$_userMixins_isHomeUser" />
                    </template>
                </div>
            </perfect-scrollbar>
        </div>
    </div>

    <div
        v-if="show && showMobileMenu"
        class="kt-aside-overlay"
        @click.stop.prevent="setMobileMenu('toggle')"
    />
</div>
</template>

<script lang="ts">

import { mapState, mapMutations } from 'vuex';
import TheSchoolUserMenu from './TheSchoolUserMenu.vue';
import TheHomeUserMenu from './TheHomeUserMenu.vue';
import userMixins from '../../../../store/mixins/userMixins';

export default {
    name: 'TheAsideMenu',
    components: {
        TheSchoolUserMenu,
        TheHomeUserMenu,
    },
    mixins: [userMixins],
    data() {
        return {
            show: true,
            hovering: false,
            canHover: false,
        };
    },
    computed: {
        ...mapState({
            databaseIsReady: (state) => state.database.loadState === 'READY',
            asideMaximized: (state) => state.navigation.asideMaximized,
            showMobileMenu: (state) => state.navigation.showMobileMenu,
            showAside: (state) => state.navigation.showAside,
            user: (state) => state.user,
            hasCourses: (state) => state.user.school.hasCourses,
            role: (state) => state.user.school.role,
            hideAllNavigation: (state) => state.navigation.hideAllNavigation,
        }),
    },
    watch: {
        $route() {
            this.scrollToSection();
        },
        asideMaximized(enabled) {
            const v = this;
            if (enabled) {
                setTimeout(() => {
                    v.canHover = true;
                }, 1200);
            } else {
                v.canHover = false;
            }
        },
        hovering(val) {
            const v = this;
            if (!v.asideMenuEnabled) return;
            if (v.asideMenuEnabled && v.asideMaximized && v.canHover) {
                if (val) {
                    document.body.classList.remove('kt-aside--minimize');
                    document.body.classList.add('kt-aside--minimize-hover');
                } else {
                    document.body.classList.add('kt-aside--minimize');
                    document.body.classList.remove('kt-aside--minimize-hover');
                }
            }
        },
    },
    mounted() {
        const v = this;
        v.canHover = v.asideMaximized;
        setTimeout(() => {
            v.scrollToSection();
        }, 10);
    },
    methods: {
        ...mapMutations([
            'setStickyAsideMenu',
            'setMobileMenu',
        ]),
        scrollToSection() {
            const { scroll } = this.$refs;
            if (!scroll) return;
            setTimeout(() => {
                if (window.location.href.indexOf('/configuration/') > -1) {
                    scroll.$el.scrollTop = 0; // scrolling to bottom would be good here
                } else {
                    scroll.$el.scrollTop = 0;
                }
            }, 15);
        },
    },
};

</script>

<style scoped>
div#pscroll {
    height: 100vh;
    position: relative;
}

div#kt_aside_menu {
    overflow-x: hidden;
    max-width: 265px;
    padding-bottom: 120px;
}

div#kt_aside_menu_wrapper {
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;
}
div.kt_aside {
    margin-top: 0px;
}

.hovering span, a.hovering i {
    display: flex !important;
}

.kt-menu__link.hovering,
.kt-menu__submenu.show {
    display: flex !important;
}
</style>

<style scoped>
.menu-subtitle {
    width: 100%;
    display: block;
    margin-left: 1rem;
}

span.kt-menu__link-text {
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
}

li.kt-menu__text>a {
    white-space: nowrap;
    height: 22px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

a.kt-menu__link {
    white-space: nowrap;
}

div.submenu-hidden {
    display: none !important;
    overflow: hidden;
}

div.submenu-shown {
    display: flex !important;
}
</style>
