<template>
<b-modal
    ref="cat-change-modal"
    v-model="showModal"
    size="xl"
    no-close-on-backdrop
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title kt-font-danger">
            {{ extCourseSectionId }} - Grade Category Reassignments
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>

    <template #default>
        <div class="modal-body">
            <div class="form-group form-group-last pt-3 pb-5">
                <div
                    class="alert alert-secondary"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-danger kt-font-brand" />
                    </div>
                    <div class="alert-text">
                        The Grade Template Categories for <code>{{ extCourseSectionId }}</code> have been modified by an administrator.
                        To ensure accuracy, please map each old category to the new category and save.
                    </div>
                </div>
            </div>
            <div
                v-for="item in changes"
                :key="`unknown_${item.gradeTemplateCategoryId}`"
                class="form-group row px-5"
            >
                <div class="col-md-6">
                    <label>{{ item.gradeTemplateName }}</label>
                    <input
                        type="text"
                        class="form-control col-md-10"
                        disabled
                        :value="`${item.gradeTemplateCategoryName}`"
                    >
                </div>

                <div class="col-md-6">
                    <label>New Template: {{ gradeTemplate.gradeTemplateName }}</label>
                    <br>
                    <select
                        v-model="item.newGradeTemplateCategoryId"
                        class="custom-select form-control col-md-10"
                    >
                        <option
                            v-for="c in gradeTemplateCategories"
                            :key="`option_c_${c.gradeTemplateCategoryId}`"
                            :value="c.gradeTemplateCategoryId"
                        >
                            {{ c.categoryName }}
                        </option>
                    </select>
                    <span
                        v-if="!item.newGradeTemplateCategoryId"
                        class="form-text text-muted kt-font-danger"
                    >Select a new Grading Category</span>
                </div>

                <div class="kt-separator kt-separator--space-md kt-separator--border-dashed" />
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.stop.prevent="ignore"
        >
            Ignore For Now
        </button>
        <button
            type="button"
            class="btn btn-primary alignment pull-right"
            data-dismiss="modal"
            :disabled="saving || !isValid"
            @click.stop.prevent="save"
        >
            Save Changes
        </button>
    </template>
</b-modal>
</template>

<script>
import { getPortfolioCacheKey } from '../store/mixins/portfolioMixins';
import courseMixins from '../store/mixins/courseMixins';
import { getCourseWork } from '../store/mixins/courseWorkMixins';
import * as network from '../network';
import Types from '../store/Types';

export default {
    name: 'GlobalTemplateCategoryChangeModal',
    mixins: [courseMixins],
    data() {
        return {
            saving: false,
            show: false,
            changes: [],
        };
    },
    computed: {
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        gradeTemplate() {
            if (!this.course) return null;
            return this.course.gradeTemplate;
        },
        gradeTemplates() {
            return this.$store.state.database.gradeTemplates;
        },
        courseWork() {
            if (!this.course) return [];
            const { database } = this.$store.state;
            const courseWork = getCourseWork(database, this.course);
            return courseWork.filter((a) => !a.deleted);
        },
        isValid() {
            if (this.changes.length == 0) return false;
            const every = this.changes.every((c) => c.newGradeTemplateCategoryId);
            return every;
        },
        unknownCategories() {
            const { courseWorkCategories, gradeTemplateCategories } = this;
            const { gradeTemplates, gradeTemplate } = this;
            return courseWorkCategories.filter((c) => {
                const exists = gradeTemplateCategories.find((x) => (
                    x.gradeTemplateCategoryId == c.gradeTemplateCategoryId
                    && x.gradeTemplateId == gradeTemplate.gradeTemplateId
                ));
                return !exists;
            }).map((c) => {
                const origTemplate = gradeTemplates.find((t) => t.gradeTemplateId == c.gradeTemplateId);
                const category = !origTemplate ? null : origTemplate.categories.find((x) => c.gradeTemplateCategoryId == x.gradeTemplateCategoryId) || null;
                return {
                    gradeTemplateCategoryId: c.gradeTemplateCategoryId,
                    gradeTemplateId: c.gradeTemplateId,
                    gradeTemplateName: origTemplate ? origTemplate.gradeTemplateName : 'Unknown',
                    gradeTemplateCategoryName: category ? category.categoryName : 'Unknown',
                    newGradeTemplateCategoryId: null,
                };
            });
        },
        courseWorkCategories() {
            const map = new Map();
            this.courseWork.forEach((cw) => {
                const { gradeTemplateCategoryId, gradeTemplateId, courseSectionId } = cw;

                if (gradeTemplateCategoryId && (courseSectionId == this.course.courseSectionId)) {
                    map.set(`${gradeTemplateCategoryId}_${gradeTemplateId}`, { gradeTemplateCategoryId, gradeTemplateId });
                }
            });
            return [...map.values()];
        },
        gradeTemplateCategories() {
            if (!this.gradeTemplate) return [];
            return this.gradeTemplate.categories;
        },
        isPortfolioReady() {
            const cacheKey = getPortfolioCacheKey(this.$route, this.$store.state.database);
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        showModal: {
            get() {
                return this.show;
            },
            set(val) {
                this.show = val;
            },
        },
    },
    watch: {
        $route() {
            this.populate();
        },
        isPortfolioReady() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        ignore() {
            this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, true);
            setTimeout(function () {
                this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, false);
            }, 1000 * 60 * 15); // 15 mins
            this.showModal = false;
        },
        populate() {
            const { schoolEmail, extCourseSectionId } = this.$route.params;
            const isPortfolioView = Boolean(schoolEmail && extCourseSectionId);
            if (!isPortfolioView) return false;
            if (!this.course) return false;

            const { isPortfolioReady, unknownCategories } = this;
            if (!isPortfolioReady) return false;
            this.changes = unknownCategories.map((x) => ({ ...x }));
            this.showModal = this.changes.length > 0;
        },
        save() {
            const v = this;
            const { showError, saving } = this;
            const { courseSectionId } = this.course;

            if (saving) return;
            this.saving = true;
            const gradeTemplateCategories = this.changes.map((c) => ({
                courseSectionId,
                oldGradeTemplateCategoryId: c.gradeTemplateCategoryId,
                newGradeTemplateCategoryId: c.newGradeTemplateCategoryId,
            }));
            const { schoolId, schoolTermId, schoolStaffId } = this.$store.state.user.school;
            const params = {
                url: { schoolId, schoolTermId, schoolStaffId },
                body: { gradeTemplateCategories },
            };
            network.courseWork.bulkGradingCategoryChange(params, (err) => {
                if (err) return showError(err);
                v.saving = false;
                window.location.reload();
            });
        },
    },
};
</script>
