<template>
<ul
    id="aside_school_settings"
    class="kt-menu__nav pt-0"
>
    <AsideMenuItemHeader
        v-if="$_userMixins_isSchoolAdmin || !$_userMixins_isGoogleDisabled"
        title="Configuration"
    />

    <template v-if="$_userMixins_isSchoolAdmin">
        <TheSchoolSettingsMenu ref="school-menu" />
        <TheFileManagerMenu ref="file-menu" />
        <TheReportCardMenu ref="rpcd-menu" />
        <TheSetupWizardMenu v-if="finalized" />
    </template>

    <TheGoogleSettingsMenu
        v-if="!$_userMixins_isGoogleDisabled"
        ref="google-menu"
    />
</ul>
</template>

<script lang="ts">
import userMixins from '../../../../store/mixins/userMixins';
import TheFileManagerMenu from './TheFileManagerMenu.vue';
import TheReportCardMenu from './TheReportCardMenu.vue';
import TheGoogleSettingsMenu from './TheGoogleSettingsMenu.vue';
import TheSchoolSettingsMenu from './TheSchoolSettingsMenu.vue';
import TheSetupWizardMenu from './TheSetupWizardMenu.vue';
import AsideMenuItemHeader from './AsideMenuItemHeader.vue';

export default {
    name: 'TheSettingsMenu',
    components: {
        TheGoogleSettingsMenu,
        TheSchoolSettingsMenu,
        TheFileManagerMenu,
        TheReportCardMenu,
        AsideMenuItemHeader,
        TheSetupWizardMenu,
    },
    mixins: [userMixins],
    computed: {
        finalized() {
            return this.$store.state.user.school.finalized;
        },
    },
};
</script>
