var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-header__topbar-item dropdown" }, [
    _vm.$_userMixins_isSchoolUser
      ? _c(
          "div",
          {
            staticClass: "kt-header__topbar-wrapper",
            attrs: {
              "data-toggle": "dropdown",
              "data-offset": "30px,0px",
              "aria-expanded": "true",
            },
          },
          [
            _c(
              "span",
              { staticClass: "kt-header__topbar-icon" },
              [
                _c("SVGIcon", {
                  staticClass: "kt-svg-icon",
                  attrs: { name: "email" },
                }),
              ],
              1
            ),
          ]
        )
      : _c(
          "div",
          {
            staticClass: "kt-header__topbar-wrapper",
            attrs: { "data-toggle": "dropdown", "data-offset": "10px,0px" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.toggleCreateMessage.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "kt-header__topbar-icon" },
              [_c("SVGIcon", { attrs: { name: "email" } })],
              1
            ),
          ]
        ),
    _c(
      "div",
      {
        staticClass:
          "dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl",
        class: { "d-none": _vm.$_userMixins_isHomeUser },
        staticStyle: {
          position: "absolute",
          "will-change": "transform",
          top: "0px",
          left: "0px",
          transform: "translate3d(-306px, 65px, 0px)",
        },
        attrs: { "x-placement": "bottom-end" },
      },
      [
        _c("div", { staticClass: "kt-mycart" }, [
          _c(
            "div",
            {
              staticClass: "kt-mycart__head kt-head",
              staticStyle: { "background-image": "url(/images/bg-1.jpg)" },
            },
            [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "kt-mycart__button" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-success btn-sm",
                      attrs: { to: { name: "Communication" } },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(`${_vm.unReadMessageCount} Unread`) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "kt-mycart__body kt-scroll ps",
              attrs: {
                "data-scroll": "true",
                "data-height": "245",
                "data-mobile-height": "200",
              },
            },
            [
              _c(
                "div",
                { staticClass: "kt-grid-nav kt-grid-nav--skin-light" },
                [
                  _c("div", { staticClass: "kt-grid-nav__row" }, [
                    _vm.$_userMixins_isSchoolAdmin &&
                    (_vm.canMessageSchoolUsers || _vm.canMessageHomeUsers)
                      ? _c(
                          "a",
                          {
                            staticClass: "kt-grid-nav__item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.toggleCreateSchoolAnnouncement.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "kt-grid-nav__icon" },
                              [
                                _c("SVGIcon", {
                                  staticClass:
                                    "kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg",
                                  attrs: { name: "announcement" },
                                }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "kt-grid-nav__desc" }, [
                              _vm._v("New School Announcement"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    (_vm.$_userMixins_isSchoolAdmin ||
                      _vm.$_userMixins_isTeacher) &&
                    (_vm.canMessageSchoolUsers || _vm.canMessageHomeUsers)
                      ? _c(
                          "a",
                          {
                            staticClass: "kt-grid-nav__item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.toggleCreateAnnouncement.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "kt-grid-nav__icon" },
                              [
                                _c("SVGIcon", {
                                  staticClass:
                                    "kt-svg-icon kt-svg-icon--primary kt-svg-icon--lg",
                                  attrs: { name: "email" },
                                }),
                              ],
                              1
                            ),
                            _c("span", { staticClass: "kt-grid-nav__desc" }, [
                              _vm._v("New Course Announcement"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          ),
          _c("div", { staticClass: "kt-mycart__footer" }, [
            _c("div", { staticClass: "kt-mycart__section" }),
            _c(
              "div",
              { staticClass: "kt-mycart__button" },
              [
                _vm.canMessageSchoolUsers || _vm.canMessageHomeUsers
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm mr-4",
                        attrs: { type: "button" },
                        on: { click: _vm.toggleCreateMessage },
                      },
                      [_vm._v(" New Chat ")]
                    )
                  : _vm._e(),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-success btn-sm pull-right1",
                    attrs: { to: { name: "Communication" } },
                  },
                  [_vm._v(" Open Inbox ")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-mycart__info" }, [
      _c("h3", { staticClass: "kt-mycart__title" }, [
        _vm._v(" Communication "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }