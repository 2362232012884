var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.serverSideEncryptionEnabled
    ? _c(
        "div",
        { staticClass: "kt-header__topbar-item" },
        [
          _c(
            "div",
            {
              staticClass: "kt-header__topbar-wrapper",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.toggleDecryption.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "kt-header__topbar-icon" },
                [
                  !_vm.clientSideEncryptionEnabled
                    ? _c("SVGIcon", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.bottom",
                            modifiers: { hover: true, bottom: true },
                          },
                        ],
                        staticClass: "kt-svg-icon",
                        attrs: {
                          name: "unlock",
                          title: "PII is currently viewable, click to encrypt",
                          "hex-color": "#fd397a",
                        },
                      })
                    : _c("SVGIcon", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover.bottom",
                            modifiers: { hover: true, bottom: true },
                          },
                        ],
                        staticClass: "kt-svg-icon",
                        attrs: {
                          name: "lock",
                          title: "PII is currently encrypted, click to decrypt",
                          "hex-color": "#fd397a",
                        },
                      }),
                ],
                1
              ),
            ]
          ),
          _c("SchoolPasswordModal", { ref: "schoolPasswordModal" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }