var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "topSchoolMenuNav" } },
    [
      _c("TheAsideMenuHeader"),
      !_vm.$_userMixins_isDisabled
        ? _c(
            "span",
            _vm._l(_vm.portfolios, function (portfolio, idx) {
              return _c(
                "ul",
                {
                  key: `portfolioViewers_${idx}_${_vm.key}`,
                  staticClass: "kt-menu__nav py-0",
                },
                [
                  idx == 0
                    ? _c("AsideMenuItemHeader", {
                        attrs: { title: "Portfolios" },
                      })
                    : _vm._e(),
                  _c("StudentPortfolioMenu", { attrs: { student: portfolio } }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.$_userMixins_isDisabled
        ? _c(
            "ul",
            { staticClass: "kt-menu__nav pt-3" },
            [
              _c("AsideMenuItemHeader", { attrs: { title: "School" } }),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Communication",
                  "route-name": "Communication",
                  icon: "communication",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }