var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-header__topbar-item kt-header__topbar-item--search dropdown",
      attrs: { title: "Create Anecdotal Log" },
    },
    [
      _c(
        "div",
        {
          staticClass: "kt-header__topbar-wrapper",
          attrs: { "data-toggle": "dropdown", "data-offset": "10px,0px" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.toggle()
            },
          },
        },
        [
          _c(
            "span",
            { staticClass: "kt-header__topbar-icon" },
            [
              _vm.open
                ? _c("SVGIcon", {
                    attrs: { name: "anecdotal", "hex-color": "#FFFFFF" },
                  })
                : _c("SVGIcon", { attrs: { name: "anecdotal" } }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }