var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "kt-menu__item",
      class: {
        "kt-menu__item--here kt-menu__item--active": _vm.open,
        "kt-menu__item--open": _vm.open,
      },
    },
    [
      _c(
        "router-link",
        {
          staticClass: "kt-menu__link",
          attrs: {
            to: { name: "StudentCourseOverview", params: _vm.routeParameters },
          },
        },
        [
          _c("CourseIcon", { attrs: { course: _vm.course, size: "xs" } }),
          _c("span", { staticClass: "kt-menu__link-text ml-2" }, [
            _c("div", { staticClass: "menu-subtitle" }, [
              _vm._v(" " + _vm._s(_vm.course.name) + " "),
            ]),
          ]),
          _c("span", { staticClass: "kt-menu__link-badge" }, [
            _vm.course.nextMeeting.day !== _vm.today
              ? _c(
                  "span",
                  {
                    staticClass:
                      "kt-shape-bg-color-1 kt-label-font-color-2 kt-font-xs px-2 py-1",
                    class: _vm.color,
                    staticStyle: { color: "#fff", "border-radius": "0.4rem" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.course.nextMeeting.day || "").substring(0, 3)
                        ) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }