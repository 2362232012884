<template>
<div v-if="!closed">
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <div class="kt-section my-0 py-0">
                    <div class="kt-section__content">
                        <div class="alert alert-warning fade show">
                            <div class="alert-icon">
                                <button
                                    type="button"
                                    class="btn btn-warning btn-circle btn-icon btn-sm"
                                    @click.stop.prevent="closeAlert"
                                >
                                    <i class="la la-close" />
                                </button>
                            </div>
                            <div class="alert-text">
                                Your SyncGrades Password has not been set. For additional security, please set your password now.
                            </div>
                            <div class="alert-close">
                                <router-link
                                    type="button"
                                    :to="{name: 'Password'}"
                                    class="btn btn-warning btn-bold"
                                >
                                    Set Password
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

export default {
    name: 'PasswordAlert',
    data() {
        return {
            key: 1,
            closed: false,
        };
    },
    computed: {

    },
    methods: {
        closeAlert() {
            this.closed = true;
        },
    },
};
</script>
