<template>
<div
    v-if="showStudentBadgePanel"
    class="modal modal-sticky-bottom-right modal-sticky-lg show"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block"
>
    <div class="modal-dialog">
        <div class="modal-content kt-inbox">
            <StudentBadgeForm />
        </div>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import Types from '../store/Types';
import StudentBadgeForm from './StudentBadgeForm.vue';

export default {
    name: 'AddStudentBadge',
    components: {
        StudentBadgeForm,
    },
    computed: {
        ...mapState({
            showStudentBadgePanel: (state) => state.addStudentBadgePanel.open,
        }),
    },
    methods: {
        closeModal() {
            this.$store.commit(Types.mutations.SET_STUDENT_BADGE_PANEL_CLOSE);
        },
    },
};
</script>
