<template>
<div
    title="Create Anecdotal Log"
    class="kt-header__topbar-item kt-header__topbar-item--search dropdown"
>
    <div
        class="kt-header__topbar-wrapper"
        data-toggle="dropdown"
        data-offset="10px,0px"
        @click.stop.prevent="toggle()"
    >
        <span class="kt-header__topbar-icon">
            <SVGIcon
                v-if="open"
                :name="'anecdotal'"
                :hex-color="'#FFFFFF'"
            />
            <SVGIcon
                v-else
                :name="'anecdotal'"
            />
        </span>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import SVGIcon from '../../../../components/SVGIcon/SVGIcon.vue';
import Types from '../../../../store/Types';

export default {
    name: 'AnecdotalButton',
    components: { SVGIcon },
    computed: {
        ...mapState({
            open: (state) => state.createAnecdotalPanel.open,
        }),
    },
    methods: {
        toggle() {
            if (this.open) {
                this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_CLOSE);
                return;
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
        },
    },
};
</script>

<style>

span.kt-header__topbar-icon--active path {
    fill: #0f88ef !important;
}
</style>
