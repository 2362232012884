<template>
<li :class="['kt-menu__item menu-user-header', headerOpen ? 'kt-menu__item--open' : '' ]">
    <a
        :id="`aside_student_${studentEnrollmentId}`"
        href="#header-toggle-menu"
        class="kt-menu__link pt-2 pb-2"
        @click.stop.prevent="headerOpen = !headerOpen"
    >
        <span
            v-if="$_userMixins_isSchoolUser"
            class="kt-menu__link-icon kt-menu__link-icon-close"
            @click.stop.prevent="removePortfolio"
        >
            <i class="la la-close" />
        </span>
        <span
            v-if="!clientSideEncryptionEnabled"
            class="kt-menu__link-icon"
            :class="$_userMixins_isSchoolUser ? 'kt-menu__link-icon-avatar' : ''"
        >
            <UserAvatar
                :rounded="true"
                :avatar-user="student"
                class="kt-media kt-media--sm--sidebar"
            >
                <div class="kt-media kt-media--sm">
                    <a
                        style="width: 1.75rem;"
                        class="kt-media kt-media--lg kt-media--primary"
                    >
                        <span style="height: 1.75rem; font-size: .75rem;">
                            {{ initials }}
                        </span>
                    </a>
                </div>
            </UserAvatar>
        </span>
        <span
            v-else
            class="kt-menu__link-icon"
        >
            <div class="kt-media kt-media--sm">
                <a
                    style="width: 1.75rem;"
                    class="kt-media kt-media--lg kt-media--primary"
                >
                    <span style="height: 1.75rem; font-size: .75rem;">
                        {{ initials }}
                    </span>
                </a>
            </div>
        </span>

        <span class="kt-menu__link-text">
            {{ title }}
        </span>

        <i class="kt-menu__ver-arrow la la-angle-right" />
    </a>
    <b-collapse
        v-model="headerOpen"
        class="kt-menu__submenu"
    >
        <span class="kt-menu__arrow" />
        <ul class="kt-menu__subnav">
            <AsideMenuItem
                title="Student Profile"
                route-name="StudentInfo"
                icon="info"
                :route-params="{studentEnrollmentId}"
            />
            <AsideMenuItem
                title="Communication"
                route-name="StudentCommunication"
                icon="communication"
                :route-params="{studentEnrollmentId}"
            />
            <AsideMenuItem
                title="Attendance"
                route-name="StudentAttendance"
                icon="attendance"
                :route-params="{studentEnrollmentId}"
            />
            <AsideMenuItem
                title="Badges"
                route-name="StudentBadges"
                icon="badges"
                :route-params="{studentEnrollmentId}"
            />
            <AsideMenuItem
                title="Student Avatar"
                route-name="StudentAvatarEditor"
                icon="user"
                :route-params="{studentEnrollmentId}"
            />
            <AsideMenuItem
                title="Anecdotals"
                route-name="StudentAnecdotals"
                icon="anecdotal"
                :route-params="{studentEnrollmentId}"
            />
            <AsideMenuItem
                title="Score Card"
                route-name="StudentEvaluation"
                icon="evaluation"
                :route-params="{studentEnrollmentId}"
            />
            <AsideMenuItem
                v-if="isEnabledGuardiansOrStudent"
                title="Guardians"
                route-name="StudentGuardians"
                icon="guardians"
                :route-params="{studentEnrollmentId}"
            />

            <AsideMenuItem
                title="Reports"
                route-name="StudentReports"
                icon="progressReports"
                :route-params="{studentEnrollmentId}"
            />

            <StudentPortfolioClassMenu ref="course-menu">
                <StudentPortfolioMenuCourse
                    v-for="(course, idx) in courses"
                    :key="`student_port_route_${idx}`"
                    class="kt-menu__item"
                    :course="course"
                    :student="student"
                />
            </StudentPortfolioClassMenu>
        </ul>
    </b-collapse>
</li>
</template>

<script lang="ts">

import AsideMenuItem from './AsideMenuItem.vue';
import UserAvatar from '../../../../components/UserAvatar.vue';
import globalMixins from '../../../../store/mixins/globalMixins';
import userMixins from '../../../../store/mixins/userMixins';
import StudentPortfolioMenuCourse from './StudentPortfolioMenuCourse.vue';
import StudentPortfolioClassMenu from './StudentPortfolioClassMenu.vue';
import courseMixins from '../../../../store/mixins/courseMixins';
import Types from '../../../../store/Types';

export default {
    name: 'StudentPortfolioMenu',
    components: {
        UserAvatar,
        AsideMenuItem,
        StudentPortfolioMenuCourse,
        StudentPortfolioClassMenu,
    },
    mixins: [globalMixins, userMixins, courseMixins],
    props: {
        student: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            headerOpen: false,
            key: 0,
        };
    },
    computed: {
        isActiveRoute() {
            return this.$route.params.studentEnrollmentId == this.student.studentEnrollmentId && this.$route.fullPath.indexOf('/students/') > -1;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        clientSideEncryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        studentEnrollmentId() {
            return this.student.studentEnrollmentId;
        },
        initials() {
            const { student, clientSideEncryptionEnabled } = this;
            if (clientSideEncryptionEnabled) {
                return student.maskedStudentName.substring(0, 2);
            }
            return `${student.lastName.substring(0, 2)}`;
        },
        courses() {
            const courses = this.$_courseMixins_getCoursesForStudentEnrollmentId(this.studentEnrollmentId);
            return courses.sort((a, b) => (`${a.periodSort}` > `${b.periodSort}` ? 1 : -1));
        },
        title() {
            const { student, encryptionEnabled } = this;
            if (encryptionEnabled) {
                return student.maskedStudentName;
            }
            return `${student.lastName}, ${student.firstName}`;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        isEnabledGuardiansOrStudent() {
            return !this.forceStudentView && !this.$_userMixins_isHomeUser;
        },
    },
    watch: {
        extCourseSectionId() {
            this.init();
        },
        isActiveRoute() {
            this.init();
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        closeCourseMenu() {
            this.$refs['course-menu'].setOpen(false);
        },
        init() {
            const { isActiveRoute, extCourseSectionId } = this;
            this.headerOpen = isActiveRoute;
            if (isActiveRoute || extCourseSectionId) {
                this.$refs['course-menu'].setOpen(true);
            } else {
                this.$refs['course-menu'].setOpen(false);
            }
        },
        removePortfolio() {
            this.$store.commit(Types.mutations.REMOVE_PORTFOLIO_HISTORY, {
                type: 'Student',
                studentEnrollmentId: this.studentEnrollmentId,
            });
        },
    },
};
</script>

<style scoped>
img.kt-media--sm--sidebar {
    width: 1.75rem !important;
    max-height: 2rem;
}

span.kt-menu__link-icon-close {
    display: none !important;
}
span.kt-menu__link-icon-close:hover {
    color: rgba(253, 57, 122, 0.8) !important
}

li.menu-user-header a.kt-menu__link:hover span.kt-menu__link-icon-close {
    display: flex !important;
    width: 35px !important;
}
span.kt-menu__link-icon-avatar {
    display: flex !important;
}
li.menu-user-header a.kt-menu__link:hover span.kt-menu__link-icon-avatar {
    display: none !important;
}

</style>
