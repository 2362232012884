var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: [
        "kt-menu__item menu-user-header",
        _vm.headerOpen ? "kt-menu__item--open" : "",
      ],
    },
    [
      _c(
        "a",
        {
          staticClass: "kt-menu__link pt-2 pb-2",
          attrs: {
            id: `aside_teacher_${_vm.schoolEmail}`,
            href: "#header-toggle-menu",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.headerOpen = !_vm.headerOpen
            },
          },
        },
        [
          _vm.$_userMixins_schoolEmail != _vm.schoolEmail
            ? _c(
                "span",
                {
                  staticClass: "kt-menu__link-icon kt-menu__link-icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.removePortfolio.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "la la-close" })]
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "kt-menu__link-icon",
              class:
                _vm.$_userMixins_schoolEmail != _vm.schoolEmail
                  ? "kt-menu__link-icon-avatar"
                  : "",
            },
            [
              _c(
                "UserAvatar",
                {
                  staticClass: "kt-media kt-media--sm--sidebar",
                  attrs: { rounded: true, "avatar-user": _vm.teacher },
                },
                [
                  _c("div", { staticClass: "kt-media kt-media--sm" }, [
                    _c(
                      "a",
                      {
                        staticClass: "kt-media kt-media--lg kt-media--primary",
                        staticStyle: { width: "1.75rem" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              height: "1.75rem",
                              "font-size": ".75rem",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("span", { staticClass: "kt-menu__link-text" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c("i", { staticClass: "kt-menu__ver-arrow la la-angle-right" }),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "kt-menu__submenu",
          model: {
            value: _vm.headerOpen,
            callback: function ($$v) {
              _vm.headerOpen = $$v
            },
            expression: "headerOpen",
          },
        },
        [
          _c("span", { staticClass: "kt-menu__arrow" }),
          _c(
            "ul",
            { staticClass: "kt-menu__subnav" },
            [
              _c("AsideMenuItem", {
                attrs: {
                  title: "Teacher Profile",
                  "route-name": "TeacherInfo",
                  icon: "info",
                  "route-params": { schoolEmail: _vm.schoolEmail },
                },
              }),
              _c(
                "TeacherPortfolioClassMenu",
                { ref: "course-menu" },
                _vm._l(_vm.courses, function (course, idx) {
                  return _c("TeacherPortfolioMenuCourse", {
                    key: `teacher_port_route_${idx}_${_vm.key}`,
                    staticClass: "kt-menu__item",
                    attrs: { course: course, teacher: _vm.teacher },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }