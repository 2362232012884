var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.closed
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            _c("div", { staticClass: "kt-portlet" }, [
              _c("div", { staticClass: "kt-portlet__body" }, [
                _c("div", { staticClass: "kt-section my-0 py-0" }, [
                  _c("div", { staticClass: "kt-section__content" }, [
                    _c(
                      "div",
                      { staticClass: "alert alert-warning fade show" },
                      [
                        _c("div", { staticClass: "alert-icon" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-warning btn-circle btn-icon btn-sm",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.closeAlert.apply(null, arguments)
                                },
                              },
                            },
                            [_c("i", { staticClass: "la la-close" })]
                          ),
                        ]),
                        _c("div", { staticClass: "alert-text" }, [
                          _vm._v(
                            " Your SyncGrades Password has not been set. For additional security, please set your password now. "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "alert-close" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-warning btn-bold",
                                attrs: {
                                  type: "button",
                                  to: { name: "Password" },
                                },
                              },
                              [_vm._v(" Set Password ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }