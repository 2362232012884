var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showCreateMessage,
          expression: "showCreateMessage",
        },
      ],
      ref: "messageContainer",
      staticClass: "message-container",
    },
    [
      _c(
        "div",
        {
          staticClass: "modal modal-sticky-bottom-right modal-sticky-lg show",
          staticStyle: {
            display: "block",
            overflow: "visible",
            position: "relative",
            width: "36vw",
          },
          attrs: {
            id: "kt_inbox_compose",
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                variant: "transparent",
                "no-center": "",
                opacity: "1",
                blur: "10px",
                show: _vm.encryptionEnabled,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "pull-right mr-2 mt-2 kt-font-lg" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-icon btn-clean",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.closeModal.apply(null, arguments)
                                },
                              },
                            },
                            [_c("i", { staticClass: "flaticon2-cross" })]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "text-center pt-4 kt-font-lg" },
                        [
                          _vm._v(
                            " PII is currently encrypted. Please unlock PII to send a message. "
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "text-center pt-4" }, [
                        _vm.encryptionEnabled
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-brand btn-bold btn-upper mt-4",
                                staticStyle: { "min-width": "160px" },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.decrypt()
                                  },
                                },
                              },
                              [_vm._v(" Unlock PII ")]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "modal-dialog", attrs: { role: "document" } },
                [
                  _c("div", { staticClass: "modal-content kt-inbox" }, [
                    _c(
                      "div",
                      {
                        staticClass: "kt-inbox__form",
                        attrs: { id: "kt_inbox_compose_form" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "kt-inbox__head",
                            on: { mousedown: _vm.dragMouseDown },
                          },
                          [
                            _c("div", { staticClass: "kt-inbox__title" }, [
                              _vm._v(" Compose Message "),
                            ]),
                            _c("div", { staticClass: "kt-inbox__actions" }, [
                              _vm.hasContent || _vm.files.length
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          value: "Discard message",
                                          expression: "'Discard message'",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      staticClass:
                                        "kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.clearMessage.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "flaticon2-rubbish-bin-delete-button",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-sm btn-icon btn-clean btn-icon-md",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.toggleSize.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    class: [
                                      "la",
                                      _vm.minimized
                                        ? "la-angle-up"
                                        : "la-angle-down",
                                    ],
                                  }),
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "kt-inbox__icon kt-inbox__icon--md kt-inbox__icon--light",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.closeModal.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "flaticon2-cross" })]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "kt-inbox__body",
                            class: [_vm.minimized ? "d-none" : ""],
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c("div", { staticClass: "kt-inbox__to" }, [
                              _c("div", { staticClass: "kt-inbox__wrapper" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kt-inbox__field kt-inbox__field--to",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "kt-inbox__label" },
                                      [_vm._v(" To: ")]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "kt-inbox__input" },
                                      [
                                        _vm._l(
                                          _vm.newMessage.recipients,
                                          function (recipient, idx) {
                                            return _c(
                                              "span",
                                              {
                                                key: `recipient_${idx}`,
                                                staticClass:
                                                  "kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded mx-1",
                                                attrs: {
                                                  id: `recipient_${idx}-popover`,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(recipient.name) +
                                                    " "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "ml-2",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.removeRecipient(
                                                          recipient
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "la la-close",
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _c("SearchRecipients", {
                                          attrs: {
                                            "on-selected": _vm.recipientAdded,
                                            "school-staff-id":
                                              _vm.schoolStaffId,
                                            "allow-school-wide": false,
                                            "allow-my-students": false,
                                            "show-courses": false,
                                            "show-home-rooms": false,
                                            "show-grade-levels": false,
                                            "show-teachers": _vm.schoolStaffId
                                              ? _vm.userPermissions &&
                                                _vm.userPermissions
                                                  .canMessageSchoolUsers
                                              : true,
                                            "show-student-groups": false,
                                            "show-students":
                                              Boolean(_vm.schoolStaffId) &&
                                              _vm.userPermissions &&
                                              _vm.userPermissions
                                                .canMessageHomeUsers,
                                            "show-guardians":
                                              Boolean(_vm.schoolStaffId) &&
                                              _vm.userPermissions &&
                                              _vm.userPermissions
                                                .canMessageHomeUsers,
                                            "show-styling": false,
                                            placeholder:
                                              "Search for recipients...",
                                          },
                                        }),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "kt-inbox__subject ql-toolbar ql-snow",
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.newMessage.subject,
                                      expression: "newMessage.subject",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: { placeholder: "Subject" },
                                  domProps: { value: _vm.newMessage.subject },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.newMessage,
                                        "subject",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "kt-inbox__editor ql-container ql-snow px-3 pt-3",
                                staticStyle: { height: "34vh" },
                                attrs: { id: "kt_inbox_compose_editor" },
                              },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.newMessage.body,
                                      expression: "newMessage.body",
                                    },
                                  ],
                                  staticClass: "w-100 h-100",
                                  staticStyle: {
                                    border: "none !important",
                                    outline: "none !important",
                                    resize: "none !important",
                                  },
                                  style: [
                                    _vm.files.length
                                      ? {
                                          paddingBottom: `${
                                            _vm.files.length * 40
                                          }px`,
                                        }
                                      : {},
                                  ],
                                  attrs: { placeholder: "Type message..." },
                                  domProps: { value: _vm.newMessage.body },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.newMessage,
                                        "body",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "w-100 px-3",
                                staticStyle: {
                                  position: "absolute",
                                  bottom: "5px",
                                },
                              },
                              _vm._l(_vm.files, function (file, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: `attachedFile_${idx}`,
                                    staticClass:
                                      "attached-file px-3 py-1 my-2 d-flex align-items-center",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-link kt-link--state kt-link--primary kt-font-bolder no-wrap",
                                      },
                                      [_vm._v(" " + _vm._s(file.name) + " ")]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "kt-link kt-link--state kt-link--danger kt-font-bolder ml-auto",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            _vm.files = _vm.files.filter(
                                              (f, i) => i !== idx
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "flaticon2-cross",
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "kt-inbox__foot",
                            class: [_vm.minimized ? "d-none" : ""],
                          },
                          [
                            _c("div", { staticClass: "kt-inbox__primary" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-brand btn-bold mr-3",
                                  class: {
                                    "kt-spinner  kt-spinner--sm kt-spinner--light":
                                      _vm.saving,
                                  },
                                  attrs: {
                                    type: "button",
                                    disabled: !_vm.canSend || _vm.saving,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.sendMessage.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Send ")]
                              ),
                              _vm.newMessage.smsEnabled && _vm.canToggleSMS
                                ? _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName: "v-b-tooltip.hover",
                                          value:
                                            _vm.characterCount > 2400
                                              ? "Any text past 2400 characters will not be sent with SMS"
                                              : _vm.characterCount > 1200
                                              ? "SMS will be sent as 2 separate messages"
                                              : "",
                                          expression:
                                            "characterCount > 2400 ? 'Any text past 2400 characters will not be sent with SMS' : characterCount > 1200 ? 'SMS will be sent as 2 separate messages' : ''",
                                          modifiers: { hover: true },
                                        },
                                      ],
                                      class: {
                                        "kt-font-warning kt-font-bolder":
                                          _vm.characterCount > 1200 &&
                                          _vm.characterCount <= 2400,
                                        "kt-font-danger kt-font-bolder":
                                          _vm.characterCount > 2400,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.characterCount) +
                                          " / 2400 characters for SMS "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "kt-inbox__secondary" }, [
                              _c(
                                "button",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      value:
                                        "Allow recipients to reply to message",
                                      expression:
                                        "'Allow recipients to reply to message'",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "btn btn-bold btn-icon mx-1",
                                  class: {
                                    "btn-outline-success":
                                      !_vm.newMessage.allowReplies,
                                    "btn-success": _vm.newMessage.allowReplies,
                                  },
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.$_userMixins_isHomeUser,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      _vm.newMessage.allowReplies =
                                        !_vm.newMessage.allowReplies
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-reply" })]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      value: _vm.subscription
                                        ? _vm.subscription.emailQuota !==
                                            null &&
                                          _vm.subscription.emailUsage >=
                                            _vm.subscription.emailQuota
                                          ? "Your school's email limit has been exceeded"
                                          : "Notify email of recipients"
                                        : "Notify email of recipients",
                                      expression:
                                        "subscription ? subscription.emailQuota !== null && (subscription.emailUsage >= subscription.emailQuota) ? 'Your school\\'s email limit has been exceeded' : 'Notify email of recipients' : 'Notify email of recipients'",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-bold btn-icon mx-1",
                                      class: {
                                        "btn-outline-success":
                                          !_vm.newMessage.notifyEmail,
                                        "btn-success":
                                          _vm.newMessage.notifyEmail,
                                      },
                                      attrs: {
                                        type: "button",
                                        disabled: _vm.subscription
                                          ? _vm.subscription.emailQuota !==
                                              null &&
                                            _vm.subscription.emailUsage >=
                                              _vm.subscription.emailQuota
                                          : false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm.newMessage.notifyEmail =
                                            !_vm.newMessage.notifyEmail
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "flaticon2-new-email",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      value: _vm.subscription
                                        ? _vm.subscription.smsQuota !== null &&
                                          _vm.subscription.smsUsage >=
                                            _vm.subscription.smsQuota
                                          ? "Your school's SMS limit has been exceeded"
                                          : "Send SMS to recipients"
                                        : "Send SMS to recipients",
                                      expression:
                                        "subscription ? subscription.smsQuota !== null && (subscription.smsUsage >= subscription.smsQuota) ? 'Your school\\'s SMS limit has been exceeded' : 'Send SMS to recipients' : 'Send SMS to recipients'",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                },
                                [
                                  _vm.canToggleSMS
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-bold btn-icon mx-1",
                                          class: {
                                            "btn-outline-warning":
                                              !_vm.newMessage.smsEnabled,
                                            "btn-warning":
                                              _vm.newMessage.smsEnabled,
                                          },
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.subscription
                                              ? _vm.subscription.smsQuota !==
                                                  null &&
                                                _vm.subscription.smsUsage >=
                                                  _vm.subscription.smsQuota
                                              : false,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              _vm.newMessage.smsEnabled =
                                                !_vm.newMessage.smsEnabled
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-mobile-alt",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c("div", [
                                _c("input", {
                                  staticStyle: { display: "none" },
                                  attrs: {
                                    id: "file-input",
                                    type: "file",
                                    name: "file",
                                  },
                                  on: { change: _vm.onFileBrowseSelect },
                                }),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover",
                                        value:
                                          "Attach up to 3 files to message",
                                        expression:
                                          "'Attach up to 3 files to message'",
                                        modifiers: { hover: true },
                                      },
                                    ],
                                    staticClass:
                                      "btn btn-bold btn-icon btn-clean btn-clean-dark mx-1",
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.files.length >= 3,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.triggerFileBrowser.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-paperclip" })]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }