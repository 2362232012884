<template>
<div id="topSchoolMenuNav">
    <!-- School / User Label -->
    <TheAsideMenuHeader />

    <!-- Setup Wizard -->
    <ul v-if="!finalized" class="kt-menu__nav pt-0">
        <TheSetupWizardMenu />
    </ul>
    <template v-if="finalized || hideWizard">
        <!-- My Classroom Menu -->
        <ul
            v-if="!$_userMixins_isDisabled && $_userMixins_isTeacher"
            class="kt-menu__nav pt-0"
        >
            <AsideMenuItemHeader title="My Classroom" />

            <TeacherPortfolioMenu :teacher="teacher" />
        </ul>

        <!-- Portfolio Viewer -->
        <ul
            v-for="(portfolio, idx) in portfolios"
            :key="`portfolioViewers_${idx}_${key}`"
            class="kt-menu__nav py-0"
        >
            <AsideMenuItemHeader
                v-if="idx == 0"
                title="Portfolios"
            />

            <TeacherPortfolioMenu
                v-if="portfolio['schoolStaffId']"
                :ref="`teacher_port_${portfolio.schoolStaffId}`"
                :teacher="portfolio"
            />
            <StudentPortfolioMenu
                v-if="portfolio['studentEnrollmentId']"
                :student="portfolio"
            />
        </ul>

        <!-- Generic School Links -->
        <ul v-if="!$_userMixins_isDisabled" class="kt-menu__nav pt-0">
            <AsideMenuItemHeader title="School" />

            <AsideMenuItem
                title="Teachers"
                route-name="Teachers"
                icon="teachers"
            />

            <AsideMenuItem
                title="Courses"
                route-name="CourseList"
                icon="courses"
            />

            <AsideMenuItem
                title="Students"
                route-name="Students"
                icon="students"
            />

            <AsideMenuItem
                title="Student Groups"
                route-name="StudentGroupsList"
                icon="group"
            />

            <AsideMenuItem
                title="Communication"
                route-name="Communication"
                icon="communication"
            />

            <AsideMenuItem
                title="Anecdotal Logs"
                route-name="AnecdotalLogs"
                icon="anecdotal"
            />

            <AsideMenuItem
                title="Reports"
                route-name="Reports"
                icon="progressReports"
            />
        </ul>

        <!-- Data Analytics -->
        <TheDataAnalyticsMenu v-if="!$_userMixins_isDisabled && $_userMixins_isDemoSchool" />

        <!-- Settings -->
        <TheSettingsMenu v-if="!$_userMixins_isDisabled" />
    </template>
</div>
</template>

<script lang="ts">

import AsideMenuItem from './AsideMenuItem.vue';
import AsideMenuItemHeader from './AsideMenuItemHeader.vue';
import TheAsideMenuHeader from './TheAsideMenuHeader.vue';
import TheSettingsMenu from './TheSettingsMenu.vue';
import TheDataAnalyticsMenu from './TheDataAnalyticsMenu.vue';
import StudentPortfolioMenu from './StudentPortfolioMenu.vue';
import TeacherPortfolioMenu from './TeacherPortfolioMenu.vue';
import TheSetupWizardMenu from './TheSetupWizardMenu.vue';
import globalMixins from '../../../../store/mixins/globalMixins';
import userMixins from '../../../../store/mixins/userMixins';
import teacherMixins from '../../../../store/mixins/teacherMixins';
import studentMixins from '../../../../store/mixins/studentMixins';

export default {
    name: 'TheSchoolUserMenu',
    components: {
        AsideMenuItem,
        AsideMenuItemHeader,
        TheAsideMenuHeader,
        TheSettingsMenu,
        TheDataAnalyticsMenu,
        StudentPortfolioMenu,
        TheSetupWizardMenu,
        TeacherPortfolioMenu,
    },
    mixins: [
        globalMixins,
        userMixins,
        teacherMixins,
        studentMixins,
    ],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        hideWizard() {
            return this.$store.state.wizard.hide;
        },
        teacher() {
            const userName = this.$_userMixins_userName;
            return this.$_teacherMixins_getTeacherFromEmail(userName);
        },
        portfolios() {
            const { portfolioHistory } = this.$store.state;
            const studentById = this.$_studentMixins_getStudentFromEnrollmentId;
            const teacherByEmail = this.$_teacherMixins_getTeacherFromEmail;
            return portfolioHistory.map((p) => {
                const { studentEnrollmentId, schoolEmail } = p;

                if (p.type == 'Teacher' && schoolEmail) {
                    return teacherByEmail(schoolEmail);
                }
                if (p.type == 'Student' && studentEnrollmentId) {
                    return studentById(studentEnrollmentId);
                }
                return null;
            }).filter((x) => x);
        },
        finalized() {
            return this.$store.state.user.school.finalized;
        },
    },
};
</script>
