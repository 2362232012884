<template>
<div
    v-if="show"
    class="modal modal-sticky-top-right show d-block"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="overflow: hidden; max-width: 450px; height: auto;"
>
    <div class="kt-portlet kt-portlet--mobile kt-portlet--solid-brand">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <span class="kt-portlet__head-icon"><i class="flaticon-chat" /></span>
                <h3 class="kt-portlet__head-title">
                    {{ `New Message${notificationMessages[0].messageAuthor ? ` from ${notificationMessages[0].messageAuthor}` : ''}` }}
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-actions">
                    <a class="kt-link kt-link--state kt-link--light kt-font-lg" @click.stop.prevent="closeNotification">
                        <i class="flaticon2-cross" />
                    </a>
                </div>
            </div>
        </div>
        <div class="kt-portlet__body">
            <span style="max-height: 200px; text-overflow: ellipsis; overflow: hidden;">
                {{ notificationMessages[0].messageBody }}
            </span>
            <div v-if="!isReplying" class="px-4 mt-2">
                <div class="row pull-right">
                    <span class="d-flex align-items-center mr-3">
                        <a
                            href="#"
                            class="kt-link kt-link--state kt-link--light"
                            @click.stop.prevent="goToThread"
                        >
                            View Thread
                        </a>
                    </span>
                    <button
                        v-if="notificationMessages[0].allowReplies"
                        type="button"
                        class="btn btn-light"
                        @click.stop.prevent="isReplying = !isReplying"
                    >
                        Reply
                    </button>
                </div>
            </div>
        </div>
        <div
            v-if="notificationMessages[0].allowReplies && isReplying"
            class="kt-portlet__foot kt-portlet__foot--sm kt-align-right"
            style="background-color: white;"
        >
            <textarea
                v-model="newComment"
                type="text"
                class="form-control w-100"
            />
            <div class="row pull-right mt-2 px-3">
                <span class="d-flex align-items-center mr-3">
                    <a
                        href="#"
                        class="kt-link kt-link--state kt-link--dark"
                        @click.stop.prevent="goToThread"
                    >
                        View Thread
                    </a>
                </span>
                <button
                    type="button"
                    class="btn btn-brand btn-md btn-font-sm btn-upper btn-bold"
                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                    :disabled="!newComment || saving"
                    @click.stop.prevent="sendReply"
                >
                    Send
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import Types from '../../store/Types';
import * as network from '../../network';

export default {
    name: 'MessageNotification',
    components: {},
    mixins: [],
    props: {
        notificationMessages: {
            type: Array,
            required: false,
            default: () => [],
        },
        clearNotification: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            isReplying: false,
            newComment: '',
            autoHide: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            showMessageNotification: (state) => state.communication.messageNotification,
        }),
        show() {
            const { showMessageNotification, notificationMessages } = this;
            if (!showMessageNotification) return false;
            return notificationMessages[0] && notificationMessages[0].messageId && notificationMessages[0].messageBody;
        },
    },
    watch: {
        show() {
            if (this.show) {
                clearTimeout(this.autoHide);
                this.autoHide = setTimeout(() => {
                    this.closeNotification();
                }, 3750);
            }
        },
        isReplying() {
            if (this.isReplying) {
                clearTimeout(this.autoHide);
            }
        },
    },
    methods: {
        closeNotification() {
            this.$store.commit(Types.mutations.TOGGLE_COMMUNICATION_MESSAGE_NOTIFICATION);
            clearTimeout(this.autoHide);
            this.clearNotification();
        },
        goToThread() {
            if (!this.notificationMessages[0]) return;
            const threadMessageId = this.notificationMessages[0].messageId;
            this.closeNotification();
            this.$router.push({ name: 'ViewMessage', params: { messageId: threadMessageId, studentEnrollmentId: null } });
        },
        sendReply() {
            const v = this;
            if (v.saving || !v.newComment) return;
            const { user, notificationMessages, newComment } = v;
            if (!notificationMessages[0]) return;

            const { messageId } = notificationMessages[0];
            if (!messageId) return;

            const { school } = user;
            const { schoolId, schoolTermId } = school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    messageId,
                },
                body: {
                    comment: newComment,
                },
            };

            network.communication.postComment(params, (err) => {
                v.saving = false;
                if (err) return v.showError(err);
                v.newComment = '';
                v.isReplying = false;
                v.closeNotification();
            });
        },
    },
};

</script>

<style>
.modal-sticky-top-right {
    height: auto;
    position: fixed;
    left: auto !important;
    bottom: auto !important;
    top: 25px !important;
    right: 25px !important;
}
</style>
