var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.canUseEncryption
    ? _c(
        "div",
        { staticStyle: { "z-index": "999" } },
        [
          _c("b-modal", {
            ref: "school-password-modal",
            attrs: {
              size: "lg",
              "no-close-on-esc": "",
              "no-close-on-backdrop": "",
              centered: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-header",
                  fn: function ({ close }) {
                    return [
                      _c("h5", [_vm._v("Data Decryption")]),
                      _c("button", {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          "aria-label": "Close",
                        },
                        on: {
                          click: function ($event) {
                            return close()
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        _c(
                          "div",
                          { staticClass: "form-group row validated p-3" },
                          [
                            _c("div", { staticClass: "col-lg-6 pt-3" }, [
                              _c("label", [
                                _vm._v("School Term Encryption Password"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.schoolPassword,
                                    expression: "schoolPassword",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    !_vm.isValidSchoolPassword &&
                                    _vm.schoolPassword,
                                },
                                attrs: {
                                  id: "school-password",
                                  type: "password",
                                  name: _vm.formName,
                                  autocomplete: "password",
                                },
                                domProps: { value: _vm.schoolPassword },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.decrypt.apply(null, arguments)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.schoolPassword = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    " Please enter the password provided by your school, if you do not have this password please contact your school administrator. "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "modal-footer",
                  fn: function () {
                    return [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-info pull-right",
                          class: {
                            "kt-spinner kt-spinner--sm kt-spinner--light":
                              _vm.saving,
                          },
                          attrs: { type: "submit" },
                          on: { click: _vm.decrypt },
                        },
                        [_vm._v(" Decrypt ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1243613821
            ),
            model: {
              value: _vm.showModal,
              callback: function ($$v) {
                _vm.showModal = $$v
              },
              expression: "showModal",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }