<template>
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
    <div class="kt-container kt-container--fluid">
        <template v-if="loadState == 'BOOTING'">
            <div class="kt-footer__copyright">
                <b-spinner
                    class="mt-1"
                    small
                    variant="primary"
                />
                <span class="ml-3">
                    Updated rosters are being downloaded...
                </span>
            </div>
        </template>
        <template v-if="loadState == 'READY'">
            <div class="kt-footer__copyright">
                <span>
                    Your database has been updated {{ age }}.
                </span>
            </div>
            <div class="kt-footer__menu" />
        </template>
    </div>
</div>
</template>

<script lang="ts">

import Vue from 'vue';
import moment from 'moment';

export default Vue.extend({
    name: 'TheDatabaseUpdateNotification',
    computed: {
        loadState() {
            return this.$store.state.database.loadState;
        },
        dbProperties() {
            const { cache } = this.$store.state.database;
            return cache.find((i) => i.key == 'core') || null;
        },
        age() {
            const { dbProperties } = this;
            if (!dbProperties) return '';
            const { timestamp } = dbProperties;
            if (!timestamp) return '';
            const date = moment(timestamp, 'YYYY-MM-DD HH:mm:ss');
            return `by ${date.fromNow(true)}`;
        },
    },
    methods: {
        apply() {
            window.location.reload();
        },
    },
});

</script>
