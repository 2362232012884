var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1 pt-5 mt-3 pl-5 pr-5",
    },
    [
      _c("div", { staticClass: "kt-error-v1__container" }, [
        _c("h1", { staticClass: "kt-error-v1__number" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c("p", { staticClass: "kt-error-v1__desc mt-4" }, [
          _vm._v(" " + _vm._s(_vm.subtitle) + " "),
        ]),
        _vm.message
          ? _c("div", { staticClass: "alert alert-light alert-elevate" }, [
              _c("div", { staticClass: "alert-icon" }, [
                _c("i", { class: `flaticon-warning-sign kt-font-danger` }),
              ]),
              _c("div", { staticClass: "alert-text" }, [
                _vm._v(" " + _vm._s(_vm.message) + " "),
              ]),
            ])
          : _vm._e(),
        _vm.hideSlot
          ? _c("div", { staticClass: "w-100" }, [
              _c(
                "p",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "btn btn-primary align-center",
                      attrs: { to: { name: "GoogleAccount" } },
                    },
                    [_vm._v(" Click here to connect! ")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }