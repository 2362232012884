var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: [
        "kt-menu__item menu-user-header",
        _vm.headerOpen ? "kt-menu__item--open" : "",
      ],
    },
    [
      _c(
        "a",
        {
          staticClass: "kt-menu__link pt-2 pb-2",
          attrs: {
            id: `aside_student_${_vm.studentEnrollmentId}`,
            href: "#header-toggle-menu",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              _vm.headerOpen = !_vm.headerOpen
            },
          },
        },
        [
          _vm.$_userMixins_isSchoolUser
            ? _c(
                "span",
                {
                  staticClass: "kt-menu__link-icon kt-menu__link-icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.removePortfolio.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "la la-close" })]
              )
            : _vm._e(),
          !_vm.clientSideEncryptionEnabled
            ? _c(
                "span",
                {
                  staticClass: "kt-menu__link-icon",
                  class: _vm.$_userMixins_isSchoolUser
                    ? "kt-menu__link-icon-avatar"
                    : "",
                },
                [
                  _c(
                    "UserAvatar",
                    {
                      staticClass: "kt-media kt-media--sm--sidebar",
                      attrs: { rounded: true, "avatar-user": _vm.student },
                    },
                    [
                      _c("div", { staticClass: "kt-media kt-media--sm" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "kt-media kt-media--lg kt-media--primary",
                            staticStyle: { width: "1.75rem" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  height: "1.75rem",
                                  "font-size": ".75rem",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _c("span", { staticClass: "kt-menu__link-icon" }, [
                _c("div", { staticClass: "kt-media kt-media--sm" }, [
                  _c(
                    "a",
                    {
                      staticClass: "kt-media kt-media--lg kt-media--primary",
                      staticStyle: { width: "1.75rem" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            height: "1.75rem",
                            "font-size": ".75rem",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                      ),
                    ]
                  ),
                ]),
              ]),
          _c("span", { staticClass: "kt-menu__link-text" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
          ]),
          _c("i", { staticClass: "kt-menu__ver-arrow la la-angle-right" }),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "kt-menu__submenu",
          model: {
            value: _vm.headerOpen,
            callback: function ($$v) {
              _vm.headerOpen = $$v
            },
            expression: "headerOpen",
          },
        },
        [
          _c("span", { staticClass: "kt-menu__arrow" }),
          _c(
            "ul",
            { staticClass: "kt-menu__subnav" },
            [
              _c("AsideMenuItem", {
                attrs: {
                  title: "Student Profile",
                  "route-name": "StudentInfo",
                  icon: "info",
                  "route-params": {
                    studentEnrollmentId: _vm.studentEnrollmentId,
                  },
                },
              }),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Communication",
                  "route-name": "StudentCommunication",
                  icon: "communication",
                  "route-params": {
                    studentEnrollmentId: _vm.studentEnrollmentId,
                  },
                },
              }),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Attendance",
                  "route-name": "StudentAttendance",
                  icon: "attendance",
                  "route-params": {
                    studentEnrollmentId: _vm.studentEnrollmentId,
                  },
                },
              }),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Badges",
                  "route-name": "StudentBadges",
                  icon: "badges",
                  "route-params": {
                    studentEnrollmentId: _vm.studentEnrollmentId,
                  },
                },
              }),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Student Avatar",
                  "route-name": "StudentAvatarEditor",
                  icon: "user",
                  "route-params": {
                    studentEnrollmentId: _vm.studentEnrollmentId,
                  },
                },
              }),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Anecdotals",
                  "route-name": "StudentAnecdotals",
                  icon: "anecdotal",
                  "route-params": {
                    studentEnrollmentId: _vm.studentEnrollmentId,
                  },
                },
              }),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Score Card",
                  "route-name": "StudentEvaluation",
                  icon: "evaluation",
                  "route-params": {
                    studentEnrollmentId: _vm.studentEnrollmentId,
                  },
                },
              }),
              _vm.isEnabledGuardiansOrStudent
                ? _c("AsideMenuItem", {
                    attrs: {
                      title: "Guardians",
                      "route-name": "StudentGuardians",
                      icon: "guardians",
                      "route-params": {
                        studentEnrollmentId: _vm.studentEnrollmentId,
                      },
                    },
                  })
                : _vm._e(),
              _c("AsideMenuItem", {
                attrs: {
                  title: "Reports",
                  "route-name": "StudentReports",
                  icon: "progressReports",
                  "route-params": {
                    studentEnrollmentId: _vm.studentEnrollmentId,
                  },
                },
              }),
              _c(
                "StudentPortfolioClassMenu",
                { ref: "course-menu" },
                _vm._l(_vm.courses, function (course, idx) {
                  return _c("StudentPortfolioMenuCourse", {
                    key: `student_port_route_${idx}`,
                    staticClass: "kt-menu__item",
                    attrs: { course: course, student: _vm.student },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }