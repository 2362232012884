<template>
<li
    class="kt-menu__item"
    :class="{
        'kt-menu__item--here kt-menu__item--active': open,
        'kt-menu__item--open': open,
    }"
>
    <router-link
        :to="{name: 'StudentCourseOverview', params: routeParameters }"
        class="kt-menu__link"
    >
        <CourseIcon
            :course="course"
            size="xs"
        />

        <span class="kt-menu__link-text ml-2">
            <div class="menu-subtitle">
                {{ course.name }}
            </div>
        </span>
        <span class="kt-menu__link-badge">
            <span
                v-if="course.nextMeeting.day !== today"
                class="kt-shape-bg-color-1 kt-label-font-color-2 kt-font-xs px-2 py-1"
                :class="color"
                style="color: #fff; border-radius: 0.4rem;"
            >
                {{ (course.nextMeeting.day || '').substring(0, 3) }}
            </span>
        </span>
    </router-link>
</li>
</template>

<script>

import moment from 'moment';
import CourseIcon from '../../../../components/CourseIcon.vue';
import courseMixins from '../../../../store/mixins/courseMixins';
import teacherMixins from '../../../../store/mixins/teacherMixins';

export default {
    name: 'StudentPortfolioMenuCourse',
    components: { CourseIcon },
    mixins: [courseMixins, teacherMixins],
    props: {
        course: {
            type: Object,
            required: true,
        },
        student: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            today: null,
            open: false,
        };
    },
    computed: {
        extCourseSectionId() { return this.course.extCourseSectionId; },
        studentEnrollmentId() { return this.student.studentEnrollmentId; },
        color() {
            return this.$_courseMixins_getColorForCourse(this.course);
        },
        shortTitle() {
            const { today } = this;
            const { nextMeeting } = this.course;
            if (nextMeeting.day == today) {
                return `P${nextMeeting.period}`;
            }
            return '-';
        },
        active() {
            return this.$route.params.extCourseSectionId == this.course.extCourseSectionId
                && this.$route.params.studentEnrollmentId == this.course.studentEnrollmentId;
        },
        routeParameters() {
            const { extCourseSectionId, studentEnrollmentId } = this;
            return { extCourseSectionId, studentEnrollmentId };
        },
    },
    watch: {
        $route() {
            const { extCourseSectionId } = this.$route.params;
            if (!extCourseSectionId) {
                this.open = false;
            }
            this.open = Boolean(extCourseSectionId == this.course.extCourseSectionId);
        },
    },
    mounted() {
        this.populate();
        const { course } = this;
        const { extCourseSectionId, studentEnrollmentId } = this.$route.params;
        if (!studentEnrollmentId || !extCourseSectionId || !course) return false;
        const open = Boolean(extCourseSectionId == course.extCourseSectionId);
        this.open = open;
    },
    methods: {
        populate() {
            this.today = moment().format('dddd');
        },
    },
};
</script>

<style scoped>
div.menu-subtitle {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 120px;
}
</style>

<style scoped src='../../../../css/colors.scss' lang="scss" />

<style>
a.kt-menu__link.router-link-exact-active .kt-menu__link-icon svg g [fill] {
    fill: #0f88ef !important;
}
</style>
