var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isPortfolioReady &&
    _vm.selectedCourse &&
    _vm.$_userMixins_isSchoolUser
    ? _c(
        "div",
        { staticClass: "kt-header-menu-wrapper w-100" },
        [
          _c(
            "div",
            {
              key: `course_popover_${_vm.key}`,
              staticClass:
                "kt-header__topbar-wrapper course-container w-100 pl-4 pr-4",
              attrs: { id: "course-popover" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.toggleShow()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-between mb-2 mt-2",
                  class: _vm.show ? "course-popover-shown" : "",
                },
                [
                  _c("div", { staticClass: "kt-user-card-v2" }, [
                    _c(
                      "div",
                      { staticClass: "kt-user-card-v2__pic" },
                      [
                        _vm.teacher
                          ? _c(
                              "UserAvatar",
                              {
                                staticClass: "user-avatar",
                                attrs: { "avatar-user": _vm.teacher },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "kt-badge kt-badge--username kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold",
                                    staticStyle: {
                                      height: "32px",
                                      "font-size": "1rem",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                                ),
                              ]
                            )
                          : _c("CourseIcon", {
                              staticClass: "course-icon",
                              attrs: { course: _vm.selectedCourse, size: "md" },
                            }),
                        _c(
                          "span",
                          { staticClass: "hidden-down-arrow" },
                          [
                            _c("SVGIcon", {
                              attrs: {
                                "hex-color": "#c0c4d1",
                                name: !_vm.show
                                  ? "leftNavigationDoubleArrowRight"
                                  : "leftNavigationDoubleArrowDown",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "p-2" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-block w-100 kt-font-bold kt-font-lg kt-font-dark",
                        },
                        [_vm._v(" " + _vm._s(_vm.selectedCourse.name) + " ")]
                      ),
                      _c("div", { staticClass: "d-block w-100" }, [
                        _vm._v(" " + _vm._s(_vm.teachers) + " "),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "p-2 text-right" }, [
                    _c("span", { staticClass: "d-block" }, [
                      _vm._v(
                        " " + _vm._s(_vm.selectedCourse.meetingName) + " "
                      ),
                    ]),
                    _c("span", { staticClass: "d-block" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.selectedCourse.extCourseSectionId) +
                          " "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "course-popover",
                triggers: "focus",
                placement: "bottom",
                "custom-class": "popover-class",
                show: _vm.show,
              },
              on: {
                "update:show": function ($event) {
                  _vm.show = $event
                },
              },
            },
            [
              _c("div", { staticClass: "kt-quick-search" }, [
                _c(
                  "form",
                  {
                    staticClass: "kt-quick-search__form",
                    on: {
                      submit: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.selectCourse()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "input-group",
                        staticStyle: { background: "transparent !important" },
                      },
                      [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _c("i", { staticClass: "flaticon2-search-1" }),
                          ]),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchTerm,
                              expression: "searchTerm",
                            },
                          ],
                          staticClass: "form-control kt-quick-search__input",
                          staticStyle: { background: "transparent !important" },
                          attrs: {
                            id: "course-search",
                            type: "text",
                            name: "term",
                            autocomplete: "off",
                            placeholder: "Search courses",
                          },
                          domProps: { value: _vm.searchTerm },
                          on: {
                            keyup: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  $event.keyCode !== 13
                                )
                                  return null
                                return _vm.selectCourse()
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  $event.keyCode !== 40
                                )
                                  return null
                                return _vm.moveDown()
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  $event.keyCode !== 38
                                )
                                  return null
                                return _vm.moveUp()
                              },
                            ],
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.searchTerm = $event.target.value
                            },
                          },
                        }),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c("span", { staticClass: "input-group-text" }, [
                            _c("i", {
                              staticClass: "la la-close kt-quick-search__close",
                            }),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "kt-widget4" },
                _vm._l(_vm.searchResults, function (course, idx) {
                  return _c("CourseBadge", {
                    key: `${course.extCourseSectionId}_course`,
                    class: _vm.selectedIndex == idx ? "active" : "",
                    attrs: {
                      color: _vm.$_courseMixins_getColorForCourse(course),
                      course: course,
                      index: idx,
                      "on-selected": _vm.onSelected,
                      "short-title":
                        course.extCourseSectionId.substring(0, 1) +
                        course.extCourseSectionId.slice(-1),
                    },
                  })
                }),
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }