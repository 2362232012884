<template>
<div
    v-show="showPopupChat"
    class="modal modal-sticky-bottom-left modal-sticky-lg show"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block"
>
    <div class="modal-dialog">
        <div class="modal-content">
            <Chat
                :is-pop-up="true"
            />
        </div>
    </div>
</div>
</template>

<script>

import Types from '../../store/Types';
import Chat from './Chat.vue';

export default {
    name: 'PopupChat',
    components: {
        Chat,
    },
    data() {
        return {
            key: 0,
            description: null,
        };
    },
    computed: {
        showPopupChat() {
            return this.$store.state.communication.popupChat;
        },
    },
    // watch: {
    //     $route() {
    //         this.key += 1;
    //     },
    // },
    methods: {
    },
};
</script>

<style scoped lang="scss">
@import "../../assets/sass/pages/inbox/inbox.scss";
</style>
