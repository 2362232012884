var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-portlet kt-portlet--tabs mb-0" }, [
    _c("div", { staticClass: "kt-portlet__head" }, [
      _vm._m(0),
      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
        _c(
          "div",
          { staticClass: "kt-portlet__head-actions d-flex align-items-center" },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-icon",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.closeModal.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "la la-close" })]
            ),
          ]
        ),
      ]),
    ]),
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.group.groupName,
              expression: "group.groupName",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Group Name" },
          domProps: { value: _vm.group.groupName },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.group, "groupName", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          [
            _c("label", [_vm._v("Members")]),
            _vm._v(" "),
            !_vm.showHomeUsers
              ? _c("small", [
                  _vm._v(
                    "| Chat groups can only include one student/guardian currently due to privacy concerns"
                  ),
                ])
              : _vm._e(),
            _c("SearchRecipients", {
              attrs: {
                "on-selected": _vm.memberAdded,
                "school-staff-id": _vm.schoolStaffId,
                "student-enrollment-id": null,
                "allow-school-wide": false,
                "allow-my-students": false,
                "show-courses": false,
                "show-home-rooms": false,
                "show-grade-levels": false,
                "show-student-groups": false,
                "show-students": _vm.showHomeUsers,
                "show-guardians": _vm.showHomeUsers,
              },
            }),
            _c("div", { staticClass: "tab-content student-list p-3" }, [
              _c(
                "div",
                { staticClass: "kt-widget4" },
                [
                  _vm._l(_vm.group.members.groupings, function (grouping, idx) {
                    return _c(
                      "div",
                      {
                        key: `group_grouping_${idx}`,
                        staticClass: "kt-widget4__item",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "mr-3",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.removeGrouping(idx)
                              },
                            },
                          },
                          [_c("i", { staticClass: "la la-close" })]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "kt-widget4__pic kt-widget4__pic--pic",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "kt-badge kt-badge--lg kt-badge--success",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      grouping.group.name.substring(0, 2)
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "kt-widget4__info" }, [
                          _c(
                            "a",
                            {
                              staticClass: "kt-widget4__username",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.removeGrouping(idx)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(grouping.group.name) + " ")]
                          ),
                          _c("p", { staticClass: "kt-widget4__text" }, [
                            _vm._v(" " + _vm._s(grouping.group.subName) + " "),
                          ]),
                        ]),
                        _c("div", [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "kt-checkbox-list" }, [
                              _c("label", { staticClass: "kt-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: grouping.includeStaff,
                                      expression: "grouping.includeStaff",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      grouping.includeStaff
                                    )
                                      ? _vm._i(grouping.includeStaff, null) > -1
                                      : grouping.includeStaff,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = grouping.includeStaff,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              grouping,
                                              "includeStaff",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              grouping,
                                              "includeStaff",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(grouping, "includeStaff", $$c)
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" Include Staff "),
                                _c("span"),
                              ]),
                              _c("label", { staticClass: "kt-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: grouping.includeStudents,
                                      expression: "grouping.includeStudents",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      grouping.includeStudents
                                    )
                                      ? _vm._i(grouping.includeStudents, null) >
                                        -1
                                      : grouping.includeStudents,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = grouping.includeStudents,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              grouping,
                                              "includeStudents",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              grouping,
                                              "includeStudents",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          grouping,
                                          "includeStudents",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" Include Students "),
                                _c("span"),
                              ]),
                            ]),
                            _c("div", { staticClass: "kt-checkbox-list" }, [
                              _c("label", { staticClass: "kt-checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: grouping.includeGuardians,
                                      expression: "grouping.includeGuardians",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      grouping.includeGuardians
                                    )
                                      ? _vm._i(
                                          grouping.includeGuardians,
                                          null
                                        ) > -1
                                      : grouping.includeGuardians,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = grouping.includeGuardians,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              grouping,
                                              "includeGuardians",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              grouping,
                                              "includeGuardians",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          grouping,
                                          "includeGuardians",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" Include Guardians "),
                                _c("span"),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  _vm._l(_vm.group.members.users, function (user, idx) {
                    return _c(
                      "div",
                      {
                        key: `group_user_${idx}`,
                        staticClass: "kt-widget4__item",
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "mr-3",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.removeUser(idx)
                              },
                            },
                          },
                          [_c("i", { staticClass: "la la-close" })]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "kt-widget4__pic kt-widget4__pic--pic",
                          },
                          [
                            _c(
                              "UserAvatar",
                              { attrs: { "avatar-user": user } },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kt-badge kt-badge--lg kt-badge--success",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(user.name.substring(0, 2)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "kt-widget4__info" }, [
                          _c(
                            "a",
                            {
                              staticClass: "kt-widget4__username",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.removeUser(idx)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(user.name) + " ")]
                          ),
                          _c("p", { staticClass: "kt-widget4__text" }, [
                            _vm._v(" " + _vm._s(user.subName) + " "),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "kt-portlet__foot row pt-4 pb-4 pl-5 pr-5" }, [
      _c("div", { staticClass: "col-lg-4" }, [
        _c("div", { staticClass: "btn-group mt-2" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-brand btn-bold",
              class: {
                "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
              },
              attrs: {
                type: "button",
                disabled:
                  _vm.saving ||
                  (!_vm.group.members.groupings.length &&
                    !_vm.group.members.users.length),
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.createGroup.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Create Group ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" New Communication Group "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }