var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showCreateCommunicationGroup,
          expression: "showCreateCommunicationGroup",
        },
      ],
      staticClass: "modal modal-sticky-bottom-left modal-sticky-lg show",
      staticStyle: { display: "block" },
      attrs: { role: "dialog", "data-backdrop": "false", "aria-modal": "true" },
    },
    [
      _c("div", { staticClass: "modal-dialog" }, [
        _c(
          "div",
          { staticClass: "modal-content kt-inbox" },
          [
            _vm.showCreateCommunicationGroup
              ? _c("CreateMessagingForm", {
                  key: `communicationForm_${_vm.key}`,
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }