var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "help-redirect-modal",
    attrs: { size: "lg", variant: "primary", centered: "" },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" Wiki Redirect Setup "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "modal-body",
                staticStyle: { padding: "0px 10px", "overflow-x": "hidden" },
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [
                    _vm._v("Wiki URL for: " + _vm._s(_vm.routeName)),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mutableRedirectUrl,
                        expression: "mutableRedirectUrl",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "Enter wiki url" },
                    domProps: { value: _vm.mutableRedirectUrl },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.mutableRedirectUrl = $event.target.value
                      },
                    },
                  }),
                  _vm.hasWikiUrl
                    ? _c("span", { staticClass: "form-text text-muted" }, [
                        _vm._v(" Enter a new URL to update the current URL. "),
                      ])
                    : _c("span", { staticClass: "form-text text-muted" }, [
                        _vm._v(
                          " Enter a URL to redirect to when the user clicks the help button. "
                        ),
                      ]),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "row w-100" }, [
              _c(
                "div",
                { staticClass: "col-12 text-right" },
                [
                  _vm.hasWikiUrl
                    ? [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-secondary mr-2",
                            attrs: { href: _vm.wikiUrl, target: "wikiUrl" },
                          },
                          [
                            _vm._v(
                              " Open Current url for `" +
                                _vm._s(_vm.routeName) +
                                "` "
                            ),
                          ]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              href: "#",
                              disabled: !_vm.mutableRedirectUrl,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.saveChanges.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" Edit Wiki URL ")]
                        ),
                      ]
                    : _c(
                        "a",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            href: "#",
                            disabled: !_vm.mutableRedirectUrl,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.saveChanges.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Set Wiki URL ")]
                      ),
                ],
                2
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }