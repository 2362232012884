<template>
<b-modal
    ref="google-cat-change-modal"
    v-model="showModal"
    size="xl"
    no-close-on-backdrop
    centered
>
    <template #modal-header="{ close }">
        <h5 class="modal-title kt-font-danger">
            {{ extCourseSectionId }} - Google Category Assignments
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal()"
        />
    </template>

    <template #default>
        <div class="modal-body">
            <div class="form-group form-group-last pt-3 pb-5">
                <div
                    class="alert alert-secondary"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-danger kt-font-brand" />
                    </div>
                    <div class="alert-text">
                        The Grade Template Categories for <code>{{ extCourseSectionId }}</code> may have been modified by an administrator.
                        To ensure accuracy, please map each Google Category to Template Category and save.
                    </div>
                </div>
            </div>
            <div
                v-for="item in changes"
                :key="`category_${item.googleCourseGradeCategoryId}`"
                class="form-group row px-5"
            >
                <div class="col-md-6">
                    <label>Google Category</label>
                    <input
                        type="text"
                        class="form-control col-md-10"
                        disabled
                        :value="`${item.gradeCategoryName }`"
                    >
                </div>

                <div class="col-md-6">
                    <label>Grade Template Category</label>
                    <br>
                    <select
                        v-model="item.newGradeTemplateCategoryId"
                        class="form-control col-md-10"
                    >
                        <option
                            v-for="c in gradeTemplateCategories"
                            :key="`option_c_${c.gradeTemplateCategoryId}`"
                            :value="c.gradeTemplateCategoryId"
                        >
                            {{ c.categoryName }}
                        </option>
                    </select>
                    <span
                        v-if="!item.newGradeTemplateCategoryId"
                        class="form-text text-muted kt-font-danger"
                    >Select a new Grading Category</span>
                </div>

                <div class="kt-separator kt-separator--space-md kt-separator--border-dashed" />
            </div>
        </div>
    </template>
    <template #modal-footer>
        <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            @click.stop.prevent="ignore"
        >
            Ignore For Now
        </button>
        <button
            type="button"
            class="btn btn-primary alignment pull-right"
            data-dismiss="modal"
            :disabled="saving || !isValid"
            @click.stop.prevent="save"
        >
            Save Changes
        </button>
    </template>
</b-modal>
</template>

<script>
import { getPortfolioCacheKey } from '../store/mixins/portfolioMixins';
import courseMixins from '../store/mixins/courseMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import { getCourseWork } from '../store/mixins/courseWorkMixins';
import * as network from '../network';
import Types from '../store/Types';

export default {
    name: 'GlobalTemplateGoogleCategoryModal',
    mixins: [courseMixins, googleCourseMixins],
    data() {
        return {
            saving: false,
            show: false,
            changes: [],
        };
    },
    computed: {
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        googleCategories() {
            const { googleCourse } = this;
            if (!googleCourse) return null;
            return this.$store.state.database.googleCourseGradeCategories.filter((c) => c.googleCourseId == googleCourse.googleCourseId);
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        gradeTemplates() {
            return this.$store.state.database.gradeTemplates;
        },
        gradeTemplate() {
            const { course } = this;
            if (!course) return null;
            return course.gradeTemplate;
        },
        gradeTemplateCategories() {
            if (!this.gradeTemplate) return [];
            return this.gradeTemplate.categories;
        },
        unknownCategories() {
            const { googleCategories, gradeTemplateCategories } = this;
            if (!googleCategories) return [];
            return googleCategories.filter((c) => !c.gradeTemplateCategoryId || !gradeTemplateCategories.map((x) => x.gradeTemplateCategoryId).includes(c.gradeTemplateCategoryId));
        },
        isValid() {
            const { changes } = this;
            if (changes.length == 0) return false;
            const every = changes.every((c) => c.newGradeTemplateCategoryId);
            if (!every) return false;
            return true;
        },
        isPortfolioReady() {
            const cacheKey = getPortfolioCacheKey(this.$route, this.$store.state.database);
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        globalModalShow() {
            return this.$store.state.globalModal.show;
        },
        showModal: {
            get() {
                return this.show;
            },
            set(val) {
                this.show = val;
            },
        },
    },
    watch: {
        $route() {
            this.populate();
        },
        isPortfolioReady() {
            this.populate();
        },
        globalModalShow() {
            if (this.globalModalShow === 'google-cat-change-modal') {
                this.populate();
            }
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        ignore() {
            this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, true);
            setTimeout(function () {
                this.$store.commit(Types.mutations.SET_IGNORE_WARNINGS, false);
            }, 1000 * 60 * 15); // 15 mins
            if (this.globalModalShow === 'google-cat-change-modal') this.$store.commit(Types.mutations.HIDE_GLOBAL_MODAL);
            this.showModal = false;
        },
        closeModal() {
            if (this.globalModalShow === 'google-cat-change-modal') this.$store.commit(Types.mutations.HIDE_GLOBAL_MODAL);
            this.showModal = false;
        },
        populate() {
            const { schoolEmail, extCourseSectionId } = this.$route.params;
            const isPortfolioView = Boolean(schoolEmail && extCourseSectionId);
            if (!isPortfolioView) return false;
            if (!this.course) return false;

            const { isPortfolioReady, googleCategories, unknownCategories } = this;
            if (!isPortfolioReady) return false;
            if (!googleCategories) return false;
            this.changes = googleCategories.map((x) => ({ ...x, newGradeTemplateCategoryId: x.gradeTemplateCategoryId || null }));
            if (this.globalModalShow === 'google-cat-change-modal') this.showModal = true;
            else this.showModal = unknownCategories.length > 0;
        },
        save() {
            const v = this;
            const {
                showError, saving, isValid, changes,
            } = this;
            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const { googleCourseId } = this.googleCourse;
            if (saving || !isValid) return;
            v.saving = true;

            const googleCategories = changes.map((c) => ({
                googleCourseGradeCategoryId: c.googleCourseGradeCategoryId,
                oldGradeTemplateCategoryId: c.gradeTemplateCategoryId,
                newGradeTemplateCategoryId: c.newGradeTemplateCategoryId,
            }));
            const params = {
                url: { schoolId, schoolTermId, googleCourseId },
                body: { googleCategories },
            };

            network.googleCourseWork.gradeTemplateCategoryGoogleCategory(params, (err) => {
                if (err) return showError(err);
                v.saving = false;
                window.location.reload();
            });
        },
    },
};
</script>
