var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-header__topbar-item" }, [
    _vm.screenRecord.isRecording
      ? _c(
          "div",
          {
            staticClass: "kt-header__topbar-wrapper",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.stopScreenRecord.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    value: "Click here to stop recording",
                    expression: "'Click here to stop recording'",
                    modifiers: { hover: true },
                  },
                ],
                staticClass:
                  "kt-header__topbar-icon1 kt-pulse kt-pulse--danger",
                attrs: { id: "record-icon" },
              },
              [
                _c("SVGIcon", {
                  attrs: { name: "record", "hex-color": "#fd397a" },
                }),
                _c("span", { staticClass: "kt-pulse__ring" }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }