<template>
<div
    title="Settings Menu"
    class="kt-header__topbar-item kt-header__topbar-item--search dropdown"
>
    <div
        class="kt-header__topbar-wrapper"
    >
        <router-link
            v-slot="{ navigate }"
            :to="{ name: isSettingsView ? 'Home' : 'SettingsMenu' }"
            custom
        >
            <span
                role="link"
                :class="isSettingsView ? 'kt-header__topbar-icon_new' : 'kt-header__topbar-icon'"
                @click="navigate"
            >
                <SVGIcon
                    :hex-color="isSettingsView ? '#646c9a' : '#0f88ef'"
                    name="settings-3"
                    class="svg-icon"
                />
            </span>
        </router-link>

        <!-- <router-link
            :tag="'span'"
            :class="isSettingsView ? 'kt-header__topbar-icon_new' : 'kt-header__topbar-icon'"
            :to="{ name: 'SettingsMenu' }"
        >
            <SVGIcon
                :hex-color="isSettingsView ? '#646c9a' : '#0f88ef'"
                name="settings-3"
                class="svg-icon"
            />
        </router-link> -->
    </div>
</div>
</template>

<script>
import SVGIcon from '../../../../components/SVGIcon/SVGIcon.vue';

export default {
    name: 'TheSettingsButton',
    components: { SVGIcon },
    computed: {
        isSettingsView() {
            return this.$route.path == '/configuration';
        },
    },
    methods: {
    },
};
</script>

<style scoped>
span.kt-header__topbar-icon_new {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 4px;
}

.svg-icon {
    height: 24px;
    width: 24px;
}
</style>
