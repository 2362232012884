<template>
<div v-if="canUseEncryption" style="z-index: 999;">
    <b-modal
        ref="school-password-modal"
        v-model="showModal"
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        centered
    >
        <template #modal-header="{ close }">
            <h5>Data Decryption</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
            />
        </template>
        <template #default>
            <div class="kt-portlet__body">
                <div class="form-group row validated p-3">
                    <div class="col-lg-6 pt-3">
                        <label>School Term Encryption Password</label>
                        <input
                            id="school-password"
                            v-model="schoolPassword"
                            type="password"
                            class="form-control"
                            :name="formName"
                            :class="{'is-invalid': !isValidSchoolPassword && schoolPassword}"
                            autocomplete="password"
                            @keyup.enter="decrypt"
                        >
                        <span class="form-text text-muted">
                            Please enter the password provided by your school,
                            if you do not have this password please contact your school administrator.
                        </span>
                    </div>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <button
                type="submit"
                class="btn btn-info pull-right"
                :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                @click="decrypt"
            >
                Decrypt
            </button>
        </template>
    </b-modal>
</div>
</template>

<script>
import Vue from 'vue';
import Types from '../../../store/Types';

export default Vue.extend({
    name: 'SchoolPasswordModal',
    components: {},
    mixins: [],
    data() {
        return {
            saving: false,
            schoolPassword: '',
        };
    },
    computed: {
        showModal: {
            get() {
                return this.$store.state.user.school.showSchoolPasswordModal;
            },
            set(show) {
                this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, show);
            },
        },
        user() {
            return this.$store.state.user;
        },
        formName() {
            // return `school-password-modal-${Math.random().toString(36).substring(7)}`;
            return `encryptionPass_${this.$store.state.user.school.schoolTermId}`;
        },
        clientSideEncryptionEnabled() {
            return this.user.school.clientSideEncryptionEnabled;
        },
        serverSideEncryptionEnabled() {
            return this.user.school.serverSideEncryptionEnabled;
        },
        hasEncryptionCredentials() {
            return Boolean(this.$store.state.database.schoolTermEncryptionKey);
        },
        canUseEncryption() {
            return this.$store.state.user.school.encryptionHash
                && this.$store.state.user.twoFactorAuth.twoFactorAuthEnabled
                && this.$store.state.user.hasLocalPassword
                && this.user.school.serverSideEncryptionEnabled;
        },
        isValidSchoolPassword() {
            const password = this.schoolPassword || '';
            if (password.length <= 8) return false;
            const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
            if (!/\d/.test(password)) return false;
            if (!/[A-Z]/.test(password)) return false;
            if (!format.test(password)) return false;
            return true;
        },
    },
    watch: {
        showModal(show) {
            if (show) {
                setTimeout(() => {
                    const input = document.getElementById('school-password');
                    if (input) input.focus();
                }, 250);
            }
        },
    },
    mounted() {
        if (this.$store.state.user.school.externalSchoolId == '00X000') {
            this.schoolPassword = 'Welcome1!';
        }
    },
    methods: {
        open() {
            this.showModal = true;
        },
        decrypt() {
            this.showModal = false;
            const { schoolPassword, showError } = this;
            this.$store.dispatch(Types.actions.SET_CIPHER_CREDENTIALS, {
                schoolPassword,
                callback: (err) => {
                    if (err) return showError(err);
                    window.location.reload();
                },
            });
        },
    },
});
</script>

<style scoped>

ul {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

li {
    margin-bottom: 8px;
    color: #525f7f;
    position: relative;
}

li:before {
  content: "";
    width: 0%; height: 2px;
    background: #2ecc71;
    position: absolute;
    left: 0; top: 50%;
    display: block;
    transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.is_valid { color: rgba(136, 152, 170, 0.8); }
.is_valid:before { width: 100%; }
.checked { animation: draw 0.5s ease forwards; }

@keyframes draw {
  to { stroke-dashoffset: 0; }
}
</style>
