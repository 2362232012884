<template>
<div v-if="serverSideEncryptionEnabled" class="kt-header__topbar-item">
    <div class="kt-header__topbar-wrapper" @click.stop.prevent="toggleDecryption">
        <span class="kt-header__topbar-icon">
            <SVGIcon
                v-if="!clientSideEncryptionEnabled"
                v-b-tooltip.hover.bottom
                name="unlock"
                class="kt-svg-icon"
                title="PII is currently viewable, click to encrypt"
                :hex-color="'#fd397a'"
            />
            <SVGIcon
                v-else
                v-b-tooltip.hover.bottom
                name="lock"
                title="PII is currently encrypted, click to decrypt"
                class="kt-svg-icon"
                :hex-color="'#fd397a'"
            />
        </span>
    </div>
    <SchoolPasswordModal ref="schoolPasswordModal" />
</div>
</template>

<script>
import SchoolPasswordModal from '../SchoolPasswordModal.vue';
import Types from '../../../../store/Types';

export default {
    name: 'TheCipherButton',
    components: {
        SchoolPasswordModal,
    },
    data() {
        return {
            loading: false,
            timeout: null,
            timestamp: null,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        school() {
            return this.$store.state.user.school;
        },
        serverSideEncryptionEnabled() {
            return this.user.school.serverSideEncryptionEnabled;
        },
        clientSideEncryptionEnabled() {
            return this.user.school.clientSideEncryptionEnabled;
        },
        encryptionHash() {
            return this.user.school.encryptionHash;
        },
    },
    methods: {
        toggleDecryption() {
            if (this.clientSideEncryptionEnabled) {
                this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
            } else {
                const { schoolTermId } = this.$store.state.user.school;
                window.sessionStorage.removeItem(`stek_${schoolTermId}`);
                window.location.reload();
            }
        },
    },
};
</script>
