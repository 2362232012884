<template>
<li
    class="kt-menu__item menu-user-header"
    :class="{
        'kt-menu__item--open': open,
        'kt-menu__item--dull-open': openAndDull
    }"
>
    <a
        href="#toggle-menu"
        class="kt-menu__link pt-2 pb-2"
        @click.stop.prevent="toggle"
    >
        <span class="kt-menu__link-icon">
            <SVGIcon name="earth" />
        </span>

        <span class="kt-menu__link-text">
            NYSED Biliteracy Seal
        </span>

        <i class="kt-menu__ver-arrow la la-angle-right" />
    </a>
    <b-collapse
        v-model="open"
        class="kt-menu__submenu"
    >
        <span class="kt-menu__arrow" />
        <ul class="kt-menu__subnav">
            <li
                class="kt-menu__item"
            >
                <a
                    href="/nysed/biliteracy-seal"
                    target="_blank"
                    class="kt-menu__link"
                >
                    <i class="kt-menu__link-bullet kt-menu__link-bullet--dot">
                        <span />
                    </i>

                    <span class="kt-menu__link-text">
                        <div class="menu-subtitle text-nowrap text-truncate">
                            District Dashboard
                        </div>
                    </span>
                </a>
            </li>
            <li
                class="kt-menu__item"
            >
                <a
                    href="/nysed/biliteracy-seal"
                    target="_blank"
                    class="kt-menu__link"
                >
                    <i class="kt-menu__link-bullet kt-menu__link-bullet--dot">
                        <span />
                    </i>

                    <span class="kt-menu__link-text">
                        <div class="menu-subtitle text-nowrap text-truncate">
                            School Dashboard
                        </div>
                    </span>
                </a>
            </li>
            <!-- <AsideMenuItem
                title="Categories"
                route-name="DataAnalysisAnecdotalCategories"
            />
            <AsideMenuItem
                title="Badges"
                route-name="DataAnalysisAnecdotalBadges"
            /> -->
        </ul>
    </b-collapse>
</li>
</template>

<script lang="ts">
import AsideMenuItem from './AsideMenuItem.vue';

export default {
    name: 'DataAnalyticsMenu',
    components: {
        AsideMenuItem,
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        openAndDull() {
            return Boolean(this.isActive && this.open);
        },
        isActive() {
            return this.$route.fullPath.indexOf('/analysis/anecdotal') > -1;
        },
    },
    watch: {
        $route() {
            this.setOpenState();
        },
    },
    mounted() {
        this.setOpenState();
    },
    methods: {
        toggle() {
            this.open = !this.open;
        },
        setOpenState() {
            const { isActive } = this;
            this.open = Boolean(isActive);
        },
    },
};
</script>

<style lang="scss">
.kt-menu__item--dull-open {
    .kt-menu__link-text {
        color: #3d4456 !important;
    }
    .kt-menu__link-icon svg g [fill] {
        fill: #c4cff9 !important;;
    }
}
</style>
