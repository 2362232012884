var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass: "kt-menu__nav pt-0",
      attrs: { id: "aside_school_settings" },
    },
    [
      _vm.$_userMixins_isSchoolAdmin || !_vm.$_userMixins_isGoogleDisabled
        ? _c("AsideMenuItemHeader", { attrs: { title: "Configuration" } })
        : _vm._e(),
      _vm.$_userMixins_isSchoolAdmin
        ? [
            _c("TheSchoolSettingsMenu", { ref: "school-menu" }),
            _c("TheFileManagerMenu", { ref: "file-menu" }),
            _c("TheReportCardMenu", { ref: "rpcd-menu" }),
            _vm.finalized ? _c("TheSetupWizardMenu") : _vm._e(),
          ]
        : _vm._e(),
      !_vm.$_userMixins_isGoogleDisabled
        ? _c("TheGoogleSettingsMenu", { ref: "google-menu" })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }