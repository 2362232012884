<template>
<div class="kt-portlet kt-portlet--tabs mb-0">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                New Communication Group
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-actions d-flex align-items-center">
                <a
                    href="#"
                    class="btn btn-icon"
                    @click.stop.prevent="closeModal"
                >
                    <i class="la la-close" />
                </a>
            </div>
        </div>
    </div>

    <div class="kt-portlet__body">
        <div class="form-group">
            <input
                v-model="group.groupName"
                type="text"
                class="form-control"
                placeholder="Group Name"
            >
        </div>
        <div class="form-group">
            <div>
                <label>Members</label> <small v-if="!showHomeUsers">| Chat groups can only include one student/guardian currently due to privacy concerns</small>
                <SearchRecipients
                    :on-selected="memberAdded"
                    :school-staff-id="schoolStaffId"
                    :student-enrollment-id="null"
                    :allow-school-wide="false"
                    :allow-my-students="false"
                    :show-courses="false"
                    :show-home-rooms="false"
                    :show-grade-levels="false"
                    :show-student-groups="false"
                    :show-students="showHomeUsers"
                    :show-guardians="showHomeUsers"
                />

                <div class="tab-content student-list p-3">
                    <div class="kt-widget4">
                        <div
                            v-for="(grouping, idx) in group.members.groupings"
                            :key="`group_grouping_${idx}`"
                            class="kt-widget4__item"
                        >
                            <a
                                href="#"
                                class="mr-3"
                                @click.stop.prevent="removeGrouping(idx)"
                            >
                                <i class="la la-close" />
                            </a>

                            <div class="kt-widget4__pic kt-widget4__pic--pic">
                                <div class="kt-badge kt-badge--lg kt-badge--success">
                                    {{ grouping.group.name.substring(0, 2) }}
                                </div>
                            </div>

                            <div class="kt-widget4__info">
                                <a
                                    href="#"
                                    class="kt-widget4__username"
                                    @click.stop.prevent="removeGrouping(idx)"
                                >
                                    {{ grouping.group.name }}
                                </a>
                                <p class="kt-widget4__text">
                                    {{ grouping.group.subName }}
                                </p>
                            </div>

                            <div>
                                <div class="row">
                                    <div class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input
                                                v-model="grouping.includeStaff"
                                                type="checkbox"
                                            > Include Staff
                                            <span />
                                        </label>
                                        <label class="kt-checkbox">
                                            <input
                                                v-model="grouping.includeStudents"
                                                type="checkbox"
                                            > Include Students
                                            <span />
                                        </label>
                                    </div>
                                    <div class="kt-checkbox-list">
                                        <label class="kt-checkbox">
                                            <input
                                                v-model="grouping.includeGuardians"
                                                type="checkbox"
                                            > Include Guardians
                                            <span />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="(user, idx) in group.members.users"
                            :key="`group_user_${idx}`"
                            class="kt-widget4__item"
                        >
                            <a
                                href="#"
                                class="mr-3"
                                @click.stop.prevent="removeUser(idx)"
                            >
                                <i class="la la-close" />
                            </a>

                            <div class="kt-widget4__pic kt-widget4__pic--pic">
                                <UserAvatar :avatar-user="user">
                                    <div class="kt-badge kt-badge--lg kt-badge--success">
                                        {{ user.name.substring(0, 2) }}
                                    </div>
                                </UserAvatar>
                            </div>

                            <div class="kt-widget4__info">
                                <a
                                    href="#"
                                    class="kt-widget4__username"
                                    @click.stop.prevent="removeUser(idx)"
                                >
                                    {{ user.name }}
                                </a>
                                <p class="kt-widget4__text">
                                    {{ user.subName }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-portlet__foot row pt-4 pb-4 pl-5 pr-5">
        <div class="col-lg-4">
            <div class="btn-group mt-2">
                <button
                    type="button"
                    class="btn btn-brand btn-bold"
                    :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                    :disabled="saving || (!group.members.groupings.length && !group.members.users.length)"
                    @click.stop.prevent="createGroup"
                >
                    Create Group
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import Types from '../../store/Types';
import studentMixins from '../../store/mixins/studentMixins';
import SearchRecipients from '../SearchRecipients.vue';
import UserAvatar from '../UserAvatar.vue';
import * as network from '../../network';

export default Vue.extend({
    name: 'CreateChatGroupForm',
    components: {
        SearchRecipients,
        UserAvatar,
    },
    mixins: [studentMixins],
    data() {
        return {
            group: {
                groupName: null,
                members: {
                    groupings: [],
                    users: [],
                },
            },
            saving: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId;
        },
        showHomeUsers() {
            const { group } = this;
            const { members } = group;
            const { users } = members;
            return !users.some((u) => u.studentEnrollmentId || u.studentGuardianId);
        },
    },
    mounted() {},
    methods: {
        memberAdded(member) {
            const {
                courseSectionId, schoolStaffId, studentEnrollmentId, studentGuardianId,
            } = member;

            if (courseSectionId) {
                const { groupings } = this.group.members;
                const found = groupings.find((g) => g.group && g.group.courseSectionId == courseSectionId) || null;
                if (!found && groupings.length == 0) {
                    groupings.push({
                        group: member, includeStudents: true, includeGuardians: true, includeStaff: true,
                    });
                }
            } else {
                const { users } = this.group.members;
                let found = null;
                if (schoolStaffId) found = users.find((u) => u.schoolStaffId == schoolStaffId);
                else if (studentGuardianId) found = users.find((u) => u.studentGuardianId == studentGuardianId);
                else if (studentEnrollmentId) found = users.find((u) => u.studentEnrollmentId == studentEnrollmentId && u.role !== 'Guardian');

                if (!found) users.push(member);
            }
        },
        removeGrouping(idx) {
            const { groupings } = this.group.members;
            groupings.splice(idx, 1);
        },
        removeUser(idx) {
            const { users } = this.group.members;
            users.splice(idx, 1);
        },
        createGroup() {
            const v = this;
            const { user, group } = v;

            if (v.saving || (!group.members.groupings.length && !group.members.users.length)) return;
            v.saving = true;

            const { school } = user;
            const { schoolId, schoolTermId, schoolStaffId } = school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    schoolStaffId,
                },
                body: {
                    group: v.group,
                },
            };

            network.communication.createGroup(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Error creating group');
                v.group = {
                    groupName: null,
                    members: {
                        groupings: [],
                        users: [],
                    },
                };
                const { communicationGroup } = res;
                if (communicationGroup) v.$store.commit(Types.mutations.SET_DB_COMMUNICATION_GROUPS, [communicationGroup]);
                else v.showError('Error creating chat group, ensure you have selected valid members');
            });
        },
        closeModal() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_COMMUNICATION_GROUP);
        },
    },
});
</script>

<style scoped>
div.student-list {
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: clip;
}
</style>
