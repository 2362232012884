var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-header__topbar-item kt-header__topbar-item--search dropdown",
      attrs: { title: "Settings Menu" },
    },
    [
      _c(
        "div",
        { staticClass: "kt-header__topbar-wrapper" },
        [
          _c("router-link", {
            attrs: {
              to: { name: _vm.isSettingsView ? "Home" : "SettingsMenu" },
              custom: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ navigate }) {
                  return [
                    _c(
                      "span",
                      {
                        class: _vm.isSettingsView
                          ? "kt-header__topbar-icon_new"
                          : "kt-header__topbar-icon",
                        attrs: { role: "link" },
                        on: { click: navigate },
                      },
                      [
                        _c("SVGIcon", {
                          staticClass: "svg-icon",
                          attrs: {
                            "hex-color": _vm.isSettingsView
                              ? "#646c9a"
                              : "#0f88ef",
                            name: "settings-3",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }