<template>
<div id="topSchoolMenuNav">
    <!-- School / User Label -->
    <TheAsideMenuHeader />

    <!-- Portfolio Viewer -->
    <span v-if="!$_userMixins_isDisabled">
        <ul
            v-for="(portfolio, idx) in portfolios"
            :key="`portfolioViewers_${idx}_${key}`"
            class="kt-menu__nav py-0"
        >
            <AsideMenuItemHeader
                v-if="idx == 0"
                title="Portfolios"
            />

            <StudentPortfolioMenu
                :student="portfolio"
            />
        </ul>
    </span>

    <ul
        v-if="!$_userMixins_isDisabled"
        class="kt-menu__nav pt-3"
    >
        <AsideMenuItemHeader title="School" />

        <AsideMenuItem
            title="Communication"
            route-name="Communication"
            icon="communication"
        />
    </ul>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import TheAsideMenuHeader from './TheAsideMenuHeader.vue';
import StudentPortfolioMenu from './StudentPortfolioMenu.vue';
import AsideMenuItemHeader from './AsideMenuItemHeader.vue';
import globalMixins from '../../../../store/mixins/globalMixins';
import userMixins from '../../../../store/mixins/userMixins';
import studentMixins from '../../../../store/mixins/studentMixins';
import AsideMenuItem from './AsideMenuItem.vue';

export default {
    name: 'TheHomeUserMenu',
    components: {
        TheAsideMenuHeader,
        AsideMenuItemHeader,
        StudentPortfolioMenu,
        AsideMenuItem,
    },
    mixins: [globalMixins, userMixins, studentMixins],
    data() {
        return { key: 0 };
    },
    computed: {
        ...mapState({
            school: (state) => state.user.school,
        }),
        portfolios() {
            const { school } = this;
            const { schoolTermId } = school;
            return this.$_studentMixins_getStudents().filter((student) => student.schoolTermId == schoolTermId);
        },
    },
};
</script>
