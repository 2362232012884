var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-aside kt-aside--fixed kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop",
        class: { "kt-aside--on": _vm.show && _vm.showMobileMenu },
        attrs: { id: "kt_aside" },
        on: {
          mouseover: function ($event) {
            _vm.hovering = true
          },
          mouseout: function ($event) {
            _vm.hovering = false
          },
        },
      },
      [
        _c("div", { staticClass: "kt-aside__brand kt-grid__item" }, [
          _c(
            "div",
            { staticClass: "kt-aside__brand-logo" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Home" } } },
                [
                  _c("SVGIcon", {
                    class: _vm.showAside ? "ml-3" : "mr-3",
                    attrs: { name: "syncgrades" },
                  }),
                  !_vm.showAside
                    ? _c("img", {
                        staticStyle: { width: "128px" },
                        attrs: {
                          alt: "SyncGrades",
                          src: "/logos/logo-text-dark.svg",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "kt-aside__brand-tools" }, [
            _c(
              "button",
              {
                staticClass: "kt-aside__brand-aside-toggler",
                class: { "kt-aside__brand-aside-toggler--active": _vm.show },
                attrs: { id: "kt_aside_toggler" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.setStickyAsideMenu("toggle")
                  },
                },
              },
              [
                _c(
                  "span",
                  [
                    _c("SVGIcon", {
                      attrs: { name: "leftNavigationDoubleArrowLeft" },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _c("SVGIcon", {
                      attrs: { name: "leftNavigationDoubleArrowRight" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._m(0),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid",
            attrs: { id: "kt_aside_menu_wrapper" },
          },
          [
            _c(
              "perfect-scrollbar",
              {
                ref: "scroll",
                staticStyle: { height: "100vh", position: "relative" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "kt-aside-menu",
                    class: { "kt-aside--on": !_vm.show },
                    attrs: { id: "kt_aside_menu" },
                  },
                  [
                    _vm.databaseIsReady
                      ? [
                          _vm.$_userMixins_isSchoolUser
                            ? _c("TheSchoolUserMenu")
                            : _vm._e(),
                          _vm.$_userMixins_isHomeUser
                            ? _c("TheHomeUserMenu")
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
    _vm.show && _vm.showMobileMenu
      ? _c("div", {
          staticClass: "kt-aside-overlay",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.setMobileMenu("toggle")
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left",
      },
      [_c("span")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }