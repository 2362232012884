var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "kt-header__topbar-item kt-header__topbar-item--search dropdown",
      attrs: { title: "'Open Documentation'" },
    },
    [
      _c(
        "div",
        {
          staticClass: "kt-header__topbar-wrapper",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.open()
            },
          },
        },
        [
          _c(
            "span",
            {
              class: {
                "kt-header__topbar-icon": true,
                undefined: _vm.showDangerColor,
              },
            },
            [_c("SVGIcon", { attrs: { name: "help" } })],
            1
          ),
        ]
      ),
      _vm.isSuperAdmin
        ? _c("HelpRedirectModal", { ref: "helpRedirectModal" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }