var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "kt-menu__nav_header" }, [
    _c("li", { staticClass: "kt-menu__section_top" }, [
      _c("div", { staticClass: "kt-menu__section-text ml-4 pl-2" }, [
        _vm._v(
          " " +
            _vm._s(_vm.externalSchoolId) +
            " " +
            _vm._s(_vm.schoolName) +
            " "
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "kt-menu__section-text ml-4 pl-2",
          class: {
            "kt-font-danger": !_vm.current,
          },
          attrs: {
            title: _vm.current
              ? "This is the current school term"
              : "This is not the current school term",
          },
        },
        [_vm._v(" " + _vm._s(_vm.schoolTermName) + " ")]
      ),
      _c(
        "div",
        {
          staticClass: "kt-menu__section-text ml-4 pl-2 mt-1",
          class: {
            "kt-font-primary": _vm.socketEstablished,
            "kt-font-danger": !_vm.socketEstablished,
          },
        },
        [
          _vm.socketEstablished
            ? [
                _c("span", {
                  staticClass: "kt-badge kt-badge--brand kt-badge--dot mr-2",
                }),
                _vm._v(" WebSocket "),
              ]
            : [
                _c("span", {
                  staticClass: "kt-badge kt-badge--danger kt-badge--dot mr-2",
                }),
                _vm._v(" WebSocket "),
              ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }