<template>
<div class="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v1 pt-5 mt-3 pl-5 pr-5">
    <div class="kt-error-v1__container">
        <h1 class="kt-error-v1__number">
            {{ title }}
        </h1>
        <p class="kt-error-v1__desc mt-4">
            {{ subtitle }}
        </p>

        <div
            v-if="message"
            class="alert alert-light alert-elevate"
        >
            <div class="alert-icon">
                <i :class="`flaticon-warning-sign kt-font-danger`" />
            </div>
            <div class="alert-text">
                {{ message }}
            </div>
        </div>

        <div
            v-if="hideSlot"
            class="w-100"
        >
            <!-- <slot /> -->
            <p>
                <router-link
                    class="btn btn-primary align-center"
                    :to="{name: 'GoogleAccount'}"
                >
                    Click here to connect!
                </router-link>
            </p>
        </div>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    name: 'PageError',
    data() {
        return {
            namedErrors: [],
        };
    },
    computed: {
        ...mapState({
            unlinkedGoogleEmail: (state) => state.user.unlinkedGoogleEmail,
        }),
        hideSlot() {
            return Boolean(this.namedError);
        },
        namedError() {
            const err = this.$route.query.error;
            return this.namedErrors.find((e) => e.id == err) || null;
        },
        message() {
            if (!this.namedError) return 'An error has occured';
            return this.namedError.message;
        },
        title() {
            if (!this.namedError) return 'An error has occured';
            return this.namedError.title;
        },
        subtitle() {
            if (!this.namedError) return 'An error has occured';
            return this.namedError.subtitle;
        },
    },
    mounted() {
        this.namedErrors = [{
            id: 'googleEmailMismatch',
            message: `Please link your google account again. Be sure to select the ${this.unlinkedGoogleEmail} account. No other accounts are authorized to be linked`,
            title: 'Wrong google account linked',
            subtitle: 'The chosen google user account does not match',
        }, {
            id: 'missingScopes',
            message: 'Please link your google account again. Be sure to say YES or ACCEPT to all prompts given by google',
            title: 'Bad permissions selected',
            subtitle: 'Unsuccessful linking',
        }, {
            id: 'linkingError',
            message: `Something went wrong when linking your google account. Be sure to select the ${this.unlinkedGoogleEmail} account. No other addresses are authorized`,
            title: 'Linking Error',
            subtitle: 'Unsuccessful linking',
        }];
    },
};
</script>>
