<template>
<div class="kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop">
    <div class="kt-container kt-container--fluid">
        <div class="kt-footer__copyright">
            <b-spinner
                class="mt-1"
                small
                variant="primary"
            />
            <span class="ml-3">
                {{ caption }}
            </span>
        </div>
        <div class="kt-footer__menu">
            <a
                href="#"
                class="kt-footer__menu-link kt-link"
                @click.stop.prevent="abortRequests()"
            >
                Cancel
                <i class="la la-times" />
            </a>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Types from '../../../store/Types';

export default {
    name: 'TheNetworkRequestNotification',
    computed: {
        cache() {
            const { cache } = this.$store.state.database;
            return cache.filter((k) => k.status == 'loading' && k.cacheType != 'portfolioCalc');
        },
        caption() {
            const { cache } = this;
            if (cache.length == 0) return '';
            if (cache.length == 1) {
                const [first] = cache;
                if (first.cacheType == 'googleCourseTables') {
                    return `Refreshing Google data for ${first.cacheTitle}`;
                }
                if (first.cacheType == 'baseTables') {
                    return 'Downloading Updated School Rosters';
                }
                return `Downloading ${first.cacheType} data for ${first.cacheTitle}`;
            }
            return 'Performing mulitple updates. Please wait';
        },
    },
    methods: {
        abortRequests() {
            this.$store.commit(Types.mutations.ABORT_REQUESTS);
        },
    },
};

</script>
